import { FormField, FormFieldOptions, useFormField } from '@Util/Hooks/FormField.ts';

export interface NumericFormField extends FormField<string> {
  getNumericValue: () => number|undefined;
}

export const useNumericFormField = (options: FormFieldOptions<string>): NumericFormField => {
  const field = useFormField(options);
  const getNumericValue = () => {
    if (field.value === undefined) {
      return undefined;
    }

    return parseInt(field.value);
  }

  return {...field, getNumericValue};
}
