import { API, useAPI } from '@API/API.ts';
import { Membership } from '@API/MembershipAPI.ts';
import { Targets } from '@API/TargetAPI.ts';
import { AuthUser } from '@Util/Hooks/Auth.tsx';

export const CLIENT_MEMBERSHIP_ID = 1;

export interface Member {
  user: AuthUser,
  type: Membership,
  pendingType: Membership,
  status: string;
  approved: boolean;
}

export interface MembersSearch {
  query?: string;
  target?: Targets;
}

export class MembersAPI extends API {
  async search(options: MembersSearch = {}) {
    const response = await this.http.get('/api/members', {params: options});

    return response.data as Member[];
  }
}

export const useMembersAPI = () => useAPI(MembersAPI);
