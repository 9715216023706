import {createContext, useContext, useEffect, useState} from "react";
import {ChatMessage, MessageAPI} from "@API/MessageAPI.ts";
import {useCurrentUser} from "@Util/Hooks/Auth.tsx";
import {FCProps} from "@Util/FCProps.ts";
import {Topics, useWebSocket} from "@Components/Providers/WebsocketProvider.tsx";
import {useAPI} from "@API/API.ts";

interface UnreadSummary {
  total: number;
  bySender: {
    [id: number]: number;
  }
}

const UnreadMessages = createContext<UnreadSummary | null>(null);

export const useUnreadMessagesSummary = () => {
  const summary = useContext(UnreadMessages);

  if (!summary) {
    throw new Error('Should be wrapped by ChatProvider');
  }

  return summary;
}

export const ChatProvider = (props: FCProps) => {
  const user = useCurrentUser();
  const ws = useWebSocket();
  const api = useAPI(MessageAPI);
  const [summary, setSummary] = useState<UnreadSummary>({
    total: 0,
    bySender: {},
  });

  const updateSummary = (unread: ChatMessage[]) => {
    const updated: UnreadSummary = {
      total: unread.length,
      bySender: {},
    }

    for (const message of unread) {
      const id = message.sender.id as number;
      updated.bySender[id] = updated.bySender[id] ? updated.bySender[id] + 1 : 1;
    }

    setSummary(updated);
  }

  useEffect(() =>  {
    api.unread().then(updateSummary);

    const onChatMessage = (message: ChatMessage) => {
      if (message.receiver.id === user.id) {
        api.unread().then(updateSummary);
      }
    };

    ws.on(Topics.CHAT_MESSAGE, onChatMessage);
    ws.on(Topics.CHAT_MESSAGE_UPDATED, onChatMessage)

    return () => {
      ws.off(Topics.CHAT_MESSAGE, onChatMessage);
      ws.off(Topics.CHAT_MESSAGE_UPDATED, onChatMessage);
    }
  }, [user.id]);

  return (
    <UnreadMessages.Provider value={summary}>
      {props.children}
    </UnreadMessages.Provider>
  )
}
