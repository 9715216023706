import {TextField as MuiTextField, TextFieldProps as MuiTextFieldProps} from "@mui/material";
import {ExtraProps} from "@Util/Hooks/FormField.ts";
import {ChangeEvent, useCallback} from "react";

export type TextFieldProps = ExtraProps<string> & Omit<MuiTextFieldProps, 'onChange'>

export const TextField = (props: TextFieldProps) => {
  const {field, ...rest} = props

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    field.onChange(event.target.value);
  }, [field]);

  return <MuiTextField
    onChange={handleChange}
    onBlur={field.onBlur}
    value={field.value}
    error={!!field.error}
    helperText={field.error}
    {...rest}
  />
}
