import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {LocalisedCity, useLocalisedCities} from "@Components/Providers/LocalisedCityProvider.tsx";
import {EditButton} from "@Components/Button/EditButton.tsx";
import {DeleteButton} from "@Components/Button/DeleteButton.tsx";
import {useNavigate, useSearchParams} from "react-router-dom";

export const CityListTable = () => {
  const list = useLocalisedCities();
  const [search] = useSearchParams();
  const navigate = useNavigate();

  const showEditDialog = (city: LocalisedCity) => {
    navigate({
      pathname: `${city.id}/update`,
      search: search.toString(),
    });
  }

  const showDeleteDialog = (city: LocalisedCity) => {
    navigate({
      pathname: `${city.id}/delete`,
      search: search.toString(),
    });
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Localised</TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list.map(city => (
          <TableRow key={city.id}>
            <TableCell>{city.id}</TableCell>
            <TableCell>{city.name}</TableCell>
            <TableCell>{city.localisedName}</TableCell>
            <TableCell align="right">
              <EditButton onClick={() => showEditDialog(city)} />
              <DeleteButton onClick={() => showDeleteDialog(city)} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
