import {FCProps} from "@Util/FCProps.ts";
import {styled} from "@mui/system";
import {Box} from "@mui/material";
import {NORMAL_RADIUS, theme} from "@Util/theme.tsx";

export const Panel = ({children}: FCProps) => {
  return (
    <StyledWrapper>
      {children}
    </StyledWrapper>
  )
}

export const PanelSpaced = ({children, gap}: FCProps & {gap?: number}) => {
  return (
    <StyledWrapper>
      <Box p={2} display="flex" flexDirection="column" gap={gap}>
        {children}
      </Box>
    </StyledWrapper>
  );
}

const StyledWrapper = styled('div')`
  border-radius: ${NORMAL_RADIUS}px;
  overflow: hidden;
  background-color: white;
  margin-bottom: ${theme.spacing(2)};
`
