import {Avatar} from "@mui/material";
import {styled} from "@mui/system";
import {theme} from "@Util/theme.tsx";

export const TherapistAvatar = styled(Avatar)`
  width: 150px;
  height: 150px;
  
  ${theme.breakpoints.down('sm')} {
    width: 100vw;
    height: calc(100vw - ${theme.spacing(4)});
    margin-bottom: 0;
    border-radius: 0;
    margin-left: -${theme.spacing(2)};
    margin-top: -${theme.spacing(2)};
  }
`
