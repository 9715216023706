import {FC} from "react";
import {RegistrationDialog} from "../Auth/RegistrationDialog.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const ExpressRegistration: FC = () => {

  return (
    <RegistrationDialog
      title={(
        <Translated value={TRANSLATIONS.PROFILE.TITLE_CONTACT_THERAPIST}/>
      )}
    />
  )
}
