import {styled} from "@mui/system";
import {Box, Typography} from "@mui/material";
import {useCurrentUser} from "@Util/Hooks/Auth.tsx";
import {MembershipOption} from "@Components/Membership/useMembershipOptions.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export interface MembershipDropdownOptionProps {
  option: MembershipOption;
}

export const MembershipDropdownOption = ({ option }: MembershipDropdownOptionProps) => {
  const user = useCurrentUser();
  const isCurrent = user.membership?.type.id === option.id;
  const isPending = user.membership?.pendingType?.id === option.id;

  const price = option.price > 0
    ? <Translated value="{0}{1} per month" args={[option.currency.symbol, option.price]}/>
    : <Translated value={TRANSLATIONS.PROFILE.PRICE_FREE}/>

  return (
    <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
      <LabelWrapper>
        <Label>{option.title}</Label>
        <PriceValue>
          {price}
        </PriceValue>
      </LabelWrapper>
      {isCurrent && (
        <div>
          <Typography ml={2} component="span" color="green">
            <Translated value="Current"/>
          </Typography>
        </div>
      )}
      {isPending && (
        <div>
          <Typography ml={2} component="span">
            <Translated value="Pending"/>
          </Typography>
        </div>
      )}
    </Box>
  );
}

const LabelWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`

const Label = styled('span')`
  font-weight: 600;
  padding-right: 10px;
`

const PriceValue = styled('span')`

`
