import {RouteObject} from "react-router-dom";
import {WorksWithListPage} from "./WorksWithListPage.tsx";
import {WorksWithAddDialog} from "./WorksWithAddDialog.tsx";
import {WorksWithUpdateDialog} from "./WorksWithUpdateDialog.tsx";
import {WorksWithDeleteDialog} from "./WorksWithDeleteDialog.tsx";

export const WorkWithRoute: RouteObject = {
  path: 'work-with',
  Component: WorksWithListPage,
  children: [
    {
      path: 'add',
      Component: WorksWithAddDialog,
    },
    {
      path: ':id',
      children: [
        {
          path: 'update',
          Component: WorksWithUpdateDialog,
        },
        {
          path: 'delete',
          Component: WorksWithDeleteDialog,
        },
      ],
    },
  ],
};
