import {WorkHistory} from "@mui/icons-material";
import {useTherapist} from "../../Account/Therapist/TherapistProvider.tsx";
import {HighlightItem} from "./HighlightItem.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";
import {NotFilled} from "@Components/NotFilled.tsx";

export const YearsOfExperienceView = () => {
  const t = useTherapist();

  return (
    <HighlightItem label={TRANSLATIONS.PROFILE.YEARS_OF_EXPERIENCE} IconComponent={WorkHistory}>
      {t.yearsOfExperience ?? <NotFilled/>}
    </HighlightItem>
  );
}
