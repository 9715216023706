import {FormDialog} from "@Components/FormDialog.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {To, useParams, useSearchParams} from "react-router-dom";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {Required} from "@Util/Validation/Required.ts";
import {CheckboxField} from "@Components/CheckboxField.tsx";
import {useLocalisedCountry} from "@Components/Providers/CountriesProvider.tsx";
import {useCountryAPI} from "@API/CountryAPI.ts";

export const useCountry = () => {
  const params = useParams<{id: string}>();
  const id = parseInt(params.id ?? '');

  return useLocalisedCountry(id);
}

export const CountryDeleteDialog = () => {
  const api = useCountryAPI();
  const country = useCountry();
  const [search] = useSearchParams();
  const title = <Translated value="Delete country"/>;

  const confirmation = useFormField<boolean>({
    initialValue: false,
    validators: [
      new Required(),
    ],
  });

  const handleSubmit = async () => {
    if (country) {
      await api.delete(country.id);
    }
  };

  const back: To = {
    pathname: '../..',
    search: search.toString(),
  }

  return (
    <FormDialog title={title} onSubmit={handleSubmit} back={back}>
      <CheckboxField
        field={confirmation}
        label={(
          <Translated value="I confirm"/>
        )}
      />

      <ActionButton requireValid={[confirmation]} >
        <Translated value="Delete"/>
      </ActionButton>
    </FormDialog>
  )
}
