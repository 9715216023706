import {useCurrentUser} from "@Util/Hooks/Auth.tsx";
import {useLocalisedSex} from "@Components/Providers/SexProvider.tsx";
import {EditableInline} from "../Editable.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";
import {NotFilled} from "@Components/NotFilled.tsx";

export const SexSection = () => {
  const user = useCurrentUser();
  const sexOptions = useLocalisedSex();
  const userSex = sexOptions.find(item => item.id === user.sex?.id)

  return (
    <EditableInline label={TRANSLATIONS.PROFILE.SEX} editPath="sex">
      {userSex?.title ?? <NotFilled />}
    </EditableInline>
  )
}
