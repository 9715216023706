import {ConfirmDelete} from "@Components/ConfirmDelete.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {useSearchAwarePath} from "@Util/Hooks/Navigate.tsx";
import {useEducationAPI} from "@API/EducationAPI.ts";
import {useTherapistEducation} from "./useTherapistEducation.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const EducationDeleteDialog = () => {
  const api = useEducationAPI();
  const item = useTherapistEducation();
  const back = useSearchAwarePath('../../..');

  const handleSubmit = async () => {
    if (!item) {
      throw new Error("No item");
    }

    await api.delete({id: item.id});
  }

  const title = <Translated value={TRANSLATIONS.PROFILE.DELETE_EDUCATION_TITLE}/>;

  return <ConfirmDelete title={title} onSubmit={handleSubmit} back={back}/>
}
