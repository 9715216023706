import {Translated} from "@Components/Localization/Translated.tsx";
import {styled} from "@mui/system";
import {TRANSLATIONS} from "@assets/translation.ts";

export const CanNotBeUndone = () => {
  return (
    <Section>
      <p><Translated value={TRANSLATIONS.CANT_BE_UNDONE}/></p>
      {/*<p><Translated value="Are you sure you want to proceed?"/></p>*/}
      <p><Translated value={TRANSLATIONS.SURE_WANT_TO_PROCEED}/></p>
    </Section>
  );
}

const Section = styled('div')`
p {
  margin-top: 0;
}
`
