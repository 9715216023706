import {styled} from "@mui/system";
import {theme} from "@Util/theme.tsx";

export const WideSection = styled("section")`
  background-color: ${theme.palette.secondary.main};
  width: 100vw;
  position: relative;
  left: 50%;
  margin-left: -50vw;
  padding: ${theme.spacing(1)} 0;
`
