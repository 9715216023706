import {FormDialog} from "@Components/FormDialog.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {useLocationsAPI} from "@API/LocationsAPI.ts";
import {useFormSelectField} from "@Util/Hooks/FormSelectField.ts";
import {useLocalisedCountries} from "@Components/Providers/CountriesProvider.tsx";
import {LocalisedCity} from "@Components/Providers/LocalisedCityProvider.tsx";
import {SelectField} from "@Components/SelectField.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {useEffect} from "react";
import {Required} from "@Util/Validation/Required.ts";
import {useTherapist} from "../TherapistProvider.tsx";
import {useFilteredCities} from "./useFilteredCities.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const LocationAddDialog = () => {
  const therapist = useTherapist();
  const api = useLocationsAPI();

  const country = useFormSelectField({
    initialValue: '',
    options: useLocalisedCountries(),
    getOptionValue: country => country.id,
    getOptionLabel: country => country.localisedName ?? country.name,
  });

  const existing = therapist.locations ?? [];
  const exclude = existing.map(location => location.city);

  const city = useFormSelectField<LocalisedCity, number>({
    initialValue: '',
    options: useFilteredCities({
      country: country.selected,
      exclude: exclude,
    }),
    getOptionValue: city => city.id,
    getOptionLabel: city => city.localisedName ?? city.name,
    validators: [
      new Required(),
    ],
  });

  useEffect(() => {
    if (city.selected && city.selected.country.id !== country.selected?.id) {
      city.reset();
    }
  }, [country.value, city.selected]);

  const handleSubmit = async () => {
    if (!city.selected) {
      throw new Error('Not selected');
    }

    await api.create(city.selected?.id as number);
  };

  const title = <Translated value={TRANSLATIONS.PROFILE.ADD_LOCATION}/>;

  return (
    <FormDialog title={title} onSubmit={handleSubmit} back="../..">
      <SelectField
        field={country}
        label={(
          <Translated value={TRANSLATIONS.PROFILE.COUNTRY}/>
        )}
      />

      <SelectField
        disabled={city.options.length === 0}
        field={city}
        label={(
          <Translated value={TRANSLATIONS.PROFILE.CITY}/>
        )}
      />

      <ActionButton requireValid={[country, city]}>
        <Translated value="Add"/>
      </ActionButton>
    </FormDialog>
  );
}
