import {Translated} from "@Components/Localization/Translated.tsx";
import {useTherapist} from "../../Pages/Account/Therapist/TherapistProvider.tsx";
import React, {FC} from "react";

export const YearsOfExperienceView: FC = React.memo(() => {
  const therapist = useTherapist();

  return (
    <>
      {therapist.yearsOfExperience ?? (
        <Translated value="Not filled"/>
      )}
    </>
  )
});
