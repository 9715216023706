import {Outlet} from "react-router-dom";
import {Box, Paper} from "@mui/material";
import {TopBar} from "./partial/TopBar.tsx";
import {styled} from "@mui/system";
import {theme} from "@Util/theme.tsx";
import {buildAdminMenu} from "./menu/AdminMenu.tsx";
import {Sidebar} from "./partial/Sidebar.tsx";

export const AdminLayout = () => {
  const menu = buildAdminMenu();

  return (
    <>
      <TopBar/>

      <Box sx={{display: 'flex', gap: 4, mt:2, maxWidth: '100%', overflowX: 'scroll'}}>
        <Box sx={{flexBasis: 200}} >
          <Sidebar items={menu}/>
        </Box>
        <Box sx={{flexGrow: 1}}>
          <MainContent>
            <Outlet/>
          </MainContent>
        </Box>
      </Box>
    </>
  );
}

export const MainContent = styled(Paper)`
  min-width: 500px;
  padding: ${theme.spacing(2)};
  
  h1 {
    font-weight: 400;
    margin-top: 0;
  }
`
