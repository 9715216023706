import { FieldValidator, scalar } from '@Util/Hooks/FormField.ts';
import Joi from 'joi';

export class IsURL implements FieldValidator {
  private readonly schema = Joi.string()
    .trim()
    .uri({scheme: /^http(s?)/, allowRelative: false})
    .pattern(/[.]/) // has dot
    .pattern(/[^.]$/) // not ends with dot
    .required();

  constructor(private readonly msg: string = 'Must be a valid url') {}

  isValid(value: scalar): boolean {
    const {error} = this.schema.validate(value);

    return !error;
  }

  get message(): string {
    return this.msg;
  }
}
