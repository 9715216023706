import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {FCProps} from "@Util/FCProps.ts";
import {Language, useCurrentLanguage} from "@Components/I18nWrapper.tsx";
import {Country, useCountryAPI} from "@API/CountryAPI.ts";
import {useResponseActionInterceptor} from "@Util/Hooks/Http.tsx";

export interface LocalisedCountry {
  id: number;
  name: string;
  locale: string;
  localisedName?: string;
}

const LocalisedCountriesContext = createContext<LocalisedCountry[]>([]);

export const useLocalisedCountries = () => useContext(LocalisedCountriesContext);

export const useLocalisedCountry = (id: number) => {
  const list = useLocalisedCountries();

  return list.find(item => item.id === id);
}

interface CountriesProviderProps extends FCProps {
  list?: Country[];
  i18nLanguage?: Language; // For admin panel
}

export const CountriesProvider = ({i18nLanguage, list, children}: CountriesProviderProps) => {
  const currentLanguage = useCurrentLanguage();
  const language = i18nLanguage ?? currentLanguage;
  const api = useCountryAPI();
  const [updatedList, setUpdatedList] = useState<Country[]>();

  const currentList = updatedList ?? list ?? [];

  useEffect(() => {
    if (!list) {
      api.list().then(setUpdatedList);
    }
  }, [])

  useResponseActionInterceptor(/^country/, async () => {
    await api.list().then(setUpdatedList)
  }, [updatedList]);

  const localisedList: LocalisedCountry[] = useMemo(() => {
    if (!language) {
      return [];
    }

    return currentList.map(country => {
      const i18n = country.i18n?.find(i18n => i18n.language.code === language?.code);

      return {
        id: country.id,
        name: country.name,
        locale: language.code,
        localisedName: i18n?.name
      };
    });
  }, [updatedList, language]);

  return (
    <LocalisedCountriesContext.Provider value={localisedList}>
      {children}
    </LocalisedCountriesContext.Provider>
  );
};
