import {FormDialog} from "@Components/FormDialog.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {useSearchAwarePath} from "@Util/Hooks/Navigate.tsx";
import {useQualificationAPI} from "@API/QualificationAPI.ts";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {Required} from "@Util/Validation/Required.ts";
import {TextField} from "@Components/TextField.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";

export const QualificationAddDialog = () => {
  const api = useQualificationAPI();
  const back = useSearchAwarePath('..');
  const title = useFormField<string>({
    initialValue: '',
    validators: [
      new Required(),
    ],
  });

  const handleSubmit = async () => {
    await api.create({
      title: title.value as string,
    })
  };

  return (
    <FormDialog title={<Translated value="Qualification add"/>} onSubmit={handleSubmit} back={back}>
      <TextField
        field={title}
        label={(
          <Translated value="Title"/>
        )}
        autoComplete="off"
      />
      <ActionButton requireValid={[title]}/>
    </FormDialog>
  );
}
