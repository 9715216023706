import {useFormField} from "@Util/Hooks/FormField.ts";
import {Required} from "@Util/Validation/Required.ts";
import {IsEmail} from "@Util/Validation/IsEmail.ts";
import {PasswordField} from "@Components/PasswordField.tsx";
import {useAxios} from "@Util/Hooks/Http.tsx";
import {useSubmitHandler} from "@Util/Hooks/Form.tsx";
import {TextField} from "@Components/TextField.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {Translated, useTranslated} from "@Components/Localization/Translated.tsx";
import {Link} from "@Components/Link.tsx";
import {BottomHint} from "./BottomHint.tsx";
import {useSearchAwarePath} from "@Util/Hooks/Navigate.tsx";
import {Helmet} from "react-helmet-async";

export const Login = () => {
  const http = useAxios();
  const registerPath = useSearchAwarePath('../register');

  const login = useFormField<string>({
    initialValue: '',
    validators: [
      new Required(),
      new IsEmail(),
    ],
  });

  const password = useFormField<string>( {
    initialValue: '',
    validators: [
      new Required(),
    ]
  });

  const handleSubmit = useSubmitHandler(async () => {
    await http.post<{role: {id: number}}>('/api/auth', {
      login: login.value,
      password: password.value,
    });
  });

  const title = useTranslated('Log-in');

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <form noValidate method="post" onSubmit={handleSubmit}>
        <TextField
          type="email"
          label={(
            <Translated value="Email"/>
          )}
          field={login}
          autoComplete="email username login"
        />
        <PasswordField
          field={password}
          label={(
            <Translated value="Password"/>
          )}
          autoComplete="password"
        />

        <ActionButton requireValid={[login, password]}>
          <Translated value="Log in"/>
        </ActionButton>
      </form>


      <BottomHint>
        <Translated value="Don't have an account?"/>
        <Link to={registerPath}>
          <Translated value="Register"/>
        </Link>
      </BottomHint>
      <BottomHint>
        <Translated value="Forgot password?"/>
        <Link to="/password">
          <Translated value="Reset"/>
        </Link>
      </BottomHint>
    </>
  );
}
