import {NORMAL_RADIUS, theme} from "@Util/theme.tsx";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {ChatMessage} from "@API/MessageAPI.ts";
import {ChatContact} from "@API/ContactAPI.ts";
import {styled} from "@mui/system";

dayjs.extend(utc);

export interface ChatMessageProps {
  message: ChatMessage;
  contact: ChatContact;
}

export const ChatMessageView = ({message, contact}: ChatMessageProps) => {
  const className = message.sender.id === contact.target.id ? 'left' : 'right';

  return (
    <MessageWrapper className={className}>
      <div className="body">
        {message.content.split("\n").map((part, index) => (
          <div key={index}>{part.trim()}</div>
        ))}
      </div>
    </MessageWrapper>
  );
}

const MessageWrapper = styled('div')`
  &.left {
    margin-right: 30%;
    .body {
      text-align: left;
      background-color: ${theme.palette.secondary.light};
    }
  }

  &.right {
    margin-left: 40%;
    text-align: right;
    
    .body {
      color: white;
      background-color: ${theme.palette.primary.main};  
    }
  }
  
  .body {
    display: inline-block;
    padding: ${theme.spacing(2)};
    margin: 0 ${theme.spacing(2)};
    border-radius: ${NORMAL_RADIUS}px;
  }
  
  .time {
    font-size: 12px;
    margin: 0 ${theme.spacing(2)};
  }
`
