import {FormDialog} from "@Components/FormDialog.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {Therapist, useTherapistAPI} from "@API/TherapistAPI.ts";
import {ActionButton} from "@Components/ActionButton.tsx";
import {CheckboxField} from "@Components/CheckboxField.tsx";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {useTherapist} from "../TherapistProvider.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const WorkOnlineDialog = () => {
  const api = useTherapistAPI();
  const therapist: Therapist = useTherapist();
  const workOnline = useFormField<boolean>({
    initialValue: therapist.worksOnline ?? false,
  });

  const handleSubmit = async () => {
    await api.updateWorkOnline(workOnline.value ?? false);
  }

  return (
    <FormDialog title={<Translated value={TRANSLATIONS.PROFILE.WORK_ONLINE}/>} onSubmit={handleSubmit}>
      <CheckboxField
        field={workOnline}
        label={(
          <Translated value={TRANSLATIONS.PROFILE.I_WORK_ONLINE}/>
        )}/>

      <ActionButton/>
    </FormDialog>
  )
}
