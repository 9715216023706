import {RouteObject} from "react-router-dom";
import {LanguagesListPage} from "./LanguagesListPage.tsx";
import {LanguageAddDialog} from "./LanguageAddDialog.tsx";
import {LanguageEditDialog} from "./LanguageEditDialog.tsx";

export const AdminLanguagesRoute: RouteObject = {
  path: 'languages',
  Component: LanguagesListPage,
  children: [
    {
      path: 'add',
      Component: LanguageAddDialog,
    },
    {
      path: ':code/edit',
      Component: LanguageEditDialog,
    }
  ]
};
