import {Helmet} from "react-helmet-async";
import {Translated, useTranslated} from "@Components/Localization/Translated.tsx";
import {TransSection} from "@Components/Localization/TransSection.tsx";
import {ButtonLink} from "@Components/Link.tsx";
import {Box, Container, Grid} from "@mui/material";
import {usePath} from "@Util/Hooks/Navigate.tsx";
import {styled} from "@mui/system";
import {theme} from "@Util/theme.tsx";
import {WideSection} from "@Components/Layout/WideSection.tsx";
import {BulletSection} from "./components/BulletSection.tsx";
import {TransparentPanel} from "./components/TransparentPanel.tsx";
import {Headline} from "@Components/Headline.tsx";
import {ArrowRightAlt} from "@mui/icons-material";
import {TRANSLATIONS} from "@assets/translation.ts";

export const HomePage = () => {
  const metaTitle = useTranslated(TRANSLATIONS.HOME.META.TITLE);
  const metaDescription = useTranslated(TRANSLATIONS.HOME.META.DESCRIPTION);

  const urls = {
    ourMission: usePath('/about'),
    therapists: usePath('/therapists'),
    forTherapists: usePath('/for-therapists'),
  };

  return (
    <TransSection section="home">
      <BackgroundSection>
        <Container maxWidth="lg">
          <Box mt={4} mb={4}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <TransparentPanel>
                  <Headline text={TRANSLATIONS.HOME.TOP_PANELS[0].HEADING} level={2}/>
                  <Box>
                    <h3>
                      <Translated value={TRANSLATIONS.HOME.TOP_PANELS[0].SUB_HEADING}/>
                    </h3>
                    <p>
                      <Translated value={TRANSLATIONS.HOME.TOP_PANELS[0].CONTENT}/>
                    </p>

                    <Box mb={4} mt={4}>
                      <ButtonLink color="primary" to={urls.therapists}>
                        <Box display="inline" mr={1}>
                          <Translated value={TRANSLATIONS.BTN.FIND_THERAPIST}/>
                        </Box>
                        <ArrowRightAlt/>
                      </ButtonLink>
                    </Box>
                  </Box>
                </TransparentPanel>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TransparentPanel>
                  <Headline text={TRANSLATIONS.HOME.TOP_PANELS[1].HEADING} level={2}/>
                  <Box>
                    <h3>
                      <Translated value={TRANSLATIONS.HOME.TOP_PANELS[1].SUB_HEADING}/>
                    </h3>
                    <p>
                      <Translated value={TRANSLATIONS.HOME.TOP_PANELS[1].CONTENT}/>
                    </p>

                    <Box mb={4} mt={4}>
                      <ButtonLink color="primary" to={urls.forTherapists}>
                        <Box display="inline" mr={1}>
                          <Translated value={TRANSLATIONS.BTN.BECOME_MEMBER}/>
                        </Box>
                        <ArrowRightAlt/>
                      </ButtonLink>
                    </Box>
                  </Box>
                </TransparentPanel>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </BackgroundSection>

      <Section>
        <Grid container spacing={2}>
          {TRANSLATIONS.HOME.GESTALT.map(section => (
            <BulletSection key={section.HEADING} headline={section.HEADING}>
              <ul>
                {section.BULLETS.map((key) => (
                  <li key={key}>
                    <Translated value={key}/>
                  </li>
                ))}
              </ul>
            </BulletSection>
          ))}
        </Grid>
      </Section>
      <Helmet>
        <title>{metaTitle} | ProGestalt</title>
        <meta name="title" content="ProGestalt"/>
        <meta name="description" content={metaDescription}/>
      </Helmet>
    </TransSection>
  );
}

export const Section = styled('section')`
  padding: ${theme.spacing(1)} 0;
`;

export const BackgroundSection = styled(WideSection)`
  padding-top: 100px;
  padding-bottom: 20px;
  margin-top: -100px;
  background: #053242 url("/background.jpg") center;
  background-size: cover;
  
  ${theme.breakpoints.down('sm')} {
    background-image: url("/background_mobile.jpg");
  }

  h2 {
    font-size: 40px;
    line-height: 50px;
    word-spacing: 100vw;
  }

  p {
    min-height: 120px;
  }
`
