import {RouteObject} from "react-router-dom";
import {MembershipPage} from "./MembershipPage.tsx";
import {ChangeMembershipDialog} from "./ChangeMembershipDialog.tsx";
import {PaymentMethodDialog} from "./PaymentMethodDialog.tsx";

export const MembershipRoute: RouteObject = {
  path: 'membership',
  Component: MembershipPage,
  children: [
    {
      path: 'type',
      Component: ChangeMembershipDialog,
    },
    {
      path: 'pay',
      Component: PaymentMethodDialog,
    }
  ]
}
