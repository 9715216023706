import {useEffect, useState} from "react";
import {Badge} from "@mui/material";
import {MailOutline} from "@mui/icons-material";
import {useUnreadMessagesSummary} from "@Components/Providers/ChatProvider.tsx";

export const UnreadMessagesIndicator = () => {
  const summary = useUnreadMessagesSummary();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (summary.total > 0) {
        setVisible(!visible);
      } else {
        setVisible(false);
      }
    }, 1000);

    return () => {
      clearTimeout(interval);
    };
  }, [visible, summary]);

  return (
    <Badge invisible={!visible} variant="dot" color="success" overlap="circular">
      <MailOutline sx={{mr: 0.5}}/>
    </Badge>
  )
}
