import { API } from '@API/API.ts';
import { AuthUserPK } from '@Util/Hooks/Auth.tsx';

export interface ChatMessage {
  id: number;
  sender: AuthUserPK,
  receiver: AuthUserPK,
  content: string;
  sentAt: string;
  receivedAt: string | null;
}

export interface MessagesSearch {
  contact: number;
}

export interface MessageCreate {
  contact: number;
  content: string;
}

export interface MessageUpdate {
  id: number;
  content?: string;
  receivedAt?: string;
}

const ENDPOINT = '/api/chat/message';

export class MessageAPI extends API {
  async list(query: MessagesSearch): Promise<ChatMessage[]> {
    return this.http.get(ENDPOINT, {params: query}).then(r => r.data)
  }

  async unread(): Promise<ChatMessage[]> {
    return this.http.get(ENDPOINT + '/unread').then(r => r.data);
  }

  async create(data: MessageCreate): Promise<ChatMessage> {
    return this.http.post(ENDPOINT, data).then(r => r.data);
  }

  async update(data: MessageUpdate) {
    return await this.http.put(ENDPOINT, data).then(r => r.data);
  }
}
