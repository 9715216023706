import {Box} from "@mui/material";
import {TherapistAvatar} from "../TherapistAvatar.tsx";
import {AboutTrimmed} from "../Partial/AboutTrimmed.tsx";
import {Heading} from "./TherapistPreview.tsx";
import {WorksWithView} from "../Partial/WorksWithView.tsx";
import {LanguagesView} from "../Partial/LanguagesView.tsx";
import {styled} from "@mui/system";
import {theme} from "@Util/theme.tsx";
import {TopicsView} from "../Partial/TopicsView.tsx";
import {useTherapist} from "../../Account/Therapist/TherapistProvider.tsx";
import {MembershipView} from "../Partial/MembershipView.tsx";

export const PrimaryDetails = () => {
  const t = useTherapist();

  return (
    <Box flexGrow="1" display="flex" flexDirection="column" gap={1}>
      <ResponsiveBox>
        <TherapistAvatar variant="rounded" src={t.avatar} alt={t.name}/>
        <Box flexGrow="1">
          <Heading>
            {t.name}
          </Heading>
          <MembershipView/>
          <WorksWithView/>
          <LanguagesView/>
          <TopicsView/>
        </Box>
      </ResponsiveBox>
      <Box>
        {t.summary && <AboutTrimmed text={t.summary} maxLength={200}/>}
      </Box>
    </Box>
  );
}

const ResponsiveBox = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(2)};

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`
