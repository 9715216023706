import {Grid} from "@mui/material";
import {MembershipCard} from "./MembershipCard.tsx";
import {Headline} from "@Components/Headline.tsx";
import {useMembershipOptions} from "@Components/Membership/useMembershipOptions.tsx";
import {useInfoBlocks} from "./Info.ts";
import {InfoCard} from "./InfoCard.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";
import {TransSection} from "@Components/Localization/TransSection.tsx";

export const BecomeAMember = () => {
  const infoBlocks = useInfoBlocks();
  const membershipOptions = useMembershipOptions().filter((o) => o.price > 0);

  return (
    <TransSection section="become_a_member">
      <Headline level={1} text={TRANSLATIONS.BECOME_MEMBER.TITLE}/>

      <Grid container spacing={2}>
        {infoBlocks.map((block, index) => (
          <Grid item key={index} sm={4} xs={12} >
            <InfoCard item={block}/>
          </Grid>
        ))}
      </Grid>

      <div>
        <Headline text={TRANSLATIONS.BECOME_MEMBER.SUB_TITLE} level={2}/>

        <div>
          <p>
            <Translated value={TRANSLATIONS.BECOME_MEMBER.EXPLANATION_1}/>&nbsp;
            <b>
              <Translated value={TRANSLATIONS.BECOME_MEMBER.NO_EXTRA_CHARGES}/>
            </b>
          </p>

          <p>
            <Translated value={TRANSLATIONS.BECOME_MEMBER.EXPLANATION_2}/>
          </p>
        </div>

        <Grid container spacing={2} mt={2}>
          {membershipOptions.map((membership) => (
            <Grid item sm={3} xs={12} key={membership.id}>
              <MembershipCard membership={membership}/>
            </Grid>
          ))}
        </Grid>
      </div>
    </TransSection>
  );
}
