import {FCProps} from "@Util/FCProps.ts";
import {createTheme, Paper, ThemeProvider} from "@mui/material";
import {NORMAL_RADIUS, theme} from "@Util/theme.tsx";

const themeOverride = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    text: {
      primary: '#fff'
    }
  },
  components: {
    ...theme.components,
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: `${theme.palette.primary.main}20`,
          borderRadius: `${NORMAL_RADIUS}px`,
          padding: theme.spacing(4),
        },
      }
    }
  }
});

export const TransparentPanel = ({children}: FCProps) => {
  return (
    <ThemeProvider theme={themeOverride}>
      <Paper elevation={0}>
        {children}
      </Paper>
    </ThemeProvider>
  );
}
