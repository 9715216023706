import {RouteObject} from "react-router-dom";
import {TranslationsPage} from "./TranslationsPage.tsx";
import {TranslationEditDialog} from "./TranslationEditDialog.tsx";
import {TranslationAddDialog} from "./TranslationAddDialog.tsx";

export const AdminTranslationRoute: RouteObject = {
  path: 'translations',
  Component: TranslationsPage,
  children: [
    {
      path: ':id',
      children: [
        {
          path: 'edit',
          Component: TranslationEditDialog,
        }
      ]
    },
    {
      path: 'add',
      Component: TranslationAddDialog,
    },
  ]
}
