import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {Language, useCurrentLanguage} from "@Components/I18nWrapper.tsx";
import {FCProps} from "@Util/FCProps.ts";
import {City, useCityAPI} from "@API/CityAPI.tsx";
import {useResponseActionInterceptor} from "@Util/Hooks/Http.tsx";
import {CountryPK} from "@API/CountryAPI.ts";

const CitiesContext = createContext<LocalisedCity[]>([]);

export const useLocalisedCities = () => useContext(CitiesContext);

interface LocalisedCityProviderProps extends FCProps {
  i18nLanguage?: Language; // for amin panel
  value?: City[];
}

export const LocalisedCityProvider = ({i18nLanguage, children, value}: LocalisedCityProviderProps) => {
  const currentLanguage = useCurrentLanguage();
  const api = useCityAPI();
  const [updatedCities, setUpdatedCities] = useState<City[]>();

  const cities = updatedCities ?? value ?? [];
  const language = i18nLanguage ?? currentLanguage;

  useEffect(() => {
    if (!value) {
      api.list().then(setUpdatedCities);
    }
  }, [language]);

  useResponseActionInterceptor(/^city/, async () => {
    await api.list().then(setUpdatedCities);
  }, [cities]);

  const localised: LocalisedCity[] = useMemo(() => {
    if (!language || cities.length === 0) {
      return [];
    }

    return cities.map(city => {
      const i18n = city.i18n.find(item => item.language.code === language.code);

      return {
        id: city.id,
        country: city.country,
        name: city.name,
        localisedName: i18n?.name,
        locale: language.code,
      }
    })
  }, [cities, language])

  return (
    <CitiesContext.Provider value={localised}>
      {children}
    </CitiesContext.Provider>
  )
}

export interface LocalisedCity {
  id: number;
  country: CountryPK;
  name: string;
  locale: string;
  localisedName?: string;
}
