import {generatePath, matchRoutes, Params, resolvePath, To} from "react-router-dom";
import {useLocation, useNavigate, useParams} from "react-router-dom"
import {routes} from "../../routes.tsx";
import {useCurrentLanguage} from "@Components/I18nWrapper.tsx";

export const useSearchAwareNavigate = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (path: string) => navigate({
    pathname: path,
    search: location.search,
  });
}

export const useSearchAwarePath = (path: string): To => {
  const location = useLocation();

  return {
    pathname: path,
    search: location.search,
  };
}

export const getMatchPattern = (to: To) => {
  const path = resolvePath(to);
  const match = matchRoutes(routes, path) ?? [];

  return match
    .filter(m => m.route.path)
    .map(m => m.route.path)
    .join('/');
}

export const usePath = (to: To, locale?: string): To => {
  const defaultLanguage = useCurrentLanguage();
  const params = useParams();
  const withLocale = Object.assign({}, params, {
    locale: locale ?? defaultLanguage.code,
  });

  return absolutePath(to, withLocale);
}

export const absolutePath = (to: To, params: Params): To => {
  const pattern = getMatchPattern(to);
  const path = generatePath(pattern, params);

  return Object.assign({}, to, {
    pathname: path,
  });
}
