import { API, useAPI } from '@API/API.ts';
import { Language } from '@Components/I18nWrapper.tsx';

export enum Targets {
  CLIENT = 'client',
  THERAPIST = 'therapist',
}


export interface TargetI18n {
  locale: Language;
  title: string;
  description: string;
}

export interface RegistrationTarget {
  id: Targets;
  i18n: TargetI18n[];
}

export class TargetAPI extends API {
  async list() {
    const response = await this.http.get('/api/target');

    return response.data as RegistrationTarget[];
  }
}

export const useTargetAPI = () => useAPI(TargetAPI);

