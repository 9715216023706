import {FC} from "react";
import {useTherapist} from "../TherapistProvider.tsx";
import {useTherapistAPI} from "@API/TherapistAPI.ts";
import {TRANSLATIONS} from "@assets/translation.ts";
import {FormDialog} from "@Components/FormDialog.tsx";
import {useTranslated} from "@Components/Localization/Translated.tsx";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {TextField} from "@Components/TextField.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {Range} from "@Util/Validation/Range.ts";

export const YearsOfExperienceDialog: FC = () => {
  const api = useTherapistAPI();
  const therapist = useTherapist();

  const yearsField = useFormField({
    initialValue: therapist.yearsOfExperience?.toString() ?? '',
    validators: [
      new Range({min: 0, max: 80}),
    ],
  });

  const label = useTranslated(TRANSLATIONS.PROFILE.YEARS_OF_EXPERIENCE);

  const handleSubmit = async () => {
    await api.updateYearsOfExperience(parseInt(yearsField.value as string));
  };

  return (
    <FormDialog title={label} onSubmit={handleSubmit}>
      <TextField type="number" field={yearsField} label={label}/>
      <ActionButton/>
    </FormDialog>
  );
};
