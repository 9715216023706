import {createContext, useContext} from "react";
import {Membership} from "@API/MembershipAPI.ts";
import {FCProps} from "@Util/FCProps.ts";

const MembershipListContext = createContext<Membership[]>([]);

export const useMembershipList = () => useContext(MembershipListContext);

export interface MembershipListProviderProps extends FCProps {
  value: Membership[];
}

export const MembershipListProvider = ({value, children}: MembershipListProviderProps) => {
  return (
    <MembershipListContext.Provider value={value}>
      {children}
    </MembershipListContext.Provider>
  );
}
