import { API, useAPI } from '@API/API.ts';
import { Language } from '@Components/I18nWrapper.tsx';

const ENDPOINT = '/api/language';

export class LanguageAPI extends API {
  async list(): Promise<Language[]> {
    const response = await this.http.get(ENDPOINT);

    return response.data
  }
}

export const useLanguageAPI = () => useAPI(LanguageAPI);
