import {HistoryEdu} from "@mui/icons-material";
import {NotFilled} from "@Components/NotFilled.tsx";
import {EditableCollection} from "../../EditableCollection.tsx";
import {useTherapist} from "../TherapistProvider.tsx";
import {Certificate} from "@API/CertificateAPI.ts";
import {EditableInline} from "../../Editable.tsx";
import {PrimarySection} from "../PrimarySection.tsx";
import {Box, Collapse} from "@mui/material";
import {styled} from "@mui/system";
import {useCallback, useState} from "react";
import {NORMAL_RADIUS, theme} from "@Util/theme.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const CertificatesSection = () => {
  const therapist = useTherapist();
  const elements = therapist.certificates ?? [];

  return (
    <PrimarySection noWrap IconComponent={HistoryEdu}>
      <EditableCollection
        label={TRANSLATIONS.PROFILE.CERTIFICATES}
        showIndicator={!therapist.certificates?.length}
        indicator={(
          <NotFilled/>
        )}
        addPath="certificates/add"
      >
        {elements.map((cert) => (
          <CertificateItem key={cert.id} item={cert}/>
        ))}
      </EditableCollection>
    </PrimarySection>
  );
}

interface CertificateItemProps {
  item: Certificate;
}

const CertificateItem = ({item}: CertificateItemProps) => {
  const [imgVisible, setImgVisible] = useState(false);

  const toggleImage = useCallback(() => {
    setImgVisible(current => !current);
  }, []);

  return (
    <Box mb={2} mt={2}>
      <EditableInline editPath={`certificates/${item.id}/update`}>
        <CertificateLine onClick={toggleImage}>
          {item.institution}, {item.courseName}, {item.graduationYear}
        </CertificateLine>
      </EditableInline>
      <Collapse in={imgVisible}>
        <StyledImg src={item.photo}/>
      </Collapse>
    </Box>
  );
}

export const CertificateLine = styled('span')`
  cursor: pointer;
`

export const StyledImg = styled('img')`
  width: 100%;
  margin: ${theme.spacing(2)} 0;
  border-radius: ${NORMAL_RADIUS}px;
`
