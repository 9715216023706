import {useFormField} from "@Util/Hooks/FormField.ts";
import {FormDialog} from "@Components/FormDialog.tsx";
import {Translated, useTranslated} from "@Components/Localization/Translated.tsx";
import {TextField} from "@Components/TextField.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {useTherapist} from "../TherapistProvider.tsx";
import {useTherapistAPI} from "@API/TherapistAPI.ts";
import {Required} from "@Util/Validation/Required.ts";
import {TRANSLATIONS} from "@assets/translation.ts";

export const AboutMeDialog = () => {
  const therapist = useTherapist();
  const api = useTherapistAPI();

  const summary = useFormField<string>({
    initialValue: therapist.summary ?? '',
    validators: [
      new Required(),
    ],
  })

  const aboutMe = useFormField<string>({
    initialValue: therapist.about ?? '',
    validators: [
      new Required(),
    ]
  });

  const summaryPlaceholder = useTranslated(TRANSLATIONS.PROFILE.ABOUT_ME_SHORT_PLACEHOLDER);
  const detailsPlaceholder = useTranslated(TRANSLATIONS.PROFILE.ABOUT_ME_LONGER_PLACEHOLDER)

  const handleSubmit = async () => {
    await api.updateAboutMe(summary.value as string, aboutMe.value as string);
  };

  const title = <Translated value={TRANSLATIONS.PROFILE.ABOUT_ME}/>

  return (
    <FormDialog title={title} onSubmit={handleSubmit} maxWidth="sm">
      <TextField
        field={summary}
        name="summary"
        multiline={true}
        minRows={2}
        label={(
          <Translated value={TRANSLATIONS.PROFILE.ABOUT_ME_SHORT}/>
        )}
        placeholder={summaryPlaceholder}
      />

      <TextField
        field={aboutMe}
        name="about"
        multiline={true}
        minRows={3}
        label={(
          <Translated value={TRANSLATIONS.PROFILE.ABOUT_ME_LONGER}/>
        )}
        placeholder={detailsPlaceholder}
      />

      <ActionButton requireValid={[summary, aboutMe]}/>
    </FormDialog>
  );
}
