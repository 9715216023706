import { useAxios } from '@Util/Hooks/Http.tsx';
import { AxiosInstance } from 'axios';

export class API {
  constructor(protected readonly http: AxiosInstance) {}
}

export type APIConstructor<T> = {
  new (http: AxiosInstance): T;
}

export interface SearchResults<T> {
  elements: T[],
  total: number;
}

export const useAPI = <T extends API>(constructor: APIConstructor<T>): T => {
  const http = useAxios();

  return new constructor(http);
}
