import {Grid} from "@mui/material";
import {useDebounce} from "@uidotdev/usehooks";
import {TextField} from "@Components/TextField.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {SelectField} from "@Components/SelectField.tsx";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {useLocalisedTargets} from "@Components/Providers/TargetsProvider.tsx";
import {useFormSelectField} from "@Util/Hooks/FormSelectField.ts";
import {useSearchParam} from "@Util/Hooks/UseSearchParam.tsx";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export enum SearchParameters {
  QUERY = 'query',
  TARGET = 'target',
}

export const MembersFilter = () => {
  const navigate = useNavigate();

  const searchField = useFormField({
    initialValue: useSearchParam(SearchParameters.QUERY, {
      default: '',
      transform: (v) => v.trim(),
    }),
  });

  const targetField = useFormSelectField({
    initialValue: useSearchParam(SearchParameters.TARGET, {
      default: '',
      transform: v => v
    }),
    options: useLocalisedTargets(),
    getOptionValue: (t) => t.id,
    getOptionLabel: (t) => t.title,
  });

  const debouncedSearchQuery = useDebounce(searchField.value, 500);

  useEffect(() => {
    const params = new URLSearchParams();

    if (debouncedSearchQuery) {
      params.set(SearchParameters.QUERY, debouncedSearchQuery);
    }

    if (targetField.value) {
      params.set(SearchParameters.TARGET, targetField.value);
    }

    navigate({
      search: params.toString()
    });
  }, [debouncedSearchQuery, targetField.value]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={3} xs={12}>
        <TextField
          label={(
            <Translated value="Search"/>
          )}
          field={searchField}
        />
      </Grid>
      <Grid item sm={3} xs={12}>
        <SelectField
          label={(
            <Translated value="Target"/>
          )}
          field={targetField}
        />
      </Grid>
    </Grid>
  );
}
