import {LoaderFunction, Outlet, ScrollRestoration, useLoaderData} from 'react-router-dom';
import {City, CityAPI} from '@API/CityAPI.tsx';
import {Currency, CurrencyAPI} from '@API/CurrencyAPI.ts';
import {Qualification, QualificationAPI} from '@API/QualificationAPI.ts';
import {Topic, TopicsAPI} from '@API/TopicsAPI.ts';
import {WorksWith, WorksWithAPI} from '@API/WorksWithAPI.ts';
import {CurrencyProvider} from '@Components/Providers/CurrencyProvider.tsx';
import {LocalisedCityProvider} from '@Components/Providers/LocalisedCityProvider.tsx';
import {QualificationProvider} from '@Components/Providers/QualificationProvider.tsx';
import {TopicsProvider} from '@Components/Providers/TopicsProvider.tsx';
import {WorksWithProvider} from '@Components/Providers/WorksWithProvider.tsx';
import {createUniversalAPI} from '@Util/UniversalAPI.ts';
import {RegistrationTarget, TargetAPI} from "@API/TargetAPI.ts";
import {TargetsProvider} from "@Components/Providers/TargetsProvider.tsx";

export type MembersListData = [
  Qualification[],
  City[],
  WorksWith[],
  Topic[],
  Currency[],
  RegistrationTarget[],
];

export const membersPageDataLoader: LoaderFunction = ({context}): Promise<MembersListData> => {
  const qualificationAPI = createUniversalAPI(QualificationAPI, context);
  const cityAPI = createUniversalAPI(CityAPI, context);
  const worksWithAPI = createUniversalAPI(WorksWithAPI, context);
  const topicAPI = createUniversalAPI(TopicsAPI, context);
  const currencyAPI = createUniversalAPI(CurrencyAPI, context);
  const targetAPI = createUniversalAPI(TargetAPI, context);


  return Promise.all([
    qualificationAPI.list(),
    cityAPI.list(),
    worksWithAPI.list(),
    topicAPI.list(),
    currencyAPI.list(),
    targetAPI.list(),
  ]);
}

export const MembersPageDataProvider = () => {
  const data = useLoaderData() as MembersListData;

  return (
    <QualificationProvider value={data[0]}>
      <LocalisedCityProvider value={data[1]}>
        <WorksWithProvider value={data[2]}>
          <TopicsProvider value={data[3]}>
            <CurrencyProvider value={data[4]}>
              <TargetsProvider value={data[5]}>
                <Outlet/>
                <ScrollRestoration/>
              </TargetsProvider>
            </CurrencyProvider>
          </TopicsProvider>
        </WorksWithProvider>
      </LocalisedCityProvider>
    </QualificationProvider>
  );
}
