import {Box} from "@mui/material";
import {theme} from "@Util/theme.tsx";
import {TherapyFormat} from "../TherapyFormat.tsx";
import {ButtonLink} from "@Components/Link.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {styled} from "@mui/system";
import {useTherapist} from "../../Account/Therapist/TherapistProvider.tsx";
import {PriceAmountView} from "@Components/Therapist/PriceAmountView.tsx";

export const SecondaryDetails = () => {
  const t = useTherapist();
  return (
    <Wrapper>
      <Box>
        <Box fontWeight="bold">
          <PriceAmountView/>
        </Box>
        <TherapyFormat/>
      </Box>
      <Box>
        <ButtonLink color="primary" to={t.id.toString()}>
          <Translated value="More details"/>
        </ButtonLink>
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  display: flex;
  align-items: flex-end;
  text-align: right;
  flex-direction: column;
  justify-content: space-between;
  min-width: 200px;
  
  ${theme.breakpoints.down('sm')} {
    text-align: left;
    flex-direction: row;
  }
`
