import {useCurrentUser} from "@Util/Hooks/Auth.tsx";
import {FormDialog} from "@Components/FormDialog.tsx";
import {DateCalendar} from "@mui/x-date-pickers";
import {ActionButton} from "@Components/ActionButton.tsx";
import dayjs, {Dayjs} from "dayjs";
import {useMemo, useState} from "react";
import {useAxios} from "@Util/Hooks/Http.tsx";
import {styled} from "@mui/system";
import {Box} from "@mui/material";
import {Translated} from "@Components/Localization/Translated.tsx";
import {NORMAL_RADIUS, theme} from "@Util/theme.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const DateOfBirthDialog = () => {
  const user = useCurrentUser();
  const http = useAxios();
  const [selected, setSelected] = useState<Dayjs | null>(null);

  const onDateChange = (value: Dayjs | null) => {
    setSelected(value);
  }

  const onSubmit = async () => {
    if (selected) {
      await http.patch('/api/user', {
        id: user.id,
        dateOfBirth: selected.format('YYYY-MM-DD'),
      });

      await http.get('/api/auth');
    }
  };

  const defaultDate = useMemo(() => user.dateOfBirth ? dayjs(user.dateOfBirth) : dayjs(), [user.dateOfBirth])
  const maxDate = useMemo(() => dayjs().subtract(18, 'years'), []);

  return (
    <FormDialog title={<Translated value={TRANSLATIONS.PROFILE.DATE_OF_BIRTH}/>} onSubmit={onSubmit}>
      <DatePickerWrapper sx={{mb: 2}}>
        <DateCalendar maxDate={maxDate} onChange={onDateChange} defaultValue={defaultDate}/>
      </DatePickerWrapper>
      <ActionButton/>
    </FormDialog>
  );
}

const DatePickerWrapper = styled(Box)`
  border: 1px solid ${theme.palette.divider};
  border-radius: ${NORMAL_RADIUS}px;
`;
