import {Actions, FormDialog} from "@Components/FormDialog.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {useSearchAwareNavigate, useSearchAwarePath} from "@Util/Hooks/Navigate.tsx";
import {CertificateUpdate, useCertificateAPI} from "@API/CertificateAPI.ts";
import {CertificateForm, useCertificateFormState} from "./CertificateForm.tsx";
import {Button} from "@mui/material";
import {useCertificate} from "./useCertificate.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const CertificateUpdateDialog = () => {
  const api = useCertificateAPI();
  const cert = useCertificate();
  const state = useCertificateFormState(cert);

  const handleSubmit = async () => {
    if (!cert) {
      throw new Error("No item");
    }

    const data: CertificateUpdate = {
      id: cert.id,
      institution: state.institution.value as string,
      courseName: state.courseName.value as string,
      graduationYear: state.graduationYear.getNumericValue() as number,
    };

    if (state.photo.file) {
      data.photo = state.photo.value;
    }

    await api.update(data);
  }

  const title = <Translated value={TRANSLATIONS.PROFILE.UPDATE_CERTIFICATE_TITLE}/>;
  const back = useSearchAwarePath('../../..');
  const navigate = useSearchAwareNavigate();
  const showDeleteDialog = () => navigate('../delete');

  return (
    <FormDialog title={title} onSubmit={handleSubmit} back={back}>
      <CertificateForm state={state}/>
      <Actions>
        <ActionButton/>
        <Button onClick={showDeleteDialog} color="error">
          <Translated value="Delete"/>
        </Button>
      </Actions>
    </FormDialog>
  )
}
