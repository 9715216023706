import {FC, ReactNode} from "react";
import {ButtonProps} from "./ButtonProps.tsx";
import {Button} from "@mui/material";
import {MailOutlined} from "@mui/icons-material";
import {theme} from "@Util/theme.tsx";

export interface ButtonContactWideProps extends ButtonProps {
  children?: ReactNode;
}

export const ButtonContactWide: FC<ButtonContactWideProps> = ({onClick, children}) => {
  return (
    <Button fullWidth variant="contained" onClick={onClick}>
      <MailOutlined sx={{marginRight: `${theme.spacing(1)}`}}/>{children}
    </Button>
  );
}
