import {RouteObject} from "react-router-dom";
import {UserListPage} from "./UserListPage.tsx";
import {UserFormDialog} from "./UserFormDialog.tsx";

export const AdminUserRoute: RouteObject = {
  path: 'users',
  Component: UserListPage,
  children: [
    {
      path: ':id',
      children: [
        {
          path: 'edit',
          Component: UserFormDialog,
        }
      ]
    }
  ],
};
