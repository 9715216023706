import {Translated} from "@Components/Localization/Translated.tsx";
import {useWorksWithAPI} from "@API/WorksWithAPI.ts";
import {useParams} from "react-router-dom";
import {useLocalisedWorksWithItem} from "@Components/Providers/WorksWithProvider.tsx";
import {ConfirmDelete} from "@Components/ConfirmDelete.tsx";
import {useSearchAwarePath} from "@Util/Hooks/Navigate.tsx";

export const WorksWithDeleteDialog = () => {
  const api = useWorksWithAPI();
  const back = useSearchAwarePath('../..');
  const params = useParams<{id: string}>();
  const item = useLocalisedWorksWithItem(params.id);

  const handleSubmit = async () => {
    if (!item) {
      throw new Error('Item not selected');
    }

    await api.delete(item.id);
  }

  return (
    <ConfirmDelete title={<Translated value="Works with delete"/>} onSubmit={handleSubmit} back={back}/>
  );
}
