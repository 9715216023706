import { API } from '@API/API.ts';
import { Language } from '@Components/I18nWrapper.tsx';
import { useAxios } from '@Util/Hooks/Http.tsx';
import { useMemo } from 'react';

export interface CountryI18n {
  language: Language,
  name: string,
}

export interface CountryPK {
  id: number;
}

export interface Country extends CountryPK {
  name: string;
  i18n?: CountryI18n[];
}

export const useCountryAPI = () => {
  const http = useAxios();

  return useMemo(() => new CountryAPI(http), [http]);
}

export class CountryAPI extends API {
  private readonly ENDPOINT = '/api/country';

  async list(): Promise<Country[]> {
    return this.http.get(this.ENDPOINT).then(r => r.data);
  }

  async create(name: string): Promise<Country> {
    return this.http.post(this.ENDPOINT, {name}).then(r => r.data);
  }

  async update(id: number, name: string, locale: string): Promise<Country> {
    return this.http.put(this.ENDPOINT, {id, name, locale}).then(r => r.data);
  }

  async delete(id: number): Promise<void> {
    await this.http.delete(this.ENDPOINT, {
      data: {id},
    });
  }
}
