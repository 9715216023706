import {Helmet} from "react-helmet-async";
import {AxiosError} from "axios";
import {Card, Grid} from "@mui/material";
import {LoaderFunction, Outlet, useLoaderData, useLocation, useNavigate} from "react-router-dom";
import {SearchParams, Therapist, TherapistAPI} from "@API/TherapistAPI.ts";
import {createUniversalAPI} from "@Util/UniversalAPI.ts";
import {Headline} from "@Components/Headline.tsx";
import {TransSection} from "@Components/Localization/TransSection.tsx";
import {useTranslated} from "@Components/Localization/Translated.tsx";
import {TherapistsFilter} from "../Partial/TherapistsFilter.tsx";
import {TherapistPreview} from "./TherapistPreview.tsx";

interface SearchLoaderData {
  list: Therapist[];
  error?: AxiosError;
}

export const searchDataLoader: LoaderFunction = async ({request, context}): Promise<SearchLoaderData> => {
  const therapistAPI = createUniversalAPI(TherapistAPI, context);
  const searchParams = new URL(request.url).searchParams;

  try {
    const list = await therapistAPI.search({
      [SearchParams.NAME]: searchParams.get(SearchParams.NAME) ?? undefined,
      [SearchParams.TOPIC]: searchParams.get(SearchParams.TOPIC) ?? undefined,
      [SearchParams.WORKS_WITH]: searchParams.get(SearchParams.WORKS_WITH) ?? undefined,
      [SearchParams.LANGUAGE]: searchParams.get(SearchParams.LANGUAGE) ?? undefined,
    });

    return {list}
  } catch (error) {
    console.error(error);

    if (error instanceof AxiosError) {
      return {list: [], error}
    } else {
      return {list: []}
    }
  }
}

export const TherapistsSearchPage = () => {
  const {list} = useLoaderData() as SearchLoaderData;
  const metaTitle = useTranslated("Find a therapist");
  const metaDescription = useTranslated('Search the therapist that fits your needs');
  const currentLocation = useLocation();
  const navigate = useNavigate();

  const onSearchChange = (loc: Partial<Location>) => {
    if (currentLocation.search !== loc.search) {
      navigate(loc, {preventScrollReset: true, replace: true})
    }
  }

  return (
    <TransSection section="profile">
      <Headline text="Find a therapist" level={1}/>

      <Grid container spacing={2} direction="row-reverse">
        <Grid item xs={12} md={4}>
          <Card sx={{p: 2}}>
            <TherapistsFilter onChange={onSearchChange}/>
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          {list.map(t => (
            <TherapistPreview key={t.id} item={t}/>
          ))}
        </Grid>
      </Grid>

      <Outlet/>
      <Helmet>
        <title>{metaTitle} | ProGestalt</title>
        <meta name="title" content={metaTitle}/>
        <meta name="description" content={metaDescription}/>
      </Helmet>
    </TransSection>
  )
}
