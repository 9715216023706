import {useEffect, useState} from "react";
import {useSSR} from "@Util/Hooks/UseSSR.ts";

export const useTabActive = () => {
  const isSSR = useSSR();
  const [isActive, setActive] = useState(true);

  useEffect(() => {
    if (isSSR) {
      return;
    }

    const onFocus = () => setActive(true);
    const onBlur = () => setActive(false);

    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur);
    }
  }, []);

  return isActive;
}
