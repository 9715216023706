import {Translated} from "@Components/Localization/Translated.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";
import {TextField} from "@Components/TextField.tsx";
import {PasswordField} from "@Components/PasswordField.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {FormDialog} from "@Components/FormDialog.tsx";
import {To, useLocation} from "react-router-dom";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {IsEmail, Required} from "@Util/Validation";
import {FC, ReactNode} from "react";
import {Link} from "@Components/Link.tsx";
import {useLoginPath} from "@Components/AuthenticationGuard.tsx";
import {TransSection} from "@Components/Localization/TransSection";
import {useAxios} from "@Util/Hooks/Http.tsx";
import {useNotifications} from "@Util/Hooks/Notifications.tsx";
import {CLIENT_MEMBERSHIP_ID} from "@API/MembersAPI.ts";

export interface RegistrationDialogProps {
  title: ReactNode;
}

export const RegistrationDialog: FC<RegistrationDialogProps> = ({title}) => {
  const location = useLocation();
  const http = useAxios();
  const showNotification = useNotifications();

  const firstName = useFormField<string>({
    initialValue: '',
    validators: [
      new Required(),
    ],
  });

  const lastName = useFormField<string>({
    initialValue: '',
    validators: [
      new Required(),
    ],
  });

  const email = useFormField<string>({
    initialValue: '',
    validators: [
      new Required(),
      new IsEmail(),
    ]
  });

  const password = useFormField<string>({
    initialValue: '',
    validators: [
      new Required(),
    ],
  });

  const loginPath: To = useLoginPath();

  const handleSubmit = async () => {
    await http
      .post('/api/auth/register', {
        firstName: firstName.value,
        lastName: lastName.value,
        userName: email.value,
        password: password.value,
        membership: CLIENT_MEMBERSHIP_ID,
      })
      .catch(() => {
        showNotification({
          variant: 'error',
          message: 'Something went wrong. Please try again later',
        });
      });
  }

  return (
    <FormDialog title={title} onSubmit={handleSubmit} redirect={location}>
      <TransSection section="auth">
        <TextField
          field={firstName}
          label={(
            <Translated value={TRANSLATIONS.AUTH.FIRST_NAME}/>
          )}
        />

        <TextField
          field={lastName}
          label={(
            <Translated value={TRANSLATIONS.AUTH.LAST_NAME}/>
          )}
        />

        <TextField
          type="email"
          field={email}
          label={(
            <Translated value={TRANSLATIONS.AUTH.EMAIL}/>
          )}
        />

        <PasswordField
          field={password}
          label={(
            <Translated value={TRANSLATIONS.AUTH.PASSWORD}/>
          )}
        />

        <p>
          <Translated value={TRANSLATIONS.AUTH.HAVE_ACCOUNT_ALREADY}/>&nbsp;
          <Link to={loginPath}>
            <Translated value="Log in"/>.
          </Link>
        </p>

        <ActionButton onClick={handleSubmit}>
          <Translated value={TRANSLATIONS.AUTH.REGISTER}/>
        </ActionButton>
      </TransSection>
    </FormDialog>
  )
}
