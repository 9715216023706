import {RouteObject} from "react-router-dom";
import {dataLoader, TherapistsDataProvider} from "@Components/Providers/TherapistsDataProvider.tsx";
import {searchDataLoader, TherapistsSearchPage} from "./Search/TherapistsSearchPage.tsx";
import {TherapistDetailsPage} from "./Details/TherapistDetailsPage.tsx";
import {TherapistContactDialog} from "./TherapistContactDialog.tsx";
import {therapistParamLoader} from "./TherapistLoader.tsx";

export const TherapistsRoute: RouteObject = {
  loader: dataLoader,
  Component: TherapistsDataProvider,
  shouldRevalidate: () => false,
  children: [
    {
      path: 'therapists',
      children: [
        {
          index: true,
          loader: searchDataLoader,
          Component: TherapistsSearchPage,
        },
        {
          path: ':id',
          loader: therapistParamLoader,
          Component: TherapistDetailsPage,
          children: [
            {
              path: 'contact',
              Component: TherapistContactDialog,
            }
          ]
        },
      ],
    },
  ],
}
