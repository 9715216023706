import {TherapyLocation} from "@API/LocationsAPI.ts";
import {useLocalisedCities} from "@Components/Providers/LocalisedCityProvider.tsx";
import {useMemo} from "react";

const useCity = (id: number) => {
  const list = useLocalisedCities();

  return useMemo(() => {
    return list.find(item => item.id === id);
  }, [id, list])
}

export interface LocationLabelProps {
  location: TherapyLocation;
}

export const LocationLabel = ({location}: LocationLabelProps) => {
  const city = useCity(location.city.id);

  if (!city) {
    return <>Broken ({location.city.id})</>
  }

  return city.localisedName ?? city.name;
}
