import {useFormField} from "@Util/Hooks/FormField.ts";
import {FormDialog} from "@Components/FormDialog.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {TextField} from "@Components/TextField.tsx";
import {Box} from "@mui/material";
import {Required} from "@Util/Validation/Required.ts";
import {IsURL} from "@Util/Validation/IsURL.ts";
import {useLinksAPI} from "@API/Links.ts";
import {useMemo} from "react";
import {useParams} from "react-router-dom";
import {CheckboxField} from "@Components/CheckboxField.tsx";
import {ExternalLink} from "@Components/Link.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {EditableCollection} from "../../EditableCollection.tsx";
import {EditableInline} from "../../Editable.tsx";
import {useTherapist} from "../TherapistProvider.tsx";
import {PrimarySection} from "../PrimarySection.tsx";
import {AdsClick} from "@mui/icons-material";
import {TRANSLATIONS} from "@assets/translation.ts";


export const SocialNetworks = () => {
  const therapist = useTherapist();
  const links = therapist.links ?? [];

  return (
    <PrimarySection IconComponent={AdsClick}>
      <EditableCollection
        label={TRANSLATIONS.PROFILE.SOCIAL_NETWORKS_LINKS}
        addPath="social/add"
        indicator={(
          <Translated value="Not filled"/>
        )}
        showIndicator={links.length === 0}
      >
        {links.map(({id, url}) => (
          <Box key={id} mb={2} mt={2}>
            <EditableInline editPath={`social/${id}/update`}>
              <ExternalLink url={url}/>
            </EditableInline>
          </Box>
        ))}
      </EditableCollection>
    </PrimarySection>
  );
}

export const SocialNetworkDialog = () => {
  const therapist = useTherapist();
  const params = useParams<{id: string}>();

  const links = therapist.links ?? [];
  const link = useMemo(() => {
    if (params.id) {
      const id = parseInt(params.id);
      return links.find(l => l.id === id);
    }

    return undefined;
  }, [links]);

  const api = useLinksAPI();
  const url = useFormField<string>({
    initialValue: link?.url ?? '',
    validators: [
      new Required(),
      new IsURL(),
    ],
  });

  const isDelete = useFormField<boolean>({
    initialValue: false,
  });

  const handleSubmit = async () => {
    if (isDelete.value && link?.id) {
       await api.deleteLink({
         id: link.id,
       });
    } else {
      if (link) {
        await api.updateLink({
          id: link.id,
          url: url.value as string,
        });
      } else {
        await api.addLink({
          url: url.value as string,
        });
      }
    }
  }

  const checkboxLabel = <Translated value={TRANSLATIONS.PROFILE.DELETE_THIS_LINK}/>;
  const requireValid = isDelete.value ? [] : [url];
  const title = <Translated value={TRANSLATIONS.PROFILE.SOCIAL_NETWORKS_LINK}/>;

  return (
    <FormDialog title={title} onSubmit={handleSubmit} maxWidth="md">
      <TextField field={url} type="url" label="URL" autoComplete="off" />

      {link && (
        <CheckboxField field={isDelete} label={checkboxLabel}/>
      )}

      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <ActionButton requireValid={requireValid}/>
      </Box>
    </FormDialog>
  );
}
