import { API, SearchResults, useAPI } from '@API/API.ts';
import { Therapist } from '@API/TherapistAPI.ts';

export const useTherapistAdminAPI = () => useAPI(TherapistAdminAPI);

const ENDPOINT = '/api/admin/therapist';

export interface ReviewOptions {
  id: number;
  approved: boolean;
}
export interface TherapistSearch {
  query?: string;
  status?: string;
}

export class TherapistAdminAPI extends API {
  async search(params?: TherapistSearch): Promise<SearchResults<Therapist>> {
    return await this.http.get(ENDPOINT, {params}).then(r => r.data);
  }

  async details(id: number): Promise<Therapist> {
    return await this.http.get(ENDPOINT + `/${id}`).then(r => r.data)
  }

  async review(data: ReviewOptions): Promise<Therapist> {
    return await this.http.put(ENDPOINT, data).then(r => r.data);
  }
}
