import {Outlet} from "react-router-dom";
import {Headline} from "@Components/Headline.tsx";
import {useMembershipOptions} from "@Components/Membership/useMembershipOptions.tsx";
import {useCurrentUser} from "@Util/Hooks/Auth.tsx";
import {MembershipDropdownOption} from "./MembershipDropdownOption.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {PanelSpaced} from "@Components/Layout/Panel.tsx";
import {Link} from "@Components/Link.tsx";
import {Box, Grid} from "@mui/material";
import {styled} from "@mui/system";
import {NORMAL_RADIUS, theme} from "@Util/theme.tsx";

export const MembershipPage = () => {
  const user = useCurrentUser();
  const membershipOptions = useMembershipOptions();
  const currentType = user.membership?.pendingType ?? user.membership?.type;
  const currentMembership = membershipOptions.find(o => o.id === currentType?.id);

  return (
    <>
      <Headline text="My membership" level={1}/>

      <Grid container spacing={2}>
        <Grid item sm={8} xs={12}>
          <PanelSpaced>
            <Headline text="Membership type" level={2}/>

            <AlignedRight>
              <RoundBox>
                {currentMembership ? (
                  <MembershipDropdownOption option={currentMembership}/>
                ) : (
                  <Translated value="No membership"/>
                )}
              </RoundBox>

              <Box mr={2}>
                <Link to="type">
                  <Translated value="change"/>
                </Link>
              </Box>
            </AlignedRight>

            <Headline text="Payment method" level={2}/>

            <AlignedRight>
              <RoundBox>
                <Translated value="No payment method"/>
              </RoundBox>
              <Box mr={2}>
                <Link to="pay">
                  <Translated value="add"/>
                </Link>
              </Box>
            </AlignedRight>
          </PanelSpaced>
        </Grid>
        <Grid item sm={4} xs={12}>
          <PanelSpaced>
            <Headline text="Payment history" level={2}/>
            <p>
              <Translated value="No payments"/>
            </p>
          </PanelSpaced>
        </Grid>
      </Grid>

      <Outlet/>
    </>
  )
};

const RoundBox = styled('div')`
  padding: ${theme.spacing(2)};
  border: 1px solid ${theme.palette.secondary.dark};
  border-radius: ${NORMAL_RADIUS}px;
  width: 100%;
`

const AlignedRight = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: ${theme.spacing(1)};
`

