import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import {TranslationSection, useTranslationAPI} from "@API/TranslationAPI.ts";

const TransSectionsContext = createContext<TranslationSection[]>([]);

export const AvailableTransSectionsProvider = (props: PropsWithChildren) => {
  const [sections, setSections] = useState<TranslationSection[]>([]);
  const api = useTranslationAPI();

  useEffect(() => {
    api.listSections().then(setSections);
  }, [])

  return <TransSectionsContext.Provider value={sections}>{props.children}</TransSectionsContext.Provider>
}

export const useTransSections = () => useContext(TransSectionsContext);


