import React, {FC} from "react";
import {YearsOfExperienceView} from "@Components/Therapist/YearsOfExperienceView.tsx";
import {useTranslated} from "@Components/Localization/Translated.tsx";
import {EditableInline} from "../../Editable.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const YearsOfExperienceSection: FC = React.memo(() => {
  const label = useTranslated(TRANSLATIONS.PROFILE.YEARS_OF_EXPERIENCE);

  return (
    <EditableInline label={label} editPath="years-of-experience">
      <YearsOfExperienceView/>
    </EditableInline>
  );
});
