import {useUsers} from "./UserListPage.tsx";
import {useParams} from "react-router-dom";
import {FormDialog} from "@Components/FormDialog.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {TextField} from "@Components/TextField.tsx";
import {CheckboxField} from "@Components/CheckboxField.tsx";
import {PasswordField} from "@Components/PasswordField.tsx";
import {useAxios} from "@Util/Hooks/Http.tsx";
import {useFormSelectField} from "@Util/Hooks/FormSelectField.ts";
import {SelectField} from "@Components/SelectField.tsx";

const useUser = (id: string) => useUsers().find(user => user.id === parseInt(id));

interface Role {
  id: string;
}

const roles: Role[] = [
  {id: 'user'},
  {id: 'admin'},
];

export const UserFormDialog = () => {
  const http = useAxios();
  const params = useParams<{ id: string }>();
  const user = useUser(params.id ?? '');

  const firstName = useFormField({
    initialValue: user?.firstName ?? '',
  });

  const lastName = useFormField({
    initialValue: user?.lastName ?? '',
  })

  const email = useFormField({
    initialValue: user?.userName ?? ''
  });

  const role = useFormSelectField<Role, string>({
    initialValue: user?.role?.id ?? '',
    options: roles,
    getOptionValue: role => role.id,
    getOptionLabel: role => role.id.charAt(0).toUpperCase() + role.id.slice(1),
  });

  const changePassword = useFormField<boolean>({
    initialValue: false,
  });

  const newPassword = useFormField<string>({
    initialValue: '',
  });

  const handleSubmit = async () => {
    await http.put('/api/user', {
      id: user?.id,
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      role: role.value,
      changePassword: changePassword.value ?? false,
      newPassword: newPassword.value ? newPassword.value : undefined,
    });
  };

  return (
    <FormDialog title="Update user" onSubmit={handleSubmit}>
      <TextField field={firstName} label="First name"/>
      <TextField field={lastName} label="Last name"/>
      <TextField field={email} label="Email"/>

      <SelectField field={role} label="Role"/>

      <CheckboxField field={changePassword} label="Change password"/>
      {changePassword.value && (
        <PasswordField field={newPassword} label="New password"/>
      )}

      <ActionButton>Save</ActionButton>
    </FormDialog>
  )
}
