import {Therapist} from "@API/TherapistAPI.ts";
import {Card} from "@mui/material";
import {styled} from "@mui/system";
import {theme} from "@Util/theme.tsx";
import {PrimaryDetails} from "./PrimaryDetails.tsx";
import {SecondaryDetails} from "./SecondaryDetails.tsx";
import {TherapistProvider} from "../../Account/Therapist/TherapistProvider.tsx";

export interface TherapistPreviewProps {
  item: Therapist;
}

export const TherapistPreview = ({item}: TherapistPreviewProps) => {
  return (
    <TherapistProvider value={item}>
      <TherapistCard>
        <PrimaryDetails/>
        <SecondaryDetails/>
      </TherapistCard>
    </TherapistProvider>
  );
}

export const TherapistCard = styled(Card)`
  padding: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(2)};
  display: flex;

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
    gap: ${theme.spacing(2)};
  }
`

export const Heading = styled('h2')`
  margin: 0;
  font-weight: 600;
`
