import {RouteObject} from "react-router-dom";
import {MembersListPage} from "./MembersListPage.tsx";
import {AccountPreviewDialog} from "./Dialog/AccountPreviewDialog.tsx";
import {memberReviewLoader} from "./Loader/MemberReviewLoader.tsx";
import {membersPageDataLoader, MembersPageDataProvider} from "./Loader/MembersPageDataProvider.tsx";
import {AccountApproveDialog} from "./Dialog/AccountApproveDialog.tsx";

export const AdminMembersRoute: RouteObject = {
  loader: membersPageDataLoader,
  Component: MembersPageDataProvider,
  shouldRevalidate: () => false,
  children: [
    {
      path: 'members',
      Component: MembersListPage,
      children: [
        {
          path: ':id',
          children: [
            {
              path: 'preview',
              loader: memberReviewLoader,
              Component: AccountPreviewDialog,
            },
            {
              path: 'approve',
              loader: memberReviewLoader,
              Component: AccountApproveDialog
            }
          ]
        }
      ]
    }
  ]
}
