import {FormDialog} from "@Components/FormDialog.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {useTopicsAPI} from "@API/TopicsAPI.ts";
import {useParams} from "react-router-dom";
import {useLocalisedTopic} from "@Components/Providers/TopicsProvider.tsx";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {Required} from "@Util/Validation/Required.ts";
import {ActionButton} from "@Components/ActionButton.tsx";
import {TextField} from "@Components/TextField.tsx";
import {useSearchAwarePath} from "@Util/Hooks/Navigate.tsx";

export const TopicEditDialog = () => {
  const api = useTopicsAPI();
  const back = useSearchAwarePath('../..');
  const params = useParams<{id: string}>();
  const topic = useLocalisedTopic(params.id);

  const title = useFormField({
    initialValue: topic?.translation?.title ?? '',
    validators: [
      new Required(),
    ],
  });

  const handleSubmit = async () => {
    if (!topic) {
      throw new Error('No topic');
    }

    await api.update({
      id: topic.id,
      title: title.value as string,
      locale: topic.translation.locale,
    });
  }

  return (
    <FormDialog title={<Translated value="Topic update"/>} onSubmit={handleSubmit} back={back}>
      <TextField
        field={title}
        label={(
          <Translated value="Title"/>
        )}
      />

      <ActionButton requireValid={[title]}/>
    </FormDialog>
  )
}
