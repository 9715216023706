import { API } from '@API/API.ts';
import { Certificate } from '@API/CertificateAPI.ts';
import { Education } from '@API/EducationAPI.ts';
import { UserLink } from '@API/Links.ts';
import { TherapyLocation } from '@API/LocationsAPI.ts';
import { MembershipPK } from '@API/MembershipAPI.ts';
import { Topic } from '@API/TopicsAPI.ts';
import { WorksWith } from '@API/WorksWithAPI.ts';
import { Language } from '@Components/I18nWrapper.tsx';
import { AuthUserPK } from '@Util/Hooks/Auth.tsx';
import { useAxios } from '@Util/Hooks/Http.tsx';

export const useTherapistAPI = () => {
  const http = useAxios();

  return new TherapistAPI(http);
}

export interface TherapistPK {
  id: number;
}

export enum TherapistStatus {
  APPROVED = 'approved',
  DRAFT = 'draft',
}

export interface Price {
  amount: number;
  currency: {
    id: string;
  }
}

export interface Therapist extends TherapistPK {
  user?: AuthUserPK;
  name?: string;
  avatar?: string;
  yearsOfExperience?: number;
  age?: number;
  inReviewSince?: string;
  summary?: string;
  about?: string;
  worksOnline?: boolean;
  worksWith?: WorksWith[];
  topics?: Topic[];
  languages?: Language[];
  locations?: TherapyLocation[];
  education?: Education[];
  certificates?: Certificate[];
  links?: UserLink[];
  status?: TherapistStatus;
  isActive?: boolean;
  price?: Price;
  membershipType?: MembershipPK
  video?: string;
}

export enum SearchParams {
  NAME = 'name',
  TOPIC = 'topic',
  WORKS_WITH = 'worksWith',
  LANGUAGE = 'language',
}

export type TherapistSearch = {
  [x in SearchParams]?: string;
}

export interface UpdatePrice {
  amount: number;
  currency: string;
}

export class TherapistAPI extends API {
  async search(params: TherapistSearch): Promise<Therapist[]> {
    return this.http.get('/api/therapist/search', {params}).then(r => r.data);
  }

  async details(id: number): Promise<Therapist> {
    return this.http
      .get(`/api/therapist/${id}`)
      .then(r => r.data);
  }

  async current(): Promise<Therapist> {
    const response = await this.http.get('/api/therapist');

    return response.data;
  }

  async updatePrice(data: UpdatePrice) {
    return await this.update('/api/therapist/price', data);
  }

  async updateYearsOfExperience(yearsOfExperience: number) {
    return await this.update('/api/therapist/years-of-experience', {yearsOfExperience});
  }

  async updateAboutMe(summary:string, about: string): Promise<Therapist> {
    return await this.update('/api/therapist/about', {
      summary: summary,
      about: about,
    });
  }

  async updateWorkOnline(status: boolean): Promise<Therapist> {
    return await this.update('/api/therapist/online', {status});
  }

  async updateWorksWith(ids: number[]): Promise<Therapist> {
    return await this.update('/api/therapist/works-with', {
      worksWith: ids,
    });
  }

  async updateTopics(ids: number[]): Promise<Therapist> {
    return await this.update('/api/therapist/topics', {
      topics: ids,
    });
  }

  async updateLanguages(codes: string[]): Promise<Therapist> {
    return await this.update('/api/therapist/languages', {
      locales: codes,
    });
  }

  async sendToReview(): Promise<Therapist> {
    return await this.update('/api/therapist/review', {
      terms: true,
      policy: true,
    });
  }

  private async update(path: string, data: Record<string, any>) {
    const response = await this.http.put(path, data);

    return response.data as Therapist;
  }
}
