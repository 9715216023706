import {SearchParams} from "@API/TherapistAPI.ts";

export interface BuildSearchQueryProps {
  name?: string;
  topics?: number[];
  worksWith?: number[];
  languages?: string[];
}

export const buildSearchQuery = ({name, topics, worksWith, languages}: BuildSearchQueryProps) => {
  const params: Record<string, string | undefined> = {};

  if (name) {
    params[SearchParams.NAME] = name;
  }

  if (topics && topics.length > 0) {
    params[SearchParams.TOPIC] = topics.join(',');
  }

  if (worksWith && worksWith.length > 0) {
    params[SearchParams.WORKS_WITH] = worksWith.join(',');
  }

  if (languages && languages.length) {
    params[SearchParams.LANGUAGE] = languages.join(',');
  }

  // UrlSearchParams not handles coma well.
  let search: string = '';

  for (const key in params) {
    if (params[key]) {
      const param = `${key}=${params[key]}`
      if (!search) {
        search = '?' + param;
      } else {
        search += '&' + param;
      }
    }
  }

  return search;
}
