import {useLoaderData} from "react-router-dom";
import {MemberReviewData} from "../Loader/MemberReviewLoader.tsx";
import {TherapistPreview} from "./TherapistPreview.tsx";
import {ClientPreview} from "./ClientPreview.tsx";

export const AccountPreviewDialog = () => {
  const [user, therapist] = useLoaderData() as MemberReviewData;

  if (therapist) {
    return <TherapistPreview user={user} therapist={therapist}/>
  } else {
    return <ClientPreview user={user}/>
  }
}

