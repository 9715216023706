import {Edit} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import {ButtonProps} from "./ButtonProps.tsx";

export const EditButton = ({onClick}: ButtonProps) => {
  return (
    <IconButton onClick={onClick}>
      <Edit/>
    </IconButton>
  );
};
