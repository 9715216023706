import {NotFilled} from "@Components/NotFilled.tsx";
import {EditableList, EditableListItem} from "../../EditableList.tsx";
import {useTherapist} from "../TherapistProvider.tsx";
import {useLocalisedTopics} from "@Components/Providers/TopicsProvider.tsx";
import {useMemo} from "react";
import {PrimarySection} from "../PrimarySection.tsx";
import {PsychologyAlt} from "@mui/icons-material";
import {TRANSLATIONS} from "@assets/translation.ts";

export const TopicsSection = () => {
  const topics = useLocalisedTopics();
  const therapist = useTherapist();

  const selected: EditableListItem[] = useMemo((): EditableListItem[] => {
    const ids = therapist.topics?.map(t => t.id) ?? [];

    return topics
      .filter(t => ids.includes(t.id))
      .map(t => ({
        key: t.id,
        value: t.translation.title ?? t.title,
      }));
  }, [topics, therapist.topics]);

  return (
    <PrimarySection IconComponent={PsychologyAlt}>
      <EditableList editPath="topics" label={TRANSLATIONS.PROFILE.TOPICS_AND_PROBLEMS} items={selected}>
        {!selected.length && <NotFilled/>}
      </EditableList>
    </PrimarySection>
  )
}
