import {Translated} from "@Components/Localization/Translated.tsx";
import {EditableInline} from "../../Editable.tsx";
import {useTherapist} from "../TherapistProvider.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const WorkOnlineSection = () => {
  const therapist = useTherapist();

  return (
    <EditableInline editPath="work-online" label={TRANSLATIONS.PROFILE.I_WORK_ONLINE}>
      <Translated value={therapist.worksOnline ? 'Yes' : 'No'}/>
    </EditableInline>
  );
}
