import {useAxios} from "@Util/Hooks/Http.tsx";
import {API} from "@API/API.ts";
import {useMemo} from "react";
import {Language} from "@Components/I18nWrapper.tsx";
import {CountryPK} from "@API/CountryAPI.ts";

export interface CityI18n {
  language: Language;
  name: string;
}

export interface CityPK {
  id: number;
}

export interface City extends CityPK {
  name: string;
  country: CountryPK;
  i18n: CityI18n[];
}

export const useCityAPI = () => {
  const http = useAxios();

  return useMemo(() => new CityAPI(http), [http]);
}

export class CityAPI extends API {
  private readonly ENDPOINT = '/api/city';
  async list(): Promise<City[]> {
    return this.http.get(this.ENDPOINT).then(r => r.data);
  }

  async create(country: number, name: string): Promise<City> {
    return this.http
      .post(this.ENDPOINT, {
        country,
        name,
      })
      .then(r => r.data);
  }

  async update(data: CityUpdate): Promise<City> {
    return this.http
      .put(this.ENDPOINT, data)
      .then(r => r.data);
  }

  async delete(id: number) {
    await this.http.delete(this.ENDPOINT, {
      data: {
        id,
      }
    });
  }
}

export interface CityUpdate {
  id: number;
  name: string;
  i18n: {
    locale: string;
    name: string;
  }
}
