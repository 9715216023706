import {useCurrentUser} from "@Util/Hooks/Auth.tsx";
import {FormDialog} from "@Components/FormDialog.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {SelectField} from "@Components/SelectField.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {useUserAPI} from "@API/User.ts";
import {useNotifications} from "@Util/Hooks/Notifications.tsx";
import {useMembershipSelectField} from "./MembershipField.tsx";

export const ChangeMembershipDialog = () => {
  const user = useCurrentUser();
  const userAPI = useUserAPI();
  const showNotification = useNotifications();
  const membershipField = useMembershipSelectField(user);

  const onSubmit = async () => {
    try {
      await userAPI.changeMembership({
        id: user.id as number,
        membership: membershipField.selected?.id as number,
      });
    } catch (error) {
      showNotification({
        variant: 'error',
        message: <Translated value="Failed to update membership"/>
      });

      throw error;
    }
  };

  return (
    <FormDialog
      maxWidth="sm"
      title={(
        <Translated value="Change membership type"/>
      )}
      onSubmit={onSubmit}
      >

      <SelectField
        field={membershipField}
        label={(
          <Translated value="Membership type"/>
        )}
      />

      <p>
        <Translated value="Membership will be updated after review"/>
      </p>
      <p>
        <Translated value="If current membership is already paid we will refund and charge for new membership type."/>
      </p>
      <p>
        <Translated value="Refundable amount is subscription price divided by number of days in month and then muliplied by number of days it being active."/>
      </p>
      <ActionButton/>
    </FormDialog>
  );
}
