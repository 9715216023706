import {Box} from "@mui/material";
import {Editable, EditableProps} from "./Editable.tsx";
import {ReactNode} from "react";

export interface EditableListItem {
  key: string|number,
  value: ReactNode,
}

export interface EditableListProps extends EditableProps {
  items?: EditableListItem[];
}

export const EditableList = ({editPath, label, children, items}: EditableListProps) => {
  return (
    <Box>
      <Editable editPath={editPath} label={label}>
        {children}
      </Editable>
      {items && items.length > 0 && (
        <Box display="flex" flexDirection="column" gap={2}>
          {items.map(item => (
            <Box key={item.key}>
              {item.value}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
