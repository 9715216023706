import {FCProps} from "@Util/FCProps.ts";
import {Box, createTheme, ThemeProvider, Tooltip} from "@mui/material";
import {Edit} from "@mui/icons-material";
import {styled} from "@mui/system";
import {theme} from "@Util/theme.tsx";
import {Link} from "@Components/Link.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {To} from "react-router-dom";
import {useEditableContext} from "./EditableProvider.tsx";

export interface EditableProps extends FCProps {
  editPath: To;
  label?: string;
}

const themeOverride = createTheme({
  components: {
    MuiSvgIcon: {
      defaultProps: {
        sx: {
          width: 16,
          height: 16,
        }
      }
    }
  }
})

export const Editable = (props: EditableProps) => {
  const canEdit = useEditableContext();

  return (
    <>
      <WithLabel label={props.label}>
        {canEdit && (
          <Tooltip arrow title={<Translated value="tooltip_edit"/>} placement="right">
            <ActionLink to={props.editPath}>
              <Edit/>
            </ActionLink>
          </Tooltip>
        )}
      </WithLabel>
      <Box>{props.children}</Box>
    </>
  );
}

export const EditableInline = ({label, children, editPath}: EditableProps) => {
  const canEdit = useEditableContext();

  return (
    <ThemeProvider theme={themeOverride}>
      <SpaceBetween>
        <Box>
          {label && (
            <Box mr={1} sx={{display: 'inline', fontWeight: 'bold'}}>
              <Translated value={label}/>:
            </Box>
          )}
          <span>{children}</span>
        </Box>

        {canEdit && (
          <Tooltip arrow title={<Translated value="tooltip_edit"/>} placement="right">
            <ActionLink to={editPath}>
              <Edit/>
            </ActionLink>
          </Tooltip>
        )}
      </SpaceBetween>
    </ThemeProvider>
  );
};

export interface WithLabelProps extends FCProps {
  label?: string;
}

export const WithLabel = ({label, children}: WithLabelProps) => (
  <Box sx={{mb: 2}}>
    <SpaceBetween>
      {label && (
        <Box sx={{display: 'inline', fontWeight: 'bold'}}>
          <Translated value={label}/>:
        </Box>
      )}

      {children && (
        <ThemeProvider theme={themeOverride}>
          {children}
        </ThemeProvider>
      )}
    </SpaceBetween>
  </Box>
);

export const ActionLink = styled(Link)`
  color: ${theme.palette.grey['300']};
  text-decoration: none;
  vertical-align: middle;

  &:hover {
    color: ${theme.palette.primary.main};
  }
`;

export const SpaceBetween = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
`;
