import {Actions, FormDialog} from "@Components/FormDialog.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {useSearchAwarePath} from "@Util/Hooks/Navigate.tsx";
import {useCertificateAPI} from "@API/CertificateAPI.ts";
import {ActionButton} from "@Components/ActionButton.tsx";
import {CertificateForm, useCertificateFormState} from "./CertificateForm.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const CertificateAddDialog = () => {
  const api = useCertificateAPI();
  const state = useCertificateFormState();

  const handleSubmit = async () => {
    await api.add({
      institution: state.institution.value as string,
      courseName: state.courseName.value as string,
      graduationYear: state.graduationYear.getNumericValue() as number,
      photo: state.photo.value as string,
    });
  }

  const back = useSearchAwarePath('../..');
  const title = <Translated value={TRANSLATIONS.PROFILE.ADD_CERTIFICATE_TITLE}/>;

  return (
    <FormDialog title={title} onSubmit={handleSubmit} back={back}>
      <CertificateForm state={state}/>
      <Actions>
        <ActionButton requireValid={Object.values(state)}/>
      </Actions>
    </FormDialog>
  );
}
