import {createContext, useContext} from "react";
import {FCProps} from "@Util/FCProps.ts";
import {Translation, useTransDB} from "@Components/Localization/TransDB.tsx";
import {useCurrentLanguage} from "@Components/I18nWrapper.tsx";

export interface TransSectionProps extends FCProps {
  section: string;
}

const SectionContext = createContext<Translation[]>([]);

export const useTransSection = () => useContext(SectionContext);

export const useRootTransSection = (): Translation[]  => {
  const language = useCurrentLanguage();
  const db = useTransDB();

  return db[language.code]['root'] ?? [];
}

export const TransSection = ({section, children}: TransSectionProps) => {
  const language = useCurrentLanguage();
  const db = useTransDB();
  const translations = db[language.code][section] ?? [];

  return (
    <SectionContext.Provider value={translations}>
      {children}
    </SectionContext.Provider>
  );
}
