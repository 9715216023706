import {Roles, useCurrentUser} from "@Util/Hooks/Auth.tsx";
import {UnreadMessagesIndicator} from "@Components/Messages/UnreadMessagesIndicator.tsx";
import {PageLink} from "../partial/TopBar.tsx";
import {
  GroupsOutlined,
  PersonSearchOutlined,
  PsychologyOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import {TRANSLATIONS} from "@assets/translation.ts";

export const useTopBarNavigation = () => {
  const user = useCurrentUser();

  const items: PageLink[] = [];

  if (user.role?.id === Roles.ADMIN) {
    items.push({title: 'Admin panel', to: '/admin', icon: <SettingsOutlined/>});
  }

  items.push({title: 'Our mission', to: '/about', icon: <GroupsOutlined/>});
  items.push({title: TRANSLATIONS.BTN.FIND_THERAPIST, to: '/therapists', icon: <PersonSearchOutlined/>});

  if (!user.id) {
    items.push({title: TRANSLATIONS.BTN.BECOME_MEMBER, to: '/for-therapists', icon: <PsychologyOutlined/>});
  }

  if (user.id) {
    items.push({
      title: 'Messages',
      to: '/messages',
      icon: <UnreadMessagesIndicator/>,
      forceIcon: true,
    });
  }

  return items;
}
