import React, {FC} from "react";
import {PersonPinCircle} from "@mui/icons-material";
import {Translated} from "@Components/Localization/Translated.tsx";
import {useLocalisedCities} from "@Components/Providers/LocalisedCityProvider.tsx";
import {useTherapist} from "../../Account/Therapist/TherapistProvider.tsx";
import {HighlightItem} from "./HighlightItem.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const LocationsView: FC = React.memo(() => {
  const t = useTherapist();
  const cities = useLocalisedCities()
    .filter(city => t.locations?.some(l => l.city.id === city.id))
    .map(city => city.localisedName ?? city.name);

  return (
    <HighlightItem label={TRANSLATIONS.PROFILE.IN_PERSON} IconComponent={PersonPinCircle}>
      {cities.length > 0 ? cities.join(', ') : (
        <Translated value="No"/>
      )}
    </HighlightItem>
  );
});
