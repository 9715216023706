import {CheckboxesField} from "@Components/CheckboxesField.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {LocalisedWorksWith, useLocalisedWorksWith} from "@Components/Providers/WorksWithProvider.tsx";
import {LocalisedTopic, useLocalisedTopics} from "@Components/Providers/TopicsProvider.tsx";
import {useMultiSelectField} from "@Components/useMultiselect.ts";
import {useState} from "react";
import {SearchParams} from "@API/TherapistAPI.ts";
import {Language, useI18n} from "@Components/I18nWrapper.tsx";
import {useSearchParam} from "@Util/Hooks/UseSearchParam.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";
import {buildSearchQuery} from "./buildSearchQuery.tsx";
import {Add, Remove} from "@mui/icons-material";
import {styled} from "@mui/system";
import {Box, Button, FormGroup, FormLabel, IconButton} from "@mui/material";
import {theme} from "@Util/theme.tsx";
import {TextField} from "@Components/TextField.tsx";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {useLocation} from "react-router-dom";

export const splitArray = (v: string) => v.split(',');
export const parseNumbers = (v: string) => splitArray(v).map(v => parseInt(v));


export interface TherapistsFilterProps {
  onChange: (query: Partial<Location>) => any;
}

export const TherapistsFilter = ({onChange}: TherapistsFilterProps) => {
  const location = useLocation();
  const [expanded, setExpanded] = useState<SearchParams[]>([]);
  const ww = useLocalisedWorksWith();
  const topics = useLocalisedTopics();
  const languages = useI18n();
  const wwParam = useSearchParam(SearchParams.WORKS_WITH, {
    default: [],
    transform: parseNumbers,
  });

  const nameField = useFormField<string>({
    initialValue: useSearchParam(SearchParams.NAME, {
      default: '',
      transform: value => value,
    }),
  });

  const worksWithField = useMultiSelectField<LocalisedWorksWith, number>({
    initialValue: [],
    value: wwParam,
    options: ww.filter(item => expanded.includes(SearchParams.WORKS_WITH) ? true : wwParam.includes(item.id)),
    getOptionValue: (item) => item.id,
    getOptionLabel: (item) => item.translation.title ?? item.title,
  });

  const topicParam = useSearchParam(SearchParams.TOPIC, {
    default: [],
    transform: parseNumbers,
  });

  const topicsField = useMultiSelectField<LocalisedTopic, number>({
    initialValue: [],
    value: topicParam,
    options: topics.filter((item) => expanded.includes(SearchParams.TOPIC) ? true : topicParam.includes(item.id)),
    getOptionValue: (item) => item.id,
    getOptionLabel: (item) => item.translation.title ?? item.title,
  });

  const langsParam = useSearchParam(SearchParams.LANGUAGE, {
    default: [],
    transform: splitArray,
  });

  const languagesField = useMultiSelectField<Language, string>({
    initialValue: [],
    value: langsParam,
    options: languages.filter((item) => expanded.includes(SearchParams.LANGUAGE) ? true : langsParam.includes(item.code)),
    getOptionValue: l => l.code,
    getOptionLabel: l => l.name,
  });

  const search = buildSearchQuery({
    name: nameField.value,
    topics: topicsField.value,
    worksWith: worksWithField.value,
    languages: languagesField.value,
  });

  const toggleExpanded = (name: SearchParams) => {
    setExpanded((current) => {
      if (current.includes(name)) {
        return current.filter(param => param !== name);
      }

      return [...current, name];
    });
  }

  const showResults = () => {
    setExpanded([]);
    onChange({search});
  }

  const showName = (expanded.includes(SearchParams.NAME) || !!nameField.value);

  const toggleName = () => {
    toggleExpanded(SearchParams.NAME);
    if (showName) {
      nameField.onChange('');
    }
  }

  return (
    <>
      <FilterWrapper>
        <IconButton onClick={toggleName}>
          {showName ? <Remove/> : <Add/>}
        </IconButton>
        <FormGroup>
          <FormLabel><Translated value={TRANSLATIONS.PROFILE.SEARCH}/></FormLabel>
          {showName && (
            <TextField field={nameField}/>
          )}
        </FormGroup>
      </FilterWrapper>
      <FilterWrapper>
        <IconButton onClick={() => toggleExpanded(SearchParams.TOPIC)}>
          {expanded.includes(SearchParams.TOPIC) ? <Remove/> : <Add/>}
        </IconButton>
        <CheckboxesField
          field={topicsField}
          label={(
            <Translated value={TRANSLATIONS.PROFILE.TOPICS}/>
          )}
        />
      </FilterWrapper>

      <FilterWrapper>
        <IconButton onClick={() => toggleExpanded(SearchParams.WORKS_WITH)}>
          {expanded.includes(SearchParams.WORKS_WITH) ? <Remove/> : <Add/>}
        </IconButton>
        <CheckboxesField
          field={worksWithField}
          label={(
            <Translated value={TRANSLATIONS.PROFILE.WORKS_WITH}/>
          )}
        />
      </FilterWrapper>
      <FilterWrapper>
        <IconButton onClick={() => toggleExpanded(SearchParams.LANGUAGE)}>
          {expanded.includes(SearchParams.LANGUAGE) ? <Remove/> : <Add/>}
        </IconButton>
        <CheckboxesField
          field={languagesField}
          label={(
            <Translated value={TRANSLATIONS.PROFILE.LANGUAGES}/>
          )}
        />
      </FilterWrapper>

      {location.search !== search && (
        <Box mt={2}>
          <Button fullWidth onClick={showResults}>
            <Translated value={TRANSLATIONS.PROFILE.BTN_SHOW_RESULTS}/>
          </Button>
        </Box>
      )}
    </>
  );
}

const FilterWrapper = styled('div')`
  position: relative;

  .MuiFormLabel-root {
    font-weight: 700;
    padding: ${theme.spacing(1)} 0;
  }
  
  .MuiFormGroup-root {
    margin: 0;
  }

  > button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }
`
