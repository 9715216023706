import {Box, Grid} from "@mui/material";
import {Panel} from "@Components/Layout/Panel.tsx";
import {ProfilePicture} from "./ProfilePicture.tsx";
import {NameSection} from "./Common/NameSection.tsx";
import {MembershipSection} from "./Therapist/Membership/MembershipSection.tsx";
import {AgeSection} from "./Common/AgeSection.tsx";
import {SexSection} from "./Common/SexSection.tsx";
import {PriceSection} from "./Therapist/Price/PriceSection.tsx";
import {WorkOnlineSection} from "./Therapist/WorkOnline/WorkOnlineSection.tsx";
import {LocationsSection} from "./Therapist/Locations/LocationsSection.tsx";
import {ReviewStatusSection} from "./Therapist/ReviewStatus/ReviewStatusSection.tsx";
import {TherapistProfile} from "./Therapist/TherapistProfile.tsx";
import {ClientProfile} from "./Client/ClientProfile.tsx";
import {useCurrentUser} from "@Util/Hooks/Auth.tsx";
import {Targets} from "@API/TargetAPI.ts";
import {YearsOfExperienceSection} from "./Therapist/YearsOfExperience/YearsOfExperienceSection.tsx";

export interface AccountPageContentProps {
  noEdit?: boolean;
}

export const AccountPageContent = () => {
  const user = useCurrentUser();
  const pendingOrCurrentType = user.membership?.pendingType ?? user.membership?.type;
  const isTherapist = pendingOrCurrentType?.target === Targets.THERAPIST;

  return (
    <Grid container spacing={2}>
      <Grid item lg={4} xs={12}>
        <Panel>
          <ProfilePicture/>
          <Box p={2} display="flex" flexDirection="column" gap={2}>
            <Box mb={2}>
              <NameSection/>
              <MembershipSection/>
            </Box>
            <AgeSection/>
            <SexSection/>
            {isTherapist && (
              <>
                <YearsOfExperienceSection/>
                <PriceSection/>
                <WorkOnlineSection/>
                <LocationsSection/>
                <ReviewStatusSection/>
              </>
            )}
          </Box>
        </Panel>
      </Grid>
      <Grid item lg={8} xs={12}>
        {isTherapist ? (
          <TherapistProfile/>
        ) : (
          <ClientProfile/>
        )}
      </Grid>
    </Grid>
  )
}
