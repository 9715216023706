import {useHref, useLocation} from "react-router-dom";
import {usePath} from "@Util/Hooks/Navigate.tsx";

export const useLocationMatch = (toMatch: string) => {
  const location = useLocation();
  const path = usePath(toMatch);
  const href = useHref(path);

  return [href, toMatch].includes(location.pathname);
}
