import { API } from '@API/API.ts';
import { Language } from '@Components/I18nWrapper.tsx';
import { DB } from '@Components/Localization/TransDB.tsx';
import { useAxios } from '@Util/Hooks/Http.tsx';

export const useTranslationAPI = () => {
  const http = useAxios();

  return new TranslationAPI(http);
}

export interface TranslationSection {
  id: number;
  key: string;
  parent?: TranslationSection;
  children?: TranslationSection[];
}

export interface Translatable {
  id: number;
  key: string;
  translations: Translation[];
}

export interface Translation {
  id: number;
  translatable?: Translatable
  language: Language;
  value: string;
}

export interface TranslationCreate {
  section: number;
  key: string;
  value: string;
  locale: string;
}

export interface TranslationUpdate {
  translatable: number;
  locale: string;
  value: string;
}

export class TranslationAPI extends API {
  async list(section: TranslationSection): Promise<Translatable[]> {
    return this.http
      .get('/api/translation', {
        params: {
          section: section.id,
        },
      })
      .then(response => response.data);
  }

  async create(data: TranslationCreate) {
    return this.http.post('/api/translation', data).then(r => r.data);
  }

  async database(): Promise<DB> {
      return this.http.get('/api/translation/db').then(r => r.data)
  }

  async update(data: TranslationUpdate) {
    return this.http
      .put('/api/translation', data)
      .then(response => response.data);
  }

  async listSections(): Promise<TranslationSection[]> {
    return this.http
      .get('/api/translation-section')
      .then(r => r.data);
  }
}

