import {useLoading} from "@Util/Hooks/Loading.tsx";
import {CircularProgress} from "@mui/material";
import {MembersTable} from "./MembersTable.tsx";
import {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import {useResponseActionInterceptor} from "@Util/Hooks/Http.tsx";
import {useSearchParam} from "@Util/Hooks/UseSearchParam.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {Member, useMembersAPI} from "@API/MembersAPI.ts";
import {MembersFilter, SearchParameters} from "./MembersFilter.tsx";
import {Targets} from "@API/TargetAPI.ts";

export const MembersListPage = () => {
  const api = useMembersAPI();
  const loading = useLoading();
  const [searchResults, setSearchResults] = useState<Member[]>();

  const queryParameter = useSearchParam(SearchParameters.QUERY, {
    default: undefined,
    transform: (v) => v,
  });

  const targetParameter = useSearchParam(SearchParameters.TARGET, {
    default: undefined,
    transform: (v) => v as Targets,
  });

  useEffect(() => {
    api
      .search({
        query: queryParameter,
        target: targetParameter
      })
      .then(setSearchResults);
  }, [queryParameter, targetParameter]);

  useResponseActionInterceptor(/^therapist/, async () => {
    await api
      .search({
        query: queryParameter,
        target: targetParameter,
      })
      .then(setSearchResults);
  }, []);

  return (
    <>
      <h1><Translated value="Members"/></h1>

      <MembersFilter/>

      {loading
        ? (
          <CircularProgress/>
        )
        : (
          <MembersTable rows={searchResults ?? []}/>
        )
      }

      <Outlet/>
    </>
  )
}
