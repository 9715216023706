import {RouteObject} from "react-router-dom";

export const CalendarRoute: RouteObject = {
  path: 'calendar',
  Component: () => (
    <>
      <h1>Calendar</h1>
    </>
  )
}
