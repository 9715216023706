import {ChangeEvent, useRef, useState} from "react";
import {Box, Fab, Fade} from "@mui/material";
import {styled} from "@mui/system";
import {useAxios} from "@Util/Hooks/Http.tsx";
import {useCurrentUser} from "@Util/Hooks/Auth.tsx";
import {AddAPhoto} from "@mui/icons-material";
import {theme} from "@Util/theme.tsx";
import {useEditableContext} from "./EditableProvider.tsx";

export const ProfilePicture = () => {
  const user = useCurrentUser();
  const avatar = user.avatar;
  const http = useAxios();
  const uploadRef = useRef<HTMLInputElement>(null);
  const uploadFormRef = useRef<HTMLFormElement>(null);

  const showModal = () => {
    uploadRef?.current?.click();
  }

  const onFileSelected = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('avatar', file)

      await http
        .putForm('/api/avatar', formData)
        .finally(() => {
          uploadFormRef?.current?.reset();
        });
    }
  };

  return (
    <>
      <AvatarField onClick={showModal} src={avatar?.path}/>
      <Box sx={{display: 'none'}}>
        <form ref={uploadFormRef}>
          <input onChange={onFileSelected} type="file" accept="image/png, image/jpeg" ref={uploadRef}/>
        </form>
      </Box>
    </>
  )
}

interface AvatarFieldProps {
  src?: string;
  onClick: () => void;
}

const AvatarField = ({src, onClick}: AvatarFieldProps) => {
  const canEdit = useEditableContext();
  const [buttonShown, setButtonShown] = useState<boolean>(false);

  return (
    <Box mb={2} position="relative" onMouseEnter={() => setButtonShown(true)} onMouseLeave={() => setButtonShown(false)}>
      <AvatarImg alt="avatar" src={src ?? '/avatar.png'}/>
      {canEdit && (
        <Fade in={buttonShown}>
          <Fab color="primary" onClick={onClick} size="small" sx={{position: 'absolute', bottom: theme.spacing(2), right: theme.spacing(2)}}>
            <AddAPhoto/>
          </Fab>
        </Fade>
      )}
    </Box>
  )
}

const AvatarImg = styled('img')`
  display: block;
  width: 100%;
`
