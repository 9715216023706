import {createContext, useContext} from "react";
import {Language} from "@Components/I18nWrapper.tsx";
import {RegistrationTarget, Targets} from "@API/TargetAPI.ts";
import {SexPK} from "@API/SexAPI.ts";

export enum Roles {
  ADMIN='admin'
}

export interface AuthUserPK {
  id?: number;
}

export interface UserPublicData extends AuthUserPK {
  avatar?: Avatar;
  firstName: string;
  lastName: string;
  sex?: SexPK,
}

export interface Avatar {
  id: number;
  path: string;
}

interface MembershipType {
  id: number;
  target: Targets;
}

interface UserMembership {
  status: string;
  approved: boolean;
  type: MembershipType;
  pendingType?: MembershipType;
}

export interface AuthUser extends UserPublicData {
  userName?: string;
  role?: {
    id: Roles;
  }
  locale?: Language;
  target?: RegistrationTarget;
  membership?: UserMembership,
  dateOfBirth: string|null;
}

const AuthContext = createContext<AuthUser>({
  firstName: 'anonymous',
  lastName: 'anonymous',
  dateOfBirth: null,
});

export const AuthProvider = AuthContext.Provider;

export const useCurrentUser = () => useContext(AuthContext);

