import {LoaderFunction, Outlet, ScrollRestoration, useLoaderData} from "react-router-dom";
import {createUniversalAPI} from "@Util/UniversalAPI.ts";
import {Qualification, QualificationAPI} from "@API/QualificationAPI.ts";
import {QualificationProvider} from "@Components/Providers/QualificationProvider.tsx";
import {City, CityAPI} from "@API/CityAPI.tsx";
import {LocalisedCityProvider} from "@Components/Providers/LocalisedCityProvider.tsx";
import {WorksWith, WorksWithAPI} from "@API/WorksWithAPI.ts";
import {WorksWithProvider} from "@Components/Providers/WorksWithProvider.tsx";
import {Topic, TopicsAPI} from "@API/TopicsAPI.ts";
import {TopicsProvider} from "@Components/Providers/TopicsProvider.tsx";
import {Currency, CurrencyAPI} from "@API/CurrencyAPI.ts";
import {CurrencyProvider} from "@Components/Providers/CurrencyProvider.tsx";

export type LoaderData = [
  Qualification[],
  City[],
  WorksWith[],
  Topic[],
  Currency[],
];

export const dataLoader: LoaderFunction = ({context}) => {
  const qualificationAPI = createUniversalAPI(QualificationAPI, context);
  const cityAPI = createUniversalAPI(CityAPI, context);
  const worksWithAPI = createUniversalAPI(WorksWithAPI, context);
  const topicAPI = createUniversalAPI(TopicsAPI, context);
  const currencyAPI = createUniversalAPI(CurrencyAPI, context);

  return Promise.all([
    qualificationAPI.list(),
    cityAPI.list(),
    worksWithAPI.list(),
    topicAPI.list(),
    currencyAPI.list(),
  ]);
}

export const TherapistsDataProvider = () => {
  const data = useLoaderData() as LoaderData;

  return (
    <QualificationProvider value={data[0]}>
      <LocalisedCityProvider value={data[1]}>
        <WorksWithProvider value={data[2]}>
          <TopicsProvider value={data[3]}>
            <CurrencyProvider value={data[4]}>
              <Outlet/>
              <ScrollRestoration/>
            </CurrencyProvider>
          </TopicsProvider>
        </WorksWithProvider>
      </LocalisedCityProvider>
    </QualificationProvider>
  );
}
