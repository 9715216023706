import {createContext, useContext} from "react";
import {FCProps} from "@Util/FCProps.ts";

export interface Translation {
  key: string;
  value: string;
}

export interface LocalizationSections {
  [key: string]: Translation[];
}

export interface DB {
  [x: string]: LocalizationSections;
}

export const TransDBContext = createContext<DB>({});

export const useTransDB = () => useContext(TransDBContext);

export interface TransDBProviderProps extends FCProps {
  db: DB;
}

export const TransDBProvider = ({children, db}: TransDBProviderProps) => {
  return (
    <TransDBContext.Provider value={db}>
      {children}
    </TransDBContext.Provider>
  );
}
