import { RouteObject } from 'react-router-dom';
import { TopicAddDialog } from './TopicAddDialog.tsx';
import { TopicDeleteDialog } from './TopicDeleteDialog.tsx';
import { TopicEditDialog } from './TopicEditDialog.tsx';
import { TopicListPage } from './TopicListPage.tsx';

export const TopicAdminRoute: RouteObject = {
  path: 'topic',
  Component: TopicListPage,
  children: [
    {
      path: 'add',
      Component: TopicAddDialog,
    },
    {
      path: ':id',
      children: [
        {
          path: 'update',
          Component: TopicEditDialog,
        },
        {
          path: 'delete',
          Component: TopicDeleteDialog,
        },
      ],
    },
  ],
};
