import {ActionLink, EditableProps, WithLabel} from "./Editable.tsx";
import {Box, Tooltip} from "@mui/material";
import {Translated} from "@Components/Localization/Translated.tsx";
import {AddCircle} from "@mui/icons-material";
import {ReactNode} from "react";
import {To} from "react-router-dom";
import {useEditableContext} from "./EditableProvider.tsx";

export interface EditableCollectionProps extends Omit<EditableProps, 'editPath'> {
  addPath: To;
  indicator?: ReactNode;
  showIndicator?: boolean;
  shift?: number;
}

export const EditableCollection = ({addPath, label, children, indicator, showIndicator, shift}: EditableCollectionProps) => {
  const canEdit = useEditableContext();

  return (
    <>
      <WithLabel label={label}>
        {canEdit && (
          <Tooltip arrow title={<Translated value="tooltip_add"/>} placement="right">
            <ActionLink sx={{fontWeight: 'bold'}} to={addPath}>
              <AddCircle/>
            </ActionLink>
          </Tooltip>
        )}
      </WithLabel>
      {showIndicator && (
        <Box ml={shift}>{indicator}</Box>
      )}
      {children && (
        <Box ml={shift}>
          {children}
        </Box>
      )}
    </>
  )
}
