import {createContext, useContext, useState} from "react";
import {FCProps} from "@Util/FCProps.ts";
import {useResponseActionInterceptor} from "@Util/Hooks/Http.tsx";
import {DB, TransDBProvider} from "@Components/Localization/TransDB.tsx";
import {TransSection} from "@Components/Localization/TransSection.tsx";
import {useCurrentUser} from "@Util/Hooks/Auth.tsx";
import {useLanguageAPI} from "@API/LanguageAPI.ts";
import {useParams} from "react-router-dom";

export const DefaultLanguageCode = 'ua';

export interface Language {
  code: string;
  name: string;
  isSiteLocale: boolean;
  isEnabled: boolean;
}

const I18nContext = createContext<Language[]>([]);

export const useI18n = () => useContext(I18nContext);

export const useLanguage = (code?: string): Language => {
  const all = useI18n();
  const found = all.find(l => l.code === code);

  return found ?? all.find(l => l.code === DefaultLanguageCode) as Language;
}

export const useCurrentLanguage = () => {
  const params = useParams<{locale: string}>();
  const user = useCurrentUser();

  return useLanguage(params?.locale ?? user.locale?.code);
}

export interface I18nWrapperProps extends FCProps {
  languages: Language[];
  db: DB;
}

export const I18nWrapper = ({children, languages, db}: I18nWrapperProps) => {
  const [updatedLanguages, setUpdatedLanguages] = useState<Language[]>();
  const api = useLanguageAPI();

  useResponseActionInterceptor(/^language$/, async () => {
    api.list().then(setUpdatedLanguages);
  }, [setUpdatedLanguages]);

  return (
    <I18nContext.Provider value={updatedLanguages ?? languages}>
      <TransDBProvider db={db}>
        <TransSection section="root">
          {children}
        </TransSection>
      </TransDBProvider>
    </I18nContext.Provider>
  );
}
