import {Translated} from "@Components/Localization/Translated.tsx";

export const ResetPasswordSuccessPage = () => {

  return (
    <>
      <h1>
        <Translated value="Email sent"/>
      </h1>

      <p>
        <Translated value="Reset password request is successful."/>
      </p>
      <p>
        <Translated value="If account exists you will recieve instructions to set new password."/>
      </p>
    </>
  );
};
