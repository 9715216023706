import {useSearchParams} from "react-router-dom";
import {Language, useI18n} from "@Components/I18nWrapper.tsx";
import {useEffect} from "react";
import {Tab, Tabs} from "@mui/material";
import {cloneSearchParams} from "@Util/CloneSearchParams.ts";

export interface LanguageSelectProps {
  onSelected?: (language: Language) => any;
}

export const LanguageSelect = ({onSelected}: LanguageSelectProps) => {
  const [params, setSearchParams] = useSearchParams();
  const languages = useI18n();

  useEffect(() => {
    if (!params.has('language')) {
      if (languages.length > 0) {
        changeLanguage(languages[0].code, true)
      }
    } else {
      const code = params.get('language');
      const language = languages.find((l) => l.code == code);

      if (language && onSelected) {
        onSelected(language);
      }
    }

  }, [params, languages]);

  const changeLanguage = (code: string, replace: boolean = false) => {
    const clone = cloneSearchParams(params, {
      language: code,
    });

    setSearchParams(clone, {replace});

    const language = languages.find(l => l.code == code);

    if (language && onSelected) {
      onSelected(language);
    }
  }

  const selected = params.get('language') ?? false;

  return (
    <>
      <Tabs value={selected}>
        {languages.map(({code}) => (
          <Tab key={code} value={code} label={code} onClick={() => changeLanguage(code)}/>
        ))}
      </Tabs>
    </>
  );
}
