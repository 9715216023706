import {useWebSocket} from "@Components/Providers/WebsocketProvider.tsx";
import {useEffect, useState} from "react";
import {ChatContact, ContactAPI} from "@API/ContactAPI.ts";
import {useAPI} from "@API/API.ts";

export const useContactsList = () => {
  const api = useAPI(ContactAPI);
  const ws = useWebSocket();
  const [contacts, setContacts] = useState<ChatContact[]>([]);

  useEffect(() => {
    api.list().then(setContacts);
  }, []);

  useEffect(() => {
    const onContactUpdated = (contact: ChatContact) => {
      const updatedList = contacts
        .map(c => {
          if (c.id === contact.id) {
            return contact;
          }

          return c;
        })
        .sort((a, b) => {
          const aDate = new Date(a.lastActivityAt);
          const bDate = new Date(b.lastActivityAt);

          return bDate.getTime() - aDate.getTime();
        });

      setContacts(updatedList)
    }

    ws.on('contact.updated', onContactUpdated);

    return () => {
      ws.off('contact.updated', onContactUpdated);
    }
  }, [contacts]);

  useEffect(() => {
    const onContactCreated = (contact: ChatContact) => {
      setContacts([contact, ...contacts]);
    }

    ws.on('contact.created', onContactCreated);

    return () => {
      ws.off('contact.created', onContactCreated);
    }
  }, []);

  return contacts as ChatContact[];
}
