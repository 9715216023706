import {useCallback, useMemo, useState} from "react";
import {Box, Collapse} from "@mui/material";
import {School} from "@mui/icons-material";
import {Education} from "@API/EducationAPI.ts";
import {NotFilled} from "@Components/NotFilled.tsx";
import {useLocalisedQualifications} from "@Components/Providers/QualificationProvider.tsx";
import {useTherapist} from "../TherapistProvider.tsx";
import {EditableCollection} from "../../EditableCollection.tsx";
import {EditableInline} from "../../Editable.tsx";
import {PrimarySection} from "../PrimarySection.tsx";
import {styled} from "@mui/system";
import {NORMAL_RADIUS, theme} from "@Util/theme.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const EducationSection = () => {
  const therapist = useTherapist();
  const sorted = therapist.education?.sort((a, b) => b.graduationYear - a.graduationYear) ?? [];

  return (
    <PrimarySection noWrap IconComponent={School}>
      <EditableCollection
        label={TRANSLATIONS.PROFILE.EDUCATION}
        showIndicator={!therapist.education?.length}
        indicator={(
          <NotFilled/>
        )}
        addPath="education/add"
      >
        {sorted.map(item => (
          <EducationItem key={item.id} education={item}/>
        ))}
      </EditableCollection>
    </PrimarySection>
  );
}

export interface EducationItemProps {
  education: Education;
}

export const EducationItem = ({education}: EducationItemProps) => {
  const qualifications = useLocalisedQualifications();

  const qualification = useMemo(() => {
    const qualification = qualifications.find(q => q.id === education.qualification.id);

    return qualification?.localised.title ?? qualification?.title;
  }, [qualifications, education.qualification.id])

  const [imgVisible, setImgVisible] = useState(false);

  const toggleImage = useCallback(() => {
    setImgVisible(current => !current);
  }, []);

  return (
    <Box mt={2} mb={2}>
      <EditableInline editPath={`education/${education.id}/update`}>
        <EducationLine onClick={toggleImage}>
          {education.institution}, {qualification}, {education.speciality}, {education.graduationYear}
        </EducationLine>
      </EditableInline>
      <Collapse in={imgVisible}>
        <StyledImg src={education.photo}/>
      </Collapse>
    </Box>
  );
}

export const EducationLine = styled('span')`
  cursor: pointer;
`

export const StyledImg = styled('img')`
  width: 100%;
  margin: ${theme.spacing(2)} 0;
  border-radius: ${NORMAL_RADIUS}px;
`
