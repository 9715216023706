import {useEffect, useState} from "react";
import {toDataUrl} from "@Util/File.ts";
import {FormField, FormFieldOptions, useFormField} from "@Util/Hooks/FormField.ts";

export interface DataUrlFieldState extends FormField<string> {
  file?: File;
  setFile: (file: File) => any;
}

export interface DataUrlFieldOptions extends FormFieldOptions<string> {
}

export const useDataUrlField = (options: DataUrlFieldOptions): DataUrlFieldState => {
  const field = useFormField<string>(options);
  const [file, setFile] = useState<File>();

  useEffect(() => {
    if (file) {
      toDataUrl(file)
        .then((url) => field.onChange(url))
        .catch(() => field.onChange(''));
    }
  }, [file]);

  return {
    ...field,
    file,
    setFile,
  }
}
