import { API, useAPI } from '@API/API.ts';

interface CertificateData {
  institution: string;
  courseName: string;
  graduationYear: number;
}

export interface Certificate extends CertificateData {
  id: number
  photo: string;
}

export interface CertificateAdd extends CertificateData {
  photo: string;
}

export interface CertificateUpdate extends CertificateData {
  id: number;
  photo?: string;
}

export interface CertificateDelete {
  id: number;
}

const ENDPOINT = '/api/certificate';

class CertificateAPI extends API {
  async add(data: CertificateAdd): Promise<Certificate> {
    const response = await this.http.post(ENDPOINT, data);

    return response.data as Certificate;
  }

  async update(data: CertificateUpdate): Promise<Certificate> {
    const response = await this.http.put(ENDPOINT, data);

    return response.data as Certificate;
  }

  async delete(data: CertificateDelete): Promise<void> {
    await this.http.delete(ENDPOINT, {data});
  }
}

export const useCertificateAPI = () => useAPI(CertificateAPI);
