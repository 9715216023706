import axios, {AxiosInstance, AxiosResponse} from "axios";
import {Context, createContext, useContext, useEffect} from "react";
import {FCProps} from "@Util/FCProps.ts";

const instance = axios.create();

const AxiosContext: Context<AxiosInstance> = createContext<AxiosInstance>(instance);

export const AxiosProvider = ({children}: FCProps) => {
  return (
    <AxiosContext.Provider value={instance}>
      {children}
    </AxiosContext.Provider>
  )
};

export const useAxios = () => useContext(AxiosContext);

export const useResponseActionInterceptor = (action: RegExp, cb: (response: AxiosResponse) => Promise<any>, deps: any[]) => {
  const http = useAxios();

  return useEffect(() => {
    const id = http.interceptors.response.use(async (response) => {
      const responseAction = response.headers['x-action'];
      if (responseAction && action.test(responseAction)) {
        await cb(response)
      }

      return response;
    });

    return () => {
      http.interceptors.response.eject(id);
    }
  }, deps);
}
