import {useLoading} from "@Util/Hooks/Loading.tsx";
import {Box, Button, CircularProgress, IconButton} from "@mui/material";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material"
import {Add, Edit} from "@mui/icons-material";
import {Outlet, useNavigate} from "react-router-dom";
import {useI18n} from "@Components/I18nWrapper.tsx";

export const LanguagesListPage = () => {
  const isLoading = useLoading();
  const navigate = useNavigate();
  const languages  = useI18n();

  return (
    <>
      <h1>Languages list</h1>

      <Button onClick={() => navigate('add')}><Add/> Add</Button>

      <Box>
        {isLoading && !languages.length && <CircularProgress/>}

        {languages.length > 0 && (
          <TableContainer sx={{minWidth: 500}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Code</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Enabled</TableCell>
                  <TableCell align="right">Site locale</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {languages.map((language) => (
                  <TableRow key={language.code}>
                    <TableCell>{language.code}</TableCell>
                    <TableCell>{language.name}</TableCell>
                    <TableCell align="right">{language.isEnabled ? 'yes' : 'no'}</TableCell>
                    <TableCell align="right">{language.isSiteLocale ? 'yes' : 'no'}</TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => navigate(`${language.code}/edit`)}>
                        <Edit/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

          </TableContainer>
        )}
      </Box>

      <Outlet/>
    </>
  )
}
