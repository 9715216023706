import {RouteObject} from "react-router-dom";
import {LocationAddDialog} from "./LocationAddDialog.tsx";
import {LocationUpdateDialog} from "./LocationUpdateDialog.tsx";

export const LocationsRoute: RouteObject = {
  path: 'locations',
  children: [
    {
      path: 'add',
      Component: LocationAddDialog,
    },
    {
      path: ':id',
      children: [
        {
          path: 'update',
          Component: LocationUpdateDialog,
        }
      ]
    }
  ],
}
