import {useCurrentLanguage} from "@Components/I18nWrapper.tsx";
import {useMembershipList} from "@Components/Membership/MembershipProvider.tsx";
import {Currency} from "@API/CurrencyAPI.ts";

export interface MembershipOption {
  id: number;
  title: string;
  price: number;
  currency: Currency;
}

export const useMembershipOptions = (): MembershipOption[] => {
  const language = useCurrentLanguage();
  const list = useMembershipList();

  return list.map(item => {
    const localized = item.i18n.find(i => i.locale.code === language.code);

    return {
      id: item.id,
      title: localized?.title ?? item.title,
      price: item.price,
      currency: item.currency,
    };
  });
}
