import {FC, ReactNode} from "react";
import {useCurrentUser} from "@Util/Hooks/Auth.tsx";
import {usePath} from "@Util/Hooks/Navigate.tsx";
import {Navigate, useLocation} from "react-router-dom";
import {RETURN_PATH_PARAM_NAME} from "../Pages/Auth/AuthPage.tsx";

export interface AuthenticationGuardProps {
  children: ReactNode;
  Fallback?: FallbackComponent;
}

export type FallbackComponent = FC;

export const AuthenticationGuard: FC<AuthenticationGuardProps> = ({Fallback, children}) => {
  const user = useCurrentUser();
  const loginPath = useLoginPath();

  if (!user.id) {
    if (Fallback) {
      return <Fallback/>;
    }

    return <Navigate replace to={loginPath} />
  }

  return <>{children}</>
}

export const useLoginPath = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams({
    [RETURN_PATH_PARAM_NAME]: location.pathname
  });

  return usePath({
    pathname: '/auth/log-in',
    search: queryParams.toString(),
  });
}
