import {FormDialog} from "@Components/FormDialog.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {To, useParams, useSearchParams} from "react-router-dom";
import {useLocalisedCountry} from "@Components/Providers/CountriesProvider.tsx";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {Required} from "@Util/Validation/Required.ts";
import {useCountryAPI} from "@API/CountryAPI.ts";
import {TextField} from "@Components/TextField.tsx";

const useCountry = () => {
  const params = useParams<{id: string}>();
  const id = parseInt(params.id ?? '');

  return useLocalisedCountry(id);
}

export const CountryUpdateDialog = () => {
  const api = useCountryAPI();
  const country = useCountry();
  const [search] = useSearchParams();

  const name = useFormField({
    initialValue: country?.localisedName ?? '',
    validators: [
      new Required(),
    ],
  });

  const handleSubmit = async () => {
    if (!country) {
      throw new Error('Invalid country');
    }

    await api.update(country.id, name.value as string, country.locale);
  };

  const back: To = {
    pathname: '../..',
    search: search.toString(),
  };

  const title = <Translated value="Update country"/>;

  return (
    <FormDialog title={title} onSubmit={handleSubmit} back={back}>
      <TextField
        field={name}
        label={(
          <Translated value="Name"/>
        )}
      />

      <ActionButton requireValid={[name]} />
    </FormDialog>
  );
}
