import {useTherapist} from "../../Account/Therapist/TherapistProvider.tsx";
import {useEffect} from "react";
import {ChatContact} from "@API/ContactAPI.ts";
import {useNavigate} from "react-router-dom";
import {Translated} from "@Components/Localization/Translated.tsx";
import {useWebSocket} from "@Components/Providers/WebsocketProvider.tsx";
import {useNotifications} from "@Util/Hooks/Notifications.tsx";
import {ButtonContactWide} from "@Components/Button/ButtonContactWide.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const TherapistContactButton = () => {
  const t = useTherapist();
  const navigate = useNavigate();
  const ws = useWebSocket();
  const notify = useNotifications();

  useEffect(() => {
    const onContactCreated = (data: ChatContact) => {
      if (data.target.id === t.user?.id) {
        notify({
          variant: 'info',
          message: <Translated value="Therapist has been added to your contact list."/>,
        });
      }
    };

    ws.on('contact.created', onContactCreated);

    return () => {
      ws.off('contact.created', onContactCreated);
    };
  }, []);

  const handleClick = () => {
    navigate('contact');
  }

  return (
    <ButtonContactWide onClick={handleClick}>
      <Translated value={TRANSLATIONS.PROFILE.BTN_CONTACT_THERAPIST}/>
    </ButtonContactWide>
  )
}
