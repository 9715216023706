import {FC} from "react";
import {IconButton} from "@mui/material";
import {Visibility} from "@mui/icons-material";
import {ButtonProps} from "./ButtonProps.tsx";

export const PreviewButton: FC<ButtonProps> = ({onClick}) => {
  return (
    <IconButton onClick={onClick}>
      <Visibility/>
    </IconButton>
  );
}
