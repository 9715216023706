import { FieldValidator, scalar } from '../Hooks/FormField.ts';

export class Required implements FieldValidator {
  constructor(private msg: string = 'This field is required') {
  }

  isValid(value: scalar): boolean {
    return !!value;
  }

  get message(): string {
    return this.msg;
  }
}
