import {VolumeUpRounded} from "@mui/icons-material";
import {TRANSLATIONS} from "@assets/translation.ts";
import {Language, useI18n} from "@Components/I18nWrapper.tsx";
import {NotFilled} from "@Components/NotFilled.tsx";
import {useTherapist} from "../TherapistProvider.tsx";
import {Editable} from "../../Editable.tsx";
import {PrimarySection} from "../PrimarySection.tsx";

export const TherapyLanguagesSection = () => {
  const therapist = useTherapist();
  const languages = useI18n();
  const therapyLanguages = languages.filter(l => {
    return !!therapist.languages?.find(tl => tl.code === l.code);
  });

  return (
    <PrimarySection IconComponent={VolumeUpRounded}>
      <Editable editPath="languages" label={TRANSLATIONS.PROFILE.THERAPY_LANGUAGES}>
        <LanguagesList selected={therapyLanguages}/>
      </Editable>
    </PrimarySection>
  );
}

interface LanguagesListProps {
  selected?: Language[]
}

const LanguagesList = ({selected}: LanguagesListProps) => {

  if (!selected || selected.length === 0) {
    return <NotFilled/>
  }

  return selected.map(s => s.name ?? s.code).join(', ');
}
