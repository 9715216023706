import {useFormField} from "@Util/Hooks/FormField.ts";
import {Required} from "@Util/Validation/Required.ts";
import {FormDialog} from "@Components/FormDialog.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {TextField} from "@Components/TextField.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {useCountryAPI} from "@API/CountryAPI.ts";
import {To, useSearchParams} from "react-router-dom";

export const CountryAddDialog = () => {
  const [search] = useSearchParams();
  const api = useCountryAPI();

  const name = useFormField<string>({
    initialValue: '',
    validators: [
      new Required(),
    ]
  });

  const title = <Translated value="Add country"/>;

  const handleSubmit = async () => {
    await api.create(name.value as string);
  }

  const back: To = {
    pathname: '..',
    search: search.toString(),
  }

  return (
    <FormDialog title={title} onSubmit={handleSubmit} back={back}>
      <TextField
        field={name}
        label={(
          <Translated value="Name"/>
        )}
      />

      <ActionButton requireValid={[name]} />
    </FormDialog>
  );
}
