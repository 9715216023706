import {Language, useI18n} from "@Components/I18nWrapper.tsx";
import {useMultiSelectField} from "@Components/useMultiselect.ts";
import {FormDialog} from "@Components/FormDialog.tsx";
import {CheckboxesField} from "@Components/CheckboxesField.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {useTherapistAPI} from "@API/TherapistAPI.ts";
import {useTherapist} from "../TherapistProvider.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const LanguagesDialog = () => {
  const languages = useI18n();
  const therapist = useTherapist();
  const api = useTherapistAPI();

  const field = useMultiSelectField<Language, string>({
    initialValue: [],
    value: therapist?.languages?.map(l => l.code),
    options: languages,
    getOptionValue: lang => lang.code,
    getOptionLabel: lang => lang.name,
  });

  const handleSubmit = async () => {
    await api.updateLanguages(field.value ?? []);
  }

  return (
    <FormDialog title={<Translated value={TRANSLATIONS.PROFILE.THERAPY_LANGUAGES}/>} onSubmit={handleSubmit}>
      <CheckboxesField field={field}/>

      <ActionButton/>
    </FormDialog>
  );
}
