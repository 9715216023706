import {LoaderFunction} from "react-router-dom";
import {createUniversalAPI} from "@Util/UniversalAPI.ts";
import {TherapistAPI} from "@API/TherapistAPI.ts";

export const therapistParamLoader: LoaderFunction = ({context, params}) => {
  const api = createUniversalAPI(TherapistAPI, context);
  const id = parseInt(params['id'] ?? '');

  if (isNaN(id)) {
    throw new Error('Invalid parameter');
  }

  return api.details(id);
}
