import {FCProps} from "@Util/FCProps.ts";
import {Grid} from "@mui/material";
import {Headline} from "@Components/Headline.tsx";
import {styled} from "@mui/system";
import {theme} from "@Util/theme.tsx";

interface BulletSectionProps extends FCProps {
  headline: string;
}

export const BulletSection = ({headline, children}: BulletSectionProps) => {
  return (
    <Grid item xs={12} md={4}>
      <StyleWrapper>
        <Headline text={headline} level={2}/>
        <ListWrapper>
          {children}
        </ListWrapper>
      </StyleWrapper>
    </Grid>
  );
}

const ListWrapper = styled('div')`
  .MuiListItem-root {
    padding: 0 0 ${theme.spacing(1)} 0;
  }
`

const StyleWrapper = styled('div')`
  h2 {
    font-size: 20px;
  }
  
  ul {
    list-style: disc;
    padding: 0;
  }
  
  li {
    font-size: 14px;
    margin: 0 0 4px 16px;
  }
`
