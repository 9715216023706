import { API, useAPI } from '@API/API.ts';
import { SexEnum } from '@API/SexAPI.ts';
import { AuthUser } from '@Util/Hooks/Auth.tsx';

export const useUserAPI = () => useAPI(UserApi);

export interface UserUpdate {
  id: number,
  firstName?: string,
  lastName?: string,
  sex?: SexEnum,
  membership?: number;
}

export interface GetResetPasswordLink {
  email: string;
}

export interface ResetPassword {
  id: number;
  token: string;
  password: string;
}

export interface UserMembershipUpdate {
  id: number;
  membership: number;
}

export class UserApi extends API {

  async current(): Promise<AuthUser> {
    return this.http.get('/api/auth').then(response => response.data)
  }

  async details(id: number) {
    const response = await this.http.get(`/api/user/${id}`);

    return response.data as AuthUser;
  }

  async update(data: UserUpdate): Promise<AuthUser> {
    const response = await this.http.patch('/api/user', data);

    return response.data as AuthUser;
  }

  async getResetPasswordLink(data: GetResetPasswordLink) {
    await this.http.post('/api/auth/password/email', data)
  }

  async resetPassword(data: ResetPassword) {
    const response = await this.http.post('/api/auth/password', data);

    return response.data as AuthUser;
  }

  async changeLocale(code: string): Promise<AuthUser> {
    return await this.http.put('/api/user/locale', {
      locale: code,
    });
  }

  async changeMembership(data: UserMembershipUpdate) {
    const response = await this.http.put('/api/user/membership', data);

    return response.data as AuthUser;
  }
}
