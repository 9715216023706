import {useTherapist} from "./TherapistProvider.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {PanelSpaced} from "@Components/Layout/Panel.tsx";
import {SocialNetworks} from "./SocialNetworks/SocialNetworks.tsx";
import {TopicsSection} from "./Topics/TopicsSection.tsx";
import {AboutMe} from "./AboutMe/AboutMe.tsx";
import {WorkWith} from "./WorkWith/WorkWith.tsx";
import {TherapyLanguagesSection} from "./TherapyLanguages/TherapyLanguagesSection.tsx";
import {EducationSection} from "./Education/EducationSection.tsx";
import {CertificatesSection} from "./Certificates/CertificatesSection.tsx";
import {Box, Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {TherapistStatus} from "@API/TherapistAPI.ts";
import {useEditableContext} from "../EditableProvider.tsx";

export const TherapistProfile = () => {
  const therapist = useTherapist();
  const canEdit = useEditableContext();
  const navigate = useNavigate();

  const isApproved = therapist.status === TherapistStatus.APPROVED;

  return (
    <>
      {therapist?.id > 0 && (
        <>
          <TherapyLanguagesSection/>
          <WorkWith/>
          <TopicsSection/>
          <PanelSpaced gap={2}>
            <EducationSection/>
            <CertificatesSection/>
          </PanelSpaced>
          <SocialNetworks/>
          <AboutMe/>
        </>
      )}

      {!isApproved && canEdit && (
        <Box sx={{mb: 2}} display="flex" justifyContent="space-around">
          <Button variant="contained" onClick={() => navigate('review')}>
            <Translated value="Send to review"/>
          </Button>
        </Box>
      )}
    </>
  );
}
