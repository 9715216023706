import React from "react";
import {Link as RouterLink, LinkProps as RouterLinkProps} from 'react-router-dom';
import {createTheme} from "@mui/material";

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const {href, ...other} = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

export const NORMAL_RADIUS = 20;
export const BIG_RADIUS = 40;

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    secondary: {
      main: '#e7ebf4',
      light: '#f8f8f8',
    },
    primary: {
      main: '#6699ff',
      contrastText: '#ffffff',
      dark: '#6699ff',
    },
    success: {
      main: '#4ACC7E',
    },
    white: {
      main: '#ffffff'
    }
  },
  typography: {
    button: {
      textTransform: "none",
    },
    fontFamily: "Montserrat",
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        rounded: {
          borderRadius: NORMAL_RADIUS,
        },
      },
    },
    MuiIcon: {
      defaultProps: {
        fontSize: "inherit",
      }
    },
    MuiDialog: {
      defaultProps: {
        PaperProps: {
          sx: {
            borderRadius: `${NORMAL_RADIUS}px`,
            margin: 1,
            width: `calc(100% - 16px)`,
            backgroundColor: (theme) => theme.palette.secondary.light,
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({theme}) => theme.unstable_sx({
          borderRadius: `${NORMAL_RADIUS}px`,
        }),
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: `${NORMAL_RADIUS}px`,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: ({theme}) => theme.unstable_sx({
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        }),
      }
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: ({theme}) => theme.unstable_sx({
          mb: 3,
        }),
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({theme}) => theme.unstable_sx({
          padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
        }),
      }
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          paddingLeft: 15,
          paddingRight: 15,
          minWidth: 10,
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginBottom: 20,
        }
      }
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: NORMAL_RADIUS,
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: BIG_RADIUS,
          fontWeight: "bold",
          boxShadow: "none",
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: ({theme}) => theme.unstable_sx({
          mb: 3,
        }),
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: "absolute",
          bottom: -20,
        }
      }
    },
  },
});

