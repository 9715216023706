import {PsychologyAltOutlined} from "@mui/icons-material";
import {useLocalisedTopics} from "@Components/Providers/TopicsProvider.tsx";
import {useTherapist} from "../../Account/Therapist/TherapistProvider.tsx";
import {HighlightItem} from "./HighlightItem.tsx";
import {ListItem} from "@mui/material";
import {styled} from "@mui/system";
import {theme} from "@Util/theme.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const TopicsView = () => {
  const t = useTherapist();
  const ids = t.topics?.map(topic => topic.id) ?? [];
  const topics = useLocalisedTopics()
    .filter(item => {
      return ids.includes(item.id);
    })
    .map(topic => topic.translation.title ?? topic.title)
    .join(', ');

  if (topics.length === 0) {
    return '';
  }

  return (
    <HighlightItem label={TRANSLATIONS.PROFILE.TOPICS} IconComponent={PsychologyAltOutlined}>
      {topics}
    </HighlightItem>
  );
}

export const TopicsListView = () => {
  const t = useTherapist();
  const ids = t.topics?.map(topic => topic.id) ?? [];
  const topics = useLocalisedTopics()
    .filter(item => {
      return ids.includes(item.id);
    });

  return (
    <HighlightItem label={TRANSLATIONS.PROFILE.TOPICS_AND_PROBLEMS} IconComponent={PsychologyAltOutlined}>
      <TwoColumnsList>
        {topics.map(topic => (
          <ListItem key={topic.id} sx={{padding: 0, marginBottom: 1}}>
            {topic.translation.title ?? topic.title}
          </ListItem>
        ))}
      </TwoColumnsList>
    </HighlightItem>
  );
}

const TwoColumnsList = styled('ul')`
  columns: 2;
  padding: 0;
  margin: ${theme.spacing(1)} 0;
  column-gap: ${theme.spacing(4)};
  
  ${theme.breakpoints.down('sm')} {
    columns: 1;
  }
`


