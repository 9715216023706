import {FormDialog} from "@Components/FormDialog.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {To, useSearchParams} from "react-router-dom";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {Required} from "@Util/Validation/Required.ts";
import {CheckboxField} from "@Components/CheckboxField.tsx";
import {useCity} from "./Hooks/useCity.tsx";
import {useCityAPI} from "@API/CityAPI.tsx";

export const CityDeleteDialog = () => {
  const api = useCityAPI();
  const [search] = useSearchParams();
  const city = useCity();

  const confirmation = useFormField<boolean>({
    initialValue: false,
    validators: [
      new Required(),
    ],
  });

  const back: To = {
    pathname: '../..',
    search: search.toString(),
  };

  const handleSubmit = async () => {
    if (!city) {
      throw new Error('Invalid city');
    }

    await api.delete(city.id);
  };

  const title = <Translated value="Delete city"/>;

  return (
    <FormDialog title={title} onSubmit={handleSubmit} back={back}>
      <CheckboxField
        field={confirmation}
        label={(
          <Translated value="I confirm"/>
        )}/>
      <ActionButton requireValid={[confirmation]} >
        <Translated value="Delete"/>
      </ActionButton>
    </FormDialog>
  );
}
