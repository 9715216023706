import { API } from '@API/API.ts';
import { Language } from '@Components/I18nWrapper.tsx';

export enum SexEnum {
  Male = 'male',
  Female = 'female',
}

export interface SexI18n {
  locale: Language;
  title: string;
}

export interface SexPK {
  id: SexEnum;
}

export interface Sex extends SexPK {
  i18n: SexI18n[];
}


export class SexAPI extends API {
  async list() {
    const response = await this.http.get('/api/sex');

    return response.data as Sex[];
  }
}
