import {Translated} from "@Components/Localization/Translated.tsx";
import {QualificationProvider} from "@Components/Providers/QualificationProvider.tsx";
import {QualificationTable} from "./QualificationTable.tsx";
import {LanguageSelect} from "@Components/LanguageSelect.tsx";
import {useState} from "react";
import {Language} from "@Components/I18nWrapper.tsx";
import {AddButton} from "@Components/Button/AddButton.tsx";
import {useSearchAwareNavigate} from "@Util/Hooks/Navigate.tsx";
import {Outlet} from "react-router-dom";
import {useLoading} from "@Util/Hooks/Loading.tsx";
import {CircularProgress} from "@mui/material";

export const QualificationPage = () => {
  const [language, setLanguage] = useState<Language>();
  const isLoading = useLoading();
  const navigate = useSearchAwareNavigate();

  const showAddDialog = () => navigate('add');

  return (
    <>
      <h1>
        <Translated value="Qualification"/>
      </h1>

      <AddButton onClick={showAddDialog}/>

      <LanguageSelect onSelected={setLanguage}/>

      <QualificationProvider language={language}>
        {isLoading ? <CircularProgress/> :<QualificationTable/>}
        <Outlet/>
      </QualificationProvider>
    </>
  )
}
