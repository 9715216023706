import { RouteObject } from 'react-router-dom';
import { EducationAddDialog } from './EducationAddDialog.tsx';
import { EducationDeleteDialog } from './EducationDeleteDialog.tsx';
import { EducationEditDialog } from './EducationEditDialog.tsx';

export const EducationRoute: RouteObject = {
  path: 'education',
  children: [
    {
      path: 'add',
      Component: EducationAddDialog,
    },
    {
      path: ':id',
      children: [
        {
          path: 'update',
          Component: EducationEditDialog,
        },
        {
          path: 'delete',
          Component: EducationDeleteDialog,
        }
      ],
    },
  ],
};
