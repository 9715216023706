import {useLocalisedTranslations} from "./TranslationsProvider.tsx";
import {To, useLocation, useParams} from "react-router-dom";
import {useMemo} from "react";
import {FormDialog} from "@Components/FormDialog.tsx";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {Required} from "@Util/Validation/Required.ts";
import {TextField} from "@Components/TextField.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {useTranslationAPI} from "@API/TranslationAPI.ts";
import {styled} from "@mui/system";
import {theme} from "@Util/theme.tsx";
import {FCProps} from "@Util/FCProps.ts";
import {Translated} from "@Components/Localization/Translated.tsx";

export const TranslationEditDialog = () => {
  const elements = useLocalisedTranslations();
  const params = useParams<{ id: string }>();
  const location = useLocation();
  const api = useTranslationAPI();

  const item = useMemo(() => {
    return elements.find(element => {
      return params.id && element.id === parseInt(params.id);
    });
  }, [elements, params]);

  const valueField = useFormField({
    initialValue: item?.value ?? '',
    validators: [
      new Required(),
    ]
  });

  const back: To = {
    pathname: '/admin/translations',
    search: location.search,
  }

  const handleSubmit = async () => {
    if (!item) {
      throw new Error('Item is not defined');
    }

    await api.update({
      translatable: item.id,
      locale: item.locale,
      value: valueField.value as string,
    });
  }

  const dialogTitle = (
    <>
      <Translated value="Translation"/> {item?.locale.toUpperCase()}
    </>
  );
  const inputLabel = <Translated value="Translated text"/>;

  return (
    <FormDialog title={dialogTitle} onSubmit={handleSubmit} back={back}>

      <TextField field={valueField} label={inputLabel}/>
      <Original>{item?.key}</Original>

      <ActionButton requireValid={[valueField]}/>
    </FormDialog>
  )
}

export const Original = ({children}: FCProps) => {
  return (
    <HelperText>
      <b>Original:</b>{children}
    </HelperText>
  )
}

export const HelperText = styled('p')`
  margin-top: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(4)};

  b {
    margin-right: ${theme.spacing(1)};
  }
`;
