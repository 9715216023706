import {FormDialog} from "@Components/FormDialog.tsx";
import {CheckboxesField} from "@Components/CheckboxesField.tsx";
import {useMultiSelectField} from "@Components/useMultiselect.ts";
import {ActionButton} from "@Components/ActionButton.tsx";
import {useLocalisedTopics} from "@Components/Providers/TopicsProvider.tsx";
import {useTherapist} from "../TherapistProvider.tsx";
import {useTherapistAPI} from "@API/TherapistAPI.ts";
import {Translated} from "@Components/Localization/Translated.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const TopicsDialog = () => {
  const api = useTherapistAPI();
  const therapist = useTherapist();
  const topics = useLocalisedTopics();

  const field = useMultiSelectField({
    initialValue: [],
    options: topics,
    value: therapist.topics?.map(t => t.id) ?? [],
    getOptionValue: item => item.id,
    getOptionLabel: item => item.translation.title ?? item.title,
  });

  const handleSubmit = async () => {
    await api.updateTopics(field.value as number[]);
  };

  return (
    <FormDialog title={<Translated value={TRANSLATIONS.PROFILE.TOPICS_AND_PROBLEMS}/>} onSubmit={handleSubmit}>
      <CheckboxesField field={field}/>

      <ActionButton/>
    </FormDialog>
  )
}
