import {Box} from "@mui/material";
import {ArticleOutlined} from "@mui/icons-material";
import {NotFilled} from "@Components/NotFilled.tsx";
import {Editable} from "../../Editable.tsx";
import {useTherapist} from "../TherapistProvider.tsx";
import {PrimarySection} from "../PrimarySection.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";


export const AboutMe = () => {
  const therapist = useTherapist();
  const text = [therapist.summary, therapist.about]
    .filter(string => !!string)
    .join('\n');

  return (
    <PrimarySection IconComponent={ArticleOutlined}>
      <Editable editPath="about" label={TRANSLATIONS.PROFILE.ABOUT_ME}>
        <AboutMeText text={text}/>
      </Editable>
    </PrimarySection>
  );
}

interface AboutMeTextProps {
  text?: string;
}

export const AboutMeText = ({text}: AboutMeTextProps) => {

  if (!text) {
    return <NotFilled/>
  }

  return (
    <Box sx={{display: 'inline', whiteSpace: 'pre-line'}}>
      {text.trim()}
    </Box>
  );
}
