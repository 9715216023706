import {useTherapistAdminAPI} from "@API/TherapistAdminAPI.ts";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {useSearchAwarePath} from "@Util/Hooks/Navigate.tsx";
import {FormDialog} from "@Components/FormDialog.tsx";
import {CheckboxField} from "@Components/CheckboxField.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {useLoaderData} from "react-router-dom";
import {MemberReviewData} from "../Loader/MemberReviewLoader.tsx";
import {Required} from "@Util/Validation/Required.ts";
import {MembershipDropdownOption} from "../../../Membership/MembershipDropdownOption.tsx";
import {useMembershipOptions} from "@Components/Membership/useMembershipOptions.tsx";
import {Box} from "@mui/material";

export const AccountApproveDialog = () => {
  const [user] = useLoaderData() as MemberReviewData;
  const membershipOptions = useMembershipOptions()
    .filter((option) => {
      return [user.membership?.type.id, user.membership?.pendingType?.id].includes(option.id);
    });

  const api = useTherapistAdminAPI();
  const approved = useFormField<boolean>({
    initialValue: false,
    validators: [
      new Required()
    ],
  });

  const handleSubmit = async () => {
    await api.review({
      id: user.id as number,
      approved: true,
    });
  }

  const back = useSearchAwarePath('../..');
  const fullName = `${user.firstName} ${user.lastName}`;

  return (
    <FormDialog title={fullName} onSubmit={handleSubmit} maxWidth="md" back={back}>

      <Box mb={2} display="flex" flexDirection="column" gap={2}>
        {membershipOptions.map((option) => (
          <MembershipDropdownOption key={option.id} option={option}/>
        ))}
      </Box>

      <CheckboxField
        field={approved}
        label={(
          <Translated value="I have carefully reviwed user account"/>
        )}
      />

      <ActionButton requireValid={[approved]}>
        <Translated value="Approve"/>
      </ActionButton>
    </FormDialog>
  );
}
