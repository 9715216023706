import {Translated} from "@Components/Localization/Translated.tsx";
import {WorksWithProvider} from "@Components/Providers/WorksWithProvider.tsx";
import {Outlet} from "react-router-dom";
import {WorksWithTable} from "./WorksWithTable.tsx";
import {AddButton} from "@Components/Button/AddButton.tsx";
import {LanguageSelect} from "@Components/LanguageSelect.tsx";
import {useState} from "react";
import {Language} from "@Components/I18nWrapper.tsx";
import {useSearchAwareNavigate} from "@Util/Hooks/Navigate.tsx";
import {useLoading} from "@Util/Hooks/Loading.tsx";
import {CircularProgress} from "@mui/material";

export const WorksWithListPage = () => {
  const navigate = useSearchAwareNavigate();
  const isLoading = useLoading();
  const [language, setLanguage] = useState<Language>();

  const showAddDialog = () => navigate('add');

  return (
    <>
      <h1><Translated value="Work with"/></h1>

      <AddButton onClick={showAddDialog}/>

      <LanguageSelect onSelected={setLanguage}/>

      <WorksWithProvider language={language}>
        {isLoading ? <CircularProgress/> : <WorksWithTable/>}
        <Outlet/>
      </WorksWithProvider>
    </>
  );
}
