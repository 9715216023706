import {Translated} from "@Components/Localization/Translated.tsx";
import {FormDialog} from "@Components/FormDialog.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";

export const PaymentMethodDialog = () => {
  const onSubmit = async () => {

  }

  return (
    <FormDialog
      title={(
        <Translated value="Payment method"/>
      )}
      onSubmit={onSubmit}>
      <ActionButton/>
    </FormDialog>
  );
}
