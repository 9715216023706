import {RouteObject} from "react-router-dom";
import {CityListPage} from "./CityListPage.tsx";
import {CityAddDialog} from "./CityAddDialog.tsx";
import {CityUpdateDialog} from "./CityUpdateDialog.tsx";
import {CityDeleteDialog} from "./CityDeleteDialog.tsx";

export const CityRoute: RouteObject = {
  path: 'city',
  Component: CityListPage,
  children: [
    {
      path: 'add',
      Component: CityAddDialog,
    },
    {
      path: ':id',
      children: [
        {
          path: 'update',
          Component: CityUpdateDialog,
        },
        {
          path: 'delete',
          Component: CityDeleteDialog,
        },
      ],
    },
  ],
};
