import {Currency} from "@API/CurrencyAPI.ts";
import {createContext, useContext} from "react";
import {FCProps} from "@Util/FCProps.ts";

const CurrencyContext = createContext<Currency[]>([]);

export const useCurrencyList = () => useContext(CurrencyContext);

export interface CurrencyProviderProps extends FCProps {
  value: Currency[];
}

export const CurrencyProvider = (props: CurrencyProviderProps) => {
  return (
    <CurrencyContext.Provider value={props.value}>
      {props.children}
    </CurrencyContext.Provider>
  );
}
