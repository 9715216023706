import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {Translated} from "@Components/Localization/Translated.tsx";
import {LocalisedCountry, useLocalisedCountries} from "@Components/Providers/CountriesProvider.tsx";
import {useNavigate, useSearchParams} from "react-router-dom";
import {EditButton} from "@Components/Button/EditButton.tsx";
import {DeleteButton} from "@Components/Button/DeleteButton.tsx";

const NoTranslation = () => <Translated value="No translation"/>

export const CountryListTable = () => {
  const list = useLocalisedCountries();
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const showEditDialog = (item: LocalisedCountry) => {
    navigate({
      pathname: `${item.id}/update`,
      search: search.toString(),
    });
  }

  const showDeleteDialog = (item: LocalisedCountry) => {
    navigate({
      pathname: `${item.id}/delete`,
      search: search.toString(),
    })
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Localised name</TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list.map(country => (
          <TableRow key={country.id}>
            <TableCell>{country.id}</TableCell>
            <TableCell>{country.name}</TableCell>
            <TableCell>
              {country.localisedName ?? <NoTranslation/>}
            </TableCell>
            <TableCell align="right">
              <EditButton onClick={() => showEditDialog(country)} />
              <DeleteButton onClick={() => showDeleteDialog(country)} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
