import {useTranslated} from "@Components/Localization/Translated.tsx";
import {FCProps} from "@Util/FCProps.ts";
import {styled} from "@mui/system";
import {theme} from "@Util/theme.tsx";

export interface LevelProps {
  level: 1 | 2 | 3 | 4 | 5 | 6;
}

export interface HeadlineProps extends LevelProps {
  text: string;
}

export const Headline = ({level, text}: HeadlineProps) => {
  const translated = useTranslated(text);
  const words = translated.split(' ');

  const [last] = words.splice(-1);

  return (
    <HeaderComponent level={level}>
      {words.join(' ')} <Highlight>{last}</Highlight>
    </HeaderComponent>
  )
}

const HeaderComponent = ({level, children}: LevelProps & FCProps) => {
  switch (level) {
    case 1:
      return <h1>{children}</h1>;
    case 2:
      return <h2>{children}</h2>;
    case 3:
      return <h3>{children}</h3>
    case 4:
      return <h4>{children}</h4>
    case 5:
      return <h5>{children}</h5>
    default:
      return <h6>{children}</h6>
  }
}

const Highlight = styled('span')`
  color: ${theme.palette.primary.main};
`
