import {LocalisedTopic, useLocalisedTopics} from "@Components/Providers/TopicsProvider.tsx";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {EditButton} from "@Components/Button/EditButton.tsx";
import {DeleteButton} from "@Components/Button/DeleteButton.tsx";
import {useLocation, useNavigate} from "react-router-dom";

export const TopicTable = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const items = useLocalisedTopics();

  const showEditDialog = (id: number) => {
    navigate({
      pathname: `${id}/update`,
      search: location.search,
    });
  };
  const showDeleteDialog = (id: number) => {
    navigate({
      pathname: `${id}/delete`,
      search: location.search,
    });
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Locale</TableCell>
          <TableCell>Title (original)</TableCell>
          <TableCell>Title (translated)</TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item: LocalisedTopic) => (
          <TableRow key={item.id}>
            <TableCell>{item.id}</TableCell>
            <TableCell>{item.translation.locale}</TableCell>
            <TableCell>{item.title}</TableCell>
            <TableCell>{item.translation.title ?? 'N/A'}</TableCell>
            <TableCell align="right">
              <EditButton onClick={() => showEditDialog(item.id)}/>
              <DeleteButton onClick={() => showDeleteDialog(item.id)}/>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
