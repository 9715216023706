import {useNavigate, useParams} from "react-router-dom";
import {Translated} from "@Components/Localization/Translated.tsx";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {Required} from "@Util/Validation/Required.ts";
import {PasswordField} from "@Components/PasswordField.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {useUserAPI} from "@API/User.ts";
import {useNotifications} from "@Util/Hooks/Notifications.tsx";

export const SetPasswordPage = () => {
  const api = useUserAPI()
  const navigate = useNavigate();

  const {id, token} = useParams<{id: string, token: string}>();
  const showNotification = useNotifications();

  const password = useFormField<string>({
    initialValue: '',
    validators: [
      new Required()
    ],
  });

  const handleSubmit = () => {
    api
      .resetPassword({
        id: Number(id),
        token: token as string,
        password: password.value as string,
      })
      .then(() => {
        navigate('/account');
      })
      .catch(() => {
        showNotification({
          variant: 'error',
          autoHideDuration: 3000,
          message: <Translated value="Failed to update password. Pleas try again later." />,
        });
      });
  }

  return (
    <>
      <h1><Translated value="Reset password"/></h1>

      <PasswordField
        field={password}
        label={(
          <Translated value="New password"/>
        )}/>

      <ActionButton requireValid={[password]} onClick={handleSubmit}>
        Update
      </ActionButton>
    </>
  )
}
