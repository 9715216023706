import {FormDialog} from "@Components/FormDialog.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {Required} from "@Util/Validation/Required.ts";
import {ActionButton} from "@Components/ActionButton.tsx";
import {TextField} from "@Components/TextField.tsx";
import {useSearchAwarePath} from "@Util/Hooks/Navigate.tsx";
import {useTranslationAPI} from "@API/TranslationAPI.ts";
import {useSearchParams} from "react-router-dom";

export const TranslationAddDialog = () => {
  const api = useTranslationAPI();
  const back = useSearchAwarePath('..');
  const [params] = useSearchParams();

  const source = useFormField<string>({
    initialValue: '',
    validators: [
      new Required(),
    ],
  });

  const translation = useFormField<string>({
    initialValue: '',
    validators: [
      new Required(),
    ],
  });

  const handleSubmit = async () => {
    const section = parseInt(params.get('section') as string);

    await api.create({
      locale: params.get('language') as string,
      section: section,
      key: source.value as string,
      value: translation.value as string,
    });
  }

  return (
    <FormDialog title={<Translated value="Translation add"/>} onSubmit={handleSubmit} back={back}>
      <TextField
        field={source}
        autoComplete="off"
        label={(
          <Translated value="Source"/>
        )}
      />
      <TextField
        field={translation}
        autoComplete="off"
        label={(
          <Translated value="Translation"/>
        )}
      />
      <ActionButton/>
    </FormDialog>
  )
}
