import {ConfirmDelete} from "@Components/ConfirmDelete.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {useSearchAwarePath} from "@Util/Hooks/Navigate.tsx";
import {useQualification} from "./useQualification.tsx";
import {useQualificationAPI} from "@API/QualificationAPI.ts";

export const QualificationDeleteDialog = () => {
  const api = useQualificationAPI();
  const item = useQualification();
  const back = useSearchAwarePath('../..');

  const handleSubmit = async () => {
    if (!item) {
      throw new Error('No item');
    }

    await api.delete({id: item.id});
  };

  return (
    <ConfirmDelete title={<Translated value="Qualification delete"/>} onSubmit={handleSubmit} back={back}/>
  );
}
