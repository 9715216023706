import {useTherapist} from "../TherapistProvider.tsx";
import {Editable} from "../../Editable.tsx";
import {WorksWithList} from "./WorkWithList.tsx";
import {PrimarySection} from "../PrimarySection.tsx";
import {PeopleRounded} from "@mui/icons-material";
import {TRANSLATIONS} from "@assets/translation.ts";

export const WorkWith = () => {
  const therapist = useTherapist();

  return (
    <PrimarySection IconComponent={PeopleRounded}>
      <Editable editPath="work-with" label={TRANSLATIONS.PROFILE.I_WORK_WITH}>
        <WorksWithList elements={therapist.worksWith}/>
      </Editable>
    </PrimarySection>
  )
}
