import {Box, Button, Card, FormHelperText, IconButton, Tooltip} from "@mui/material";
import {ChangeEvent, ReactNode, useRef, useState} from "react";
import {styled} from "@mui/system";
import {Clear} from "@mui/icons-material";
import {theme} from "@Util/theme.tsx";
import {DataUrlFieldState} from "@Components/Image/useDataUrlField.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";

export interface DataUrlImageFieldProps {
  field: DataUrlFieldState;
  label: ReactNode;
}

export const DataUrlImageField = ({field, label}: DataUrlImageFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [resetCount, setResetCount] = useState(0);

  const pickFile = () => {
    inputRef?.current?.click();
  }

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      field.setFile(file);
    }
  };

  const handleReset = () => {
    setResetCount((current) => ++current);
    field.reset();
  }

  return (
    <Box sx={{mb: 2}}>
      {field.value
        ? (
          <RelativeBlock>
            <FullWidthImage src={field.value}/>
            <Tooltip arrow placement="left" title={<Translated value="tooltip_remove"/>}>
              <ClearButton onClick={handleReset}>
                <Clear/>
              </ClearButton>
            </Tooltip>
          </RelativeBlock>
        )
        : (
          <Wrapper variant="outlined">
            <Button onClick={pickFile}>{label}</Button>
          </Wrapper>
        )
      }

      <Box sx={{display: 'none'}}>
        <input
          key={resetCount}
          ref={inputRef}
          type="file"
          onChange={handleFileSelect}
          accept="image/*"
        />
      </Box>

      {field.error && <FormHelperText>{field.error}</FormHelperText>}
    </Box>
  );
}

const RelativeBlock = styled('div')`
  position: relative;
  overflow: visible;
`

const Wrapper = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
`

const ClearButton = styled(IconButton)`
  padding: 0;
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: ${theme.palette.grey[200]};
  border: 1px solid ${theme.palette.divider};
  font-weight: bold;

  &:hover {
    background-color: ${theme.palette.grey[100]};
  }
`

const FullWidthImage = styled('img')`
  width: 100%;
  object-fit: contain;
  padding: 0;
`
