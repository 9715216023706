import {AuthUser} from "@Util/Hooks/Auth.tsx";
import {useFormSelectField} from "@Util/Hooks/FormSelectField.ts";
import {MembershipDropdownOption} from "./MembershipDropdownOption.tsx";
import {useMembershipOptions} from "@Components/Membership/useMembershipOptions.tsx";

export const useMembershipSelectField = (user: AuthUser) => {
  const options = useMembershipOptions();

  return useFormSelectField({
    initialValue: user.membership?.pendingType?.id ?? user.membership?.type.id ?? '',
    options: options,
    getOptionValue: (o) => o.id,
    getOptionLabel: (o) => <MembershipDropdownOption option={o}/>,
  });
}
