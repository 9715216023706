import {NotFilled} from "@Components/NotFilled.tsx";
import {useMembershipOptions} from "@Components/Membership/useMembershipOptions.tsx";
import {useCurrentUser} from "@Util/Hooks/Auth.tsx";

export const MembershipSection = () => {
  const user = useCurrentUser();
  const options = useMembershipOptions();
  const current = user.membership?.pendingType ?? user.membership?.type;
  const selected = options.find(o => o.id === current?.id);

  return (
    <span>
      {selected?.title ?? <NotFilled/>}
    </span>
  );
}
