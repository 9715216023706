import {RouteObject} from "react-router-dom";
import {CountryListPage} from "./CountryListPage.tsx";
import {CountryAddDialog} from "./CountryAddDialog.tsx";
import {CountryUpdateDialog} from "./CountryUpdateDialog.tsx";
import {CountryDeleteDialog} from "./CountryDeleteDialog.tsx";

export const CountryRoute: RouteObject = {
  path: 'country',
  Component: CountryListPage,
  children: [
    {
      path: 'add',
      Component: CountryAddDialog,
    },
    {
      path: ':id',
      children: [
        {
          path: 'update',
          Component: CountryUpdateDialog,
        },
        {
          path: 'delete',
          Component: CountryDeleteDialog,
        }
      ]
    }
  ]
}
