import {useParams} from "react-router-dom";
import {useTherapist} from "../TherapistProvider.tsx";

export const useCertificate = () => {
  const therapist = useTherapist();
  const params = useParams<{id: string}>();
  const numeric = params.id ? parseInt(params.id): undefined;

  return therapist.certificates?.find(cert => cert.id === numeric);
}
