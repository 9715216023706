import {HighlightItem, HighlightItemProps} from "./HighlightItem.tsx";
import {VolumeUpRounded} from "@mui/icons-material";
import {useI18n} from "@Components/I18nWrapper.tsx";
import {useTherapist} from "../../Account/Therapist/TherapistProvider.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const LanguagesView = (props: Partial<HighlightItemProps>) => {
  const t = useTherapist();
  const languages = useI18n()
    .filter(l => {
      return t.languages?.map(item => item.code).includes(l.code);
    })
    .map(l => l.name)
    .join(', ');

  if (languages.length === 0) {
    return '';
  }

  return (
    <HighlightItem IconComponent={VolumeUpRounded} label={TRANSLATIONS.PROFILE.LANGUAGES} {...props}>
      {languages}
    </HighlightItem>
  )
}
