import {RouteObject} from "react-router-dom";
import {AccountPage} from "./AccountPage.tsx";
import {accountDataLoader} from "./AccountDataProvider.tsx";
import {NameDialog} from "./Common/NameDialog.tsx";
import {EmailDialog} from "./Common/EmailDialog.tsx";
import {DateOfBirthDialog} from "./Common/DateOfBirthDialog.tsx";
import {SocialNetworkDialog} from "./Therapist/SocialNetworks/SocialNetworks.tsx";
import {AboutMeDialog} from "./Therapist/AboutMe/AboutMeDialog.tsx";
import {WorkOnlineDialog} from "./Therapist/WorkOnline/WorkOnlineDialog.tsx";
import {WorkWithDialog} from "./Therapist/WorkWith/WorkWithDialog.tsx";
import {LanguagesDialog} from "./Therapist/TherapyLanguages/LanguagesDialog.tsx";
import {TopicsDialog} from "./Therapist/Topics/TopicsDialog.tsx";
import {EducationRoute} from "./Therapist/Education/route.ts";
import {CertificatesRoute} from "./Therapist/Certificates/route.ts";
import {LocationsRoute} from "./Therapist/Locations/route.tsx";
import {SendToReviewDialog} from "./Therapist/Review/SendToReviewDialog.tsx";
import {SetPriceDialog} from "./Therapist/Price/SetPriceDialog.tsx";
import {SexDialog} from "./Common/SexDialog.tsx";
import {YearsOfExperienceDialog} from "./Therapist/YearsOfExperience/YearsOfExperienceDialog.tsx";

export const AccountRoute: RouteObject = {
  path: 'account',
  loader: accountDataLoader,
  shouldRevalidate: () => false,
  Component: AccountPage,
  children: [
    {
      path: 'name',
      Component: NameDialog,
    },
    {
      path: 'email',
      Component: EmailDialog,
    },
    {
      path: 'date-of-birth',
      Component: DateOfBirthDialog,
    },
    {
      path: 'years-of-experience',
      Component: YearsOfExperienceDialog,
    },
    {
      path: 'sex',
      Component: SexDialog,
    },
    {
      path: 'price',
      Component: SetPriceDialog,
    },
    {
      path: 'social/add',
      Component: SocialNetworkDialog,
    },
    {
      path: 'social/:id/update',
      Component: SocialNetworkDialog,
    },
    {
      path: 'about',
      Component: AboutMeDialog,
    },
    {
      path: 'work-online',
      Component: WorkOnlineDialog,
    },
    {
      path: 'languages',
      Component: LanguagesDialog,
    },

    {
      path: 'work-with',
      Component: WorkWithDialog,
    },
    {
      path: 'topics',
      Component: TopicsDialog,
    },
    {
      path: 'review',
      Component: SendToReviewDialog,
    },
    LocationsRoute,
    EducationRoute,
    CertificatesRoute,
  ],
};
