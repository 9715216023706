import {useFormField} from "@Util/Hooks/FormField.ts";
import {Required} from "@Util/Validation/Required.ts";
import {IsEmail} from "@Util/Validation/IsEmail.ts";
import {PasswordField} from "@Components/PasswordField.tsx";
import {useSubmitHandler} from "@Util/Hooks/Form.tsx";
import {useAxios} from "@Util/Hooks/Http.tsx";
import {TextField} from "@Components/TextField.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {Translated, useTranslated} from "@Components/Localization/Translated.tsx";
import {Link} from "@Components/Link.tsx";
import {BottomHint} from "./BottomHint.tsx";
import {useSearchParams} from "react-router-dom";
import {useSearchAwarePath} from "@Util/Hooks/Navigate.tsx";
import {Helmet} from "react-helmet-async";
import {useFormSelectField} from "@Util/Hooks/FormSelectField.ts";
import {useMembershipOptions} from "@Components/Membership/useMembershipOptions.tsx";
import {SelectField} from "@Components/SelectField.tsx";
import {MembershipDropdownOption} from "../Membership/MembershipDropdownOption.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const Register = () => {
  const http = useAxios();
  const [searchQuery] = useSearchParams()
  const loginPath = useSearchAwarePath('../log-in');
  const membershipOptions = useMembershipOptions();

  const defaultMembership = searchQuery.has("membership")
    ? membershipOptions.find(({id}) => id === Number(searchQuery.get('membership')))
    : membershipOptions[0];

  const membership = useFormSelectField({
    initialValue: defaultMembership?.id ?? '',
    options: membershipOptions,
    getOptionValue: (o) => o.id,
    getOptionLabel: (o) => <MembershipDropdownOption option={o}/>,
    validators: [
      new Required()
    ],
  });

  const firstName = useFormField<string>({
    initialValue: '',
    validators: [
      new Required(),
    ],
  });

  const lastName = useFormField<string>({
    initialValue: '',
    validators: [
      new Required(),
    ],
  });

  const login = useFormField<string>({
    initialValue: '',
    validators: [
      new Required(),
      new IsEmail(),
    ],
  });

  const password = useFormField<string>({
    initialValue: '',
    validators: [
      new Required(),
    ],
  });

  const onSubmit = useSubmitHandler(async () => {
    await http.post('/api/auth/register', {
      firstName: firstName.value,
      lastName: lastName.value,
      userName: login.value,
      password: password.value,
      membership: membership.selected?.id,
    });
  });

  const title = useTranslated('Register')

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <form noValidate onSubmit={onSubmit} method="post">
        <SelectField
          field={membership}
          label={(
            <Translated value="Membership type"/>
          )}
        />
        <TextField
          label={(
            <Translated value="First name"/>
          )}
          field={firstName}
          autoComplete="off"
        />
        <TextField
          label={(
            <Translated value="Last name"/>
          )}
          field={lastName}
          autoComplete="off"
        />
        <TextField
          field={login}
          type="email"
          label={(
            <Translated value="Email"/>
          )}
          autoComplete="email username login"
        />
        <PasswordField
          field={password}
          label={(
            <Translated value="Password"/>
          )}
          autoComplete="password"
        />

        <ActionButton requireValid={[membership, firstName, lastName, login, password]}>
          <Translated value="Register"/>
        </ActionButton>
      </form>

      <BottomHint>
        <Translated value={TRANSLATIONS.AUTH.HAVE_ACCOUNT_ALREADY}/>
        <Link to={loginPath}>
          <Translated value="Log in"/>.
        </Link>
      </BottomHint>
    </>
  );
}
