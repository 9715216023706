import {LanguageSelect} from "@Components/LanguageSelect.tsx";
import {useEffect, useMemo, useState} from "react";
import {Translatable, TranslationSection, useTranslationAPI} from "@API/TranslationAPI.ts";
import {Language} from "@Components/I18nWrapper.tsx";
import {Box, CircularProgress, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {LocalisedTranslationsProvider} from "./TranslationsProvider.tsx";
import {Outlet} from "react-router-dom";
import {useLoading} from "@Util/Hooks/Loading.tsx";
import {Edit} from "@mui/icons-material";
import {useResponseActionInterceptor} from "@Util/Hooks/Http.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {AddButton} from "@Components/Button/AddButton.tsx";
import {useSearchAwareNavigate} from "@Util/Hooks/Navigate.tsx";
import {AvailableTransSectionsProvider} from "./AvailableTransSectionsProvider.tsx";
import {SectionSelect} from "./SectionSelect.tsx";

export interface LocalizedTranslation {
  id: number;
  key: string;
  locale: string;
  value?: string
}

export const TranslationsPage = () => {
  const api = useTranslationAPI();
  const isLoading = useLoading();
  const navigate = useSearchAwareNavigate();

  const [section, setSection] = useState<TranslationSection>();
  const [language, setLanguage] = useState<Language>();

  const [translatable, setTranslatable] = useState<Translatable[]>([])

  useEffect(() => {
    if (section) {
      api.list(section).then(setTranslatable);
    }
  }, [section]);

  const localised: LocalizedTranslation[] = useMemo(() => {

    return translatable.map(item => {
      const translation = item.translations.find(t => t.language.code == language?.code);

      return {
        id: item.id,
        locale: language?.code as string,
        key: item.key,
        value: translation?.value,
      }
    })
  }, [translatable, language]);

  useResponseActionInterceptor(/^translation$/, async () => {
    if (section) {
      await api.list(section).then(setTranslatable)
    }
  }, [translatable]);

  const createEditNavigate = (id: number) => {
    return () => navigate(`${id}/edit`);
  }

  return (
    <>
      <h1><Translated value="Interface translation"/></h1>

      <Box mb={2}>
        <AddButton onClick={() => navigate('add')}/>
      </Box>

      <AvailableTransSectionsProvider>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <SectionSelect onSelected={setSection} selected={section}/>
          </Grid>
        </Grid>
      </AvailableTransSectionsProvider>
      <LanguageSelect onSelected={setLanguage}/>

      {isLoading && <CircularProgress/>}

      {localised.length > 0 && !isLoading && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><Translated value="ID"/></TableCell>
              <TableCell><Translated value="Key"/></TableCell>
              <TableCell><Translated value="Value"/></TableCell>
              <TableCell align="right"><Translated value="Actions"/></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {localised.map(({id, key, value}) => (
              <TableRow key={id}>
                <TableCell>{id}</TableCell>
                <TableCell>{key}</TableCell>
                <TableCell>{value ?? 'N/A'}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={createEditNavigate(id)}>
                    <Edit/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {!isLoading && localised.length == 0 && (
        <p>No data to display</p>
      )}

      <LocalisedTranslationsProvider value={localised}>
        <Outlet/>
      </LocalisedTranslationsProvider>
    </>
  )
}
