import {FormDialog} from "@Components/FormDialog.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {To, useSearchParams} from "react-router-dom";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {Required} from "@Util/Validation/Required.ts";
import {useCityAPI} from "@API/CityAPI.tsx";
import {useFormSelectField} from "@Util/Hooks/FormSelectField.ts";
import {LocalisedCountry, useLocalisedCountries} from "@Components/Providers/CountriesProvider.tsx";
import {SelectField} from "@Components/SelectField.tsx";
import {TextField} from "@Components/TextField.tsx";

export const CityAddDialog = () => {
  const api = useCityAPI();
  const [search] = useSearchParams();

  const country = useFormSelectField<LocalisedCountry, number>({
    initialValue: 0,
    options: useLocalisedCountries(),
    getOptionValue: c => c.id,
    getOptionLabel: c => c.localisedName ?? c.name,
    validators: [
      new Required(),
    ],
  });

  const name = useFormField<string>({
    initialValue: '',
    validators: [
      new Required(),
    ],
  });

  const handleSubmit = async () => {
    await api.create(country.value as number, name.value as string);
  };

  const title = <Translated value="Add city"/>;

  const back: To = {
    pathname: '..',
    search: search.toString(),
  }

  return (
    <FormDialog title={title} onSubmit={handleSubmit} back={back}>
      <SelectField
        field={country}
        label={(
          <Translated value="Country" />
        )}
      />

      <TextField
        field={name}
        label={(
          <Translated value="Name" />
        )}
      />

      <ActionButton requireValid={[country, name]} />
    </FormDialog>
  );
}
