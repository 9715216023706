import { AuthUser } from '@Util/Hooks/Auth.tsx';
import { useAxios } from '@Util/Hooks/Http.tsx';
import { AxiosInstance, AxiosResponse } from 'axios';

export interface UserLink {
  id: number;
  url: string;
  user: AuthUser;
}

export const useLinksAPI = () => {
  const http = useAxios();

  return new LinksApi(http);
}

class LinksApi {
  private readonly ENDPOINT = '/api/therapist/links';
  constructor(private readonly http: AxiosInstance) {}

  async list(): Promise<UserLink[]> {
    return this.http.get(this.ENDPOINT).then(fetchBody);
  }

  async addLink(body: AddLinkBody): Promise<UserLink> {
    return this.http.post(this.ENDPOINT, body).then(fetchBody)
  }

  async updateLink(body: UpdateLinkBody): Promise<UserLink> {
    return this.http.put(this.ENDPOINT, body).then(fetchBody)
  }

  async deleteLink(body: DeleteLinkBody) {
    return this.http.delete(this.ENDPOINT, {data: body});
  }
}

const fetchBody = (response: AxiosResponse) => response.data;

export interface AddLinkBody {
  url: string;
}

export interface UpdateLinkBody {
  id: number;
  url: string;
}

export interface DeleteLinkBody {
  id: number;
}
