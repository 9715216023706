import {Delete} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import {ButtonProps} from "./ButtonProps.tsx";

export const DeleteButton = ({onClick}: ButtonProps) => {
  return (
    <IconButton onClick={onClick}>
      <Delete/>
    </IconButton>
  );
};
