import {useSearchParams} from "react-router-dom";

interface SearchParamFilterOptions<T> {
  default: T;
  transform: (value: string) => T,
}

export function useSearchParam<T>(name: string, options: SearchParamFilterOptions<T>): T {
  const [search] = useSearchParams();
  const value = search.get(name);

  if (value) {
    return options.transform(value)
  } else {
    return options.default;
  }
}
