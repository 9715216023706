import {FCProps} from "@Util/FCProps.ts";
import {TherapistProvider} from "./Therapist/TherapistProvider.tsx";
import {CountriesProvider} from "@Components/Providers/CountriesProvider.tsx";
import {LocalisedCityProvider} from "@Components/Providers/LocalisedCityProvider.tsx";
import {WorksWithProvider} from "@Components/Providers/WorksWithProvider.tsx";
import {TopicsProvider} from "@Components/Providers/TopicsProvider.tsx";
import {QualificationProvider} from "@Components/Providers/QualificationProvider.tsx";
import {AuthProvider, AuthUser} from "@Util/Hooks/Auth.tsx";
import {Country, CountryAPI} from "@API/CountryAPI.ts";
import {City, CityAPI} from "@API/CityAPI.tsx";
import {LoaderFunction} from "react-router-dom";
import {createUniversalAPI} from "@Util/UniversalAPI.ts";
import {Therapist, TherapistAPI, useTherapistAPI} from "@API/TherapistAPI.ts";
import {WorksWith, WorksWithAPI} from "@API/WorksWithAPI.ts";
import {Topic, TopicsAPI} from "@API/TopicsAPI.ts";
import {Qualification, QualificationAPI} from "@API/QualificationAPI.ts";
import {Currency, CurrencyAPI} from "@API/CurrencyAPI.ts";
import {CurrencyProvider} from "@Components/Providers/CurrencyProvider.tsx";
import {Sex, SexAPI} from "@API/SexAPI.ts";
import {SexProvider} from "@Components/Providers/SexProvider.tsx";
import {Membership, MembershipAPI} from "@API/MembershipAPI.ts";
import {MembershipListProvider} from "@Components/Membership/MembershipProvider.tsx";
import {AxiosError} from "axios";
import {useResponseActionInterceptor} from "@Util/Hooks/Http.tsx";
import {Targets} from "@API/TargetAPI.ts";
import {useState} from "react";

export const accountDataLoader: LoaderFunction = async ({context}): Promise<AccountData> => {
  const therapistAPI = createUniversalAPI(TherapistAPI, context);
  const countryAPI = createUniversalAPI(CountryAPI, context);
  const cityAPI = createUniversalAPI(CityAPI, context);
  const membershipAPI = createUniversalAPI(MembershipAPI, context);
  const worksWithAPI = createUniversalAPI(WorksWithAPI, context);
  const topicsAPI = createUniversalAPI(TopicsAPI, context);
  const qualificationAPI = createUniversalAPI(QualificationAPI, context);
  const currencyAPI = createUniversalAPI(CurrencyAPI, context);
  const sexAPI = createUniversalAPI(SexAPI, context);

  return await Promise.all([
    therapistAPI.current().catch((error) => {
      if (error instanceof AxiosError && error.response?.status === 404) {
        return undefined
      }

      throw error;
    }),
    countryAPI.list(),
    cityAPI.list(),
    membershipAPI.list(),
    worksWithAPI.list(),
    topicsAPI.list(),
    qualificationAPI.list(),
    currencyAPI.list(),
    sexAPI.list(),
  ]);
}

export type AccountData = [
  Therapist | undefined,
  Country[],
  City[],
  Membership[],
  WorksWith[],
  Topic[],
  Qualification[],
  Currency[],
  Sex[],
];

export interface DataProviderProps extends FCProps {
  data: [AuthUser, ...AccountData];
}

export const AccountDataProvider = ({children, data}: DataProviderProps) => {
  const therapistAPI = useTherapistAPI();
  const [therapist, setTherapist] = useState(data[1]);

  useResponseActionInterceptor(/^user$/, async (response) => {
    const { membership } = response.data as AuthUser;

    if (membership?.type.target === Targets.THERAPIST || membership?.pendingType?.target === Targets.THERAPIST) {
      await therapistAPI.current().then(setTherapist);
    }
  }, []);

  return (
    <AuthProvider value={data[0]}>
      <TherapistProvider value={therapist}>
        <CountriesProvider list={data[2]}>
          <LocalisedCityProvider value={data[3]}>
            <MembershipListProvider value={data[4]}>
              <WorksWithProvider value={data[5]}>
                <TopicsProvider value={data[6]}>
                  <QualificationProvider value={data[7]}>
                    <CurrencyProvider value={data[8]}>
                      <SexProvider value={data[9]}>
                        {children}
                      </SexProvider>
                    </CurrencyProvider>
                  </QualificationProvider>
                </TopicsProvider>
              </WorksWithProvider>
            </MembershipListProvider>
          </LocalisedCityProvider>
        </CountriesProvider>
      </TherapistProvider>
    </AuthProvider>
  );
}
