import {useMemo} from "react";
import {Box, Button, ButtonProps, CircularProgress} from "@mui/material";
import {LoadingProvider, useLoading} from "@Util/Hooks/Loading.tsx";
import {FormField} from "@Util/Hooks/FormField.ts";
import {Translated} from "@Components/Localization/Translated.tsx";

export interface ActionButtonProps extends ButtonProps {
  requireValid?: FormField<any>[]
}

export const ActionButton = (props: ActionButtonProps) => {
  return (
    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      <LoadingProvider queryOnly={false}>
        <ButtonWithIndicator {...props}/>
      </LoadingProvider>
    </Box>
  );
}

const ButtonWithIndicator = (props: ActionButtonProps) => {
  const isLoading = useLoading();
  const {children, requireValid, ...rest} = props

  const allValid = useMemo(() => {
    return !requireValid || requireValid.every(f => f.isValid);
  }, [requireValid]);

  rest.disabled = rest.disabled || isLoading || !allValid;

  return (
    <>
      <Button {...rest} variant="contained" color="primary" type="submit">
        {children ?? <Translated value="Save"/>}
      </Button>
      {isLoading && (
        <Box sx={{pr: 2, pl: 2}}>
          <CircularProgress size={20}/>
        </Box>
      )}
    </>
  );
}
