import {scalar} from "@Util/Hooks/FormField.ts";
import {MultiSelectField} from "@Components/useMultiselect.ts";
import {Checkbox, FormControlLabel, FormGroup, InputLabel} from "@mui/material";
import {ChangeEvent, ReactNode, useMemo} from "react";

interface CheckboxesFieldProps<O, V extends scalar> {
  field: MultiSelectField<O, V>;
  label?: ReactNode;
}

interface Option {
  label: ReactNode;
  value: string|number;
  checked: boolean;
}

export function CheckboxesField<O, V extends scalar>(props: CheckboxesFieldProps<O, V>) {
  const {field, label} = props;
  const {getOptionValue, getOptionLabel, options} = field;
  const selectedValues = field.value ?? [];

  const viewOptions: Option[] = useMemo(() => {
      return options.map(option => {
        const value = getOptionValue(option);
        const label = getOptionLabel ? getOptionLabel(option) : getOptionValue(option);
        const checked = selectedValues.includes(value as V);

        return {value, label, checked};
      });
    },
    [selectedValues, options]
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const changedValue = event.target.value;

    const newValues = field.options
      .map(option => getOptionValue(option))
      .filter(v => {
        const exists = selectedValues.includes(v as V);

        if (v == changedValue) {
          return checked;
        }

        return exists;
      });

    field.onChange(newValues as V[]);
  }

  return (
    <FormGroup sx={{mb: 2}}>
      {label && (
        <InputLabel>{label}</InputLabel>
      )}
      {viewOptions.map(option =>(
        <FormControlLabel key={option.value}
          control={(
            <Checkbox onChange={handleChange} value={option.value} checked={option.checked} />
          )}
          label={option.label}
        />
      ))}

    </FormGroup>
  )
}
