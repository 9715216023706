import {Box, ButtonBase} from "@mui/material";
import {useCurrentLanguage, useI18n} from "@Components/I18nWrapper.tsx";
import {useUserAPI} from "@API/User.ts";
import {getMatchPattern} from "@Util/Hooks/Navigate.tsx";
import {generatePath, useLocation, useNavigate, useParams} from "react-router-dom";
import {styled} from "@mui/system";
import {theme} from "@Util/theme.tsx";
import {useLocationMatch} from "@Util/Hooks/LocationMatch.tsx";
import {useTranslated} from "@Components/Localization/Translated.tsx";

type Locale = {
  code: string;
  color: string;
}

export const LanguageSwitch = () => {
  const currentLanguage = useCurrentLanguage();
  const userApi = useUserAPI();
  const languages = useI18n();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate()
  const siteLanguages = languages.filter(l => l.isSiteLocale && l.isEnabled);

  const changeLocale = async (code: string) => {
    await userApi.changeLocale(code);

    const pattern = getMatchPattern(location);

    navigate({
      pathname: generatePath(pattern, Object.assign({}, params, {
        locale: code,
      })),
      search: location.search,
    });
  };

  const isHome = useLocationMatch('/');
  const contrastColor = isHome
    ? theme.palette.primary.contrastText
    : theme.palette.secondary.contrastText;

  const list = siteLanguages.map((item): Locale => {
    const color = item.code === currentLanguage?.code
      ? theme.palette.primary.main
      : contrastColor;

    return {code: item.code, color: color};
  });

  const StyledBtnGroup = useButtonGroup({
    borderColor: contrastColor,
  });

  const switchLanguage = useTranslated('Switch language');

  return (
    <Box display="flex" alignItems="center">
      <StyledBtnGroup color="inherit">
        {list.map(({code, color}) => (
          <ButtonBase
            key={code}
            sx={{color}}
            onClick={() => changeLocale(code)}
            aria-label={`${switchLanguage}: ${code}`}
          >
            {code}
          </ButtonBase>
        ))}
      </StyledBtnGroup>
    </Box>
  );
}

interface ButtonGroupProps {
  borderColor: string;
}

const useButtonGroup = ({borderColor}: ButtonGroupProps) => styled('div')`
  display: flex;
  
  button {
    font-size: 12px;
    font-weight: bolder;
    text-transform: uppercase;
    padding: 0 ${theme.spacing(1)};
    
    &:hover {
      cursor: pointer;
    }
  }

  button:not(:last-child) {
    border-right: 1px solid ${borderColor};
  }
`
