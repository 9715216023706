import { API, useAPI } from '@API/API.ts';
import { Language } from '@Components/I18nWrapper.tsx';

export interface QualificationI18n {
  id: number;
  title: string;
  locale: Language;
}

export interface Qualification {
  id: number;
  title: string;
  i18n: QualificationI18n[];
}

export interface QualificationCreate {
  title: string;
}

export interface QualificationUpdate {
  id: number;
  title: string;
  locale: string;
}

export interface QualificationDelete {
  id: number;
}

const ENDPOINT = '/api/qualification';

export class QualificationAPI extends API {
  async list(): Promise<Qualification[]> {
    const response = await this.http.get(ENDPOINT);

    return response.data as Qualification[];
  }

  async create(data: QualificationCreate): Promise<Qualification> {
    const response = await this.http.post(ENDPOINT, data);

    return response.data as Qualification;
  }

  async update(data: QualificationUpdate): Promise<Qualification> {
    const response = await this.http.put(ENDPOINT, data);

    return response.data as Qualification;
  }

  async delete(data: QualificationDelete): Promise<void> {
    await this.http.delete(ENDPOINT, {data});
  }
}

export const useQualificationAPI = () => useAPI(QualificationAPI);
