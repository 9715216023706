import {AuthContainer} from "./AuthPage.tsx";
import {TransSection} from "@Components/Localization/TransSection.tsx";
import {Outlet} from "react-router-dom";
import {LoadingProvider} from "@Util/Hooks/Loading.tsx";

export const ResetPasswordContainer = () => {
  return (
    <AuthContainer>
      <TransSection section="auth">
        <LoadingProvider queryOnly={false}>
          <Outlet/>
        </LoadingProvider>
      </TransSection>
    </AuthContainer>
  );
}
