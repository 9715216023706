import {ComponentType, ReactNode} from "react";
import {Box, SvgIconProps} from "@mui/material";
import {theme} from "@Util/theme.tsx";
import {PanelSpaced} from "@Components/Layout/Panel.tsx";

export interface PrimarySectionProps {
  IconComponent: ComponentType<SvgIconProps>;
  children: ReactNode;
  noWrap?: true
}
export const PrimarySection = ({ IconComponent, children, noWrap }: PrimarySectionProps) => {
  const content = (
    <Box display="flex" gap={theme.spacing(1)}>
      <IconComponent color="primary"/>
      <Box flexGrow="1">
        {children}
      </Box>
    </Box>
  );

  if (noWrap) {
    return content;
  } else {
    return <PanelSpaced>{content}</PanelSpaced>
  }
};
