import {theme} from "@Util/theme";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {LoaderFunction, Outlet, useLoaderData} from "react-router-dom";
import {AxiosProvider, useResponseActionInterceptor} from "@Util/Hooks/Http.tsx";
import {AuthProvider, AuthUser} from "@Util/Hooks/Auth.tsx";
import {useMemo, useState} from "react";
import {AxiosResponse} from "axios";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LoadingProvider} from "@Util/Hooks/Loading.tsx";
import {I18nWrapper, Language} from "@Components/I18nWrapper.tsx";
import {createUniversalAPI, UniversalContext} from "@Util/UniversalAPI.ts";
import {UserApi, useUserAPI} from "@API/User.ts";
import {LanguageAPI} from "@API/LanguageAPI.ts";
import {TranslationAPI} from "@API/TranslationAPI.ts";
import {DB} from "@Components/Localization/TransDB.tsx";
import {WebsocketProvider} from "@Components/Providers/WebsocketProvider.tsx";
import {SnackbarProvider} from "notistack";
import {CheckVersion} from "@Util/CheckVersion.tsx";
import {GlobalMeta} from "./GlobalMeta.tsx";
import {UnderConstruction} from "./UnderConstruction.tsx";
import {ChatProvider} from "@Components/Providers/ChatProvider.tsx";
import {Membership, MembershipAPI} from "@API/MembershipAPI.ts";
import {MembershipListProvider} from "@Components/Membership/MembershipProvider.tsx";

export type GlobalData = [
  UniversalContext,
  AuthUser,
  Language[],
  DB,
  Membership[],
];

export const globalDataLoader: LoaderFunction = async ({context}): Promise<GlobalData> => {
  const userApi = createUniversalAPI(UserApi, context);
  const languageAPI = createUniversalAPI(LanguageAPI, context);
  const translationAPI = createUniversalAPI(TranslationAPI, context);
  const membershipAPI = createUniversalAPI(MembershipAPI, context);

  return await Promise.all([
    context,
    userApi.current(),
    languageAPI.list(),
    translationAPI.database(),
    membershipAPI.list(),
  ]);
}

export const GlobalLayout = () => {
  const globalData = useLoaderData() as GlobalData;
  const api = useUserAPI();
  const [changedUser, changeUser] = useState<AuthUser | null>(null);

  const currentUser = useMemo(() => changedUser ?? globalData[1], [changedUser, globalData]);

  useResponseActionInterceptor(/^auth$/, async (r: AxiosResponse) => {
    changeUser(r.data);
  }, [changedUser]);

  useResponseActionInterceptor(/^avatar/, async () => {
    await api.current().then(changeUser);
  }, [changedUser]);

  useResponseActionInterceptor(/^user$/, async (r: AxiosResponse) => {
    const responseUser = r.data as AuthUser;

    if (currentUser.id === responseUser.id) {
      changeUser(responseUser);
    }
  }, [currentUser]);

  return (
    <AxiosProvider>
      <AuthProvider value={currentUser}>
        <LoadingProvider queryOnly={true}>
          <I18nWrapper languages={globalData[2]} db={globalData[3]}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MembershipListProvider value={globalData[4]}>
                <ThemeProvider theme={theme}>
                  <CssBaseline/>
                  <SnackbarProvider anchorOrigin={{horizontal: "right", vertical: "top"}}>
                    <WebsocketProvider>
                      <ChatProvider>
                        <CheckVersion>
                          <Outlet/>
                          <UnderConstruction/>
                        </CheckVersion>
                      </ChatProvider>
                    </WebsocketProvider>
                  </SnackbarProvider>
                  <GlobalMeta context={globalData[0]}/>
                </ThemeProvider>
              </MembershipListProvider>
            </LocalizationProvider>
          </I18nWrapper>
        </LoadingProvider>
      </AuthProvider>
    </AxiosProvider>
  );
}
