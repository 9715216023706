import {Outlet} from "react-router-dom";
import {Box, Container} from "@mui/material";
import {TopBar} from "./partial/TopBar.tsx";
import {WideSection} from "@Components/Layout/WideSection.tsx";
import {Footer} from "./Footer.tsx";
import {theme} from "@Util/theme.tsx";

export const MainLayout = () => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <TopBar/>
      <Box flexGrow="1" pb={2} sx={{backgroundColor: theme.palette.secondary.light}}>
        <Container maxWidth="lg">
          <Outlet/>
        </Container>
      </Box>
      <WideSection>
        <Container maxWidth="lg">
          <Footer/>
        </Container>
      </WideSection>
    </Box>
  );
}
