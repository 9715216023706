import {Translated} from "@Components/Localization/Translated.tsx";
import {useState} from "react";
import {LanguageSelect} from "@Components/LanguageSelect.tsx";
import {Language} from "@Components/I18nWrapper.tsx";
import {CountriesProvider} from "@Components/Providers/CountriesProvider.tsx";
import {Outlet, useNavigate, useSearchParams} from "react-router-dom";
import {AddButton} from "@Components/Button/AddButton.tsx";
import {CountryListTable} from "./CountryListTable.tsx";
import {useLoading} from "@Util/Hooks/Loading.tsx";
import {CircularProgress} from "@mui/material";


export const CountryListPage = () => {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const [language, setLanguage] = useState<Language>();
  const isLoading = useLoading();

  const showAddDialog = () => {
    navigate({
      pathname: 'add',
      search: search.toString(),
    });
  }

  return (
    <>
      <h1><Translated value="Countries"/></h1>

      <AddButton onClick={showAddDialog}/>

      <LanguageSelect onSelected={setLanguage} />

      {language && (
        <CountriesProvider i18nLanguage={language}>
          {isLoading ? <CircularProgress/> : <CountryListTable/>}
          <Outlet/>
        </CountriesProvider>
      )}
    </>
  );
}
