import {EditableInline} from "../Editable.tsx";
import {useCurrentUser} from "@Util/Hooks/Auth.tsx";
import dayjs from "dayjs";
import {Translated} from "@Components/Localization/Translated.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const AgeSection = () => {
  const user = useCurrentUser();
  const age = user.dateOfBirth
    ? dayjs().diff(user.dateOfBirth, 'years')
    : <Translated value={TRANSLATIONS.PROFILE.NOT_FILLED}/>

  return (
    <EditableInline label={TRANSLATIONS.PROFILE.AGE} editPath="date-of-birth">
      {age}
    </EditableInline>
  );
}
