import {Drawer, Fade, LinearProgress, SxProps} from "@mui/material";
import {Menu,List, MenuItem, ListItemIcon} from "@mui/material";
import {AppBar, Avatar, Box, Divider, IconButton} from "@mui/material";
import {useCurrentUser} from "@Util/Hooks/Auth.tsx";
import {CloseRounded, MenuRounded, Payment} from '@mui/icons-material';
import {ArticleOutlined, LoginOutlined, LogoutRounded, ManageAccountsOutlined} from '@mui/icons-material';
import {To, useHref, useMatch, useNavigate, useNavigation} from "react-router-dom";
import {FC, ReactNode, useRef, useState} from "react";
import {useAxios} from "@Util/Hooks/Http.tsx";
import {LanguageSwitch} from "@Components/LanguageSwitch.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {useCurrentLanguage} from "@Components/I18nWrapper.tsx";
import {usePath} from "@Util/Hooks/Navigate.tsx";
import {FCProps} from "@Util/FCProps.ts";
import {useTopBarNavigation} from "../menu/TopBarNavigation.tsx";
import {Link} from "@Components/Link.tsx";
import {useLocationMatch} from "@Util/Hooks/LocationMatch.tsx";
import {styled} from "@mui/system";
import {NORMAL_RADIUS, theme} from "@Util/theme.tsx";
import {Logo} from "./Logo.tsx";

export interface PageLink {
  title: string;
  to: To;
  icon?: ReactNode;
  forceIcon?: true;
}

const visibleOnLarge: SxProps = {display: {xs: 'none', md: 'flex'}};
const visibleOnSmall: SxProps = {display: {xs: 'flex', md: 'none'}};

export const TopBar = () => {
  const http = useAxios();
  const navigate = useNavigate();
  const user = useCurrentUser();
  const pages = useTopBarNavigation();
  const isAnonymous = !user.id;
  const [isMainMenuOpen, setMainMenuOpen] = useState(false);
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);
  const barRef = useRef<HTMLDivElement>(null);
  const {state} = useNavigation();

  const logout = async () => {
    await http.delete('/api/auth');
    await http.get('/api/auth');

    setUserMenuOpen(false);

    navigate('/');
  }

  const menuNavigate = (to: To) => {
    setMainMenuOpen(false);
    setUserMenuOpen(false);
    navigate(to);
  }

  const userMenu: ReactNode[] = [];

  if (isAnonymous) {
    userMenu.push(
      <NavigationMenuItem key="auth" path="/auth/log-in" onClick={menuNavigate}>
        <ListItemIcon>
          <LoginOutlined/>
        </ListItemIcon>
        <Translated value="Log-in"/>
      </NavigationMenuItem>
    );
  } else {
    userMenu.push(
      <NavigationMenuItem key="profile" path="/account" onClick={menuNavigate}>
        <ListItemIcon>
          <ManageAccountsOutlined/>
        </ListItemIcon>
        <Translated value="My account"/>
      </NavigationMenuItem>
    );

    userMenu.push(
      <NavigationMenuItem key="membership" path="/membership" onClick={menuNavigate}>
        <ListItemIcon>
          <Payment/>
        </ListItemIcon>
        <Translated value="Membership"/>
      </NavigationMenuItem>
    );

    userMenu.push(
      <MenuItem key="logout" onClick={logout}>
        <ListItemIcon>
          <LogoutRounded/>
        </ListItemIcon>
        <Translated value="Log-out"/>
      </MenuItem>
    );
  }

  const isHome = useLocationMatch('/');

  const isBusy = state !== 'idle';
  const zIndex = isHome ? 1 : 0;
  const appBarColor = isHome && !isMainMenuOpen ? 'transparent' : 'secondary';
  const menuColor = isHome ? theme.palette.primary.contrastText : theme.palette.text.primary;

  return (
    <Box sx={{zIndex}} width="100vw" overflow="hidden">
      <AppBar ref={barRef} sx={{position: 'relative', borderRadius: 0}} color={appBarColor} elevation={0}>
        <Fade in={isBusy} timeout={1000}>
          <LinearProgress/>
        </Fade>
        <Box display="flex" gap={2} alignItems="center" sx={{color: menuColor}}>
          <LogoWrapper>
            <Logo/>
          </LogoWrapper>
          <Box sx={{p: 0, flexGrow: 1, display: 'flex', justifyContent: 'space-between'}}>
            <CenteredBox sx={{width: '100%', maxWidth: theme.breakpoints.values.lg}}>
              <DesktopMenuWrapper sx={visibleOnLarge}>
                {pages.map(({to, title, icon, forceIcon}) => (
                  <NavigationButton key={title} to={to}>
                    {forceIcon && icon}
                    <Translated value={title}/>
                  </NavigationButton>
                ))}
              </DesktopMenuWrapper>
            </CenteredBox>
            <CenteredBox>
              <Box sx={visibleOnLarge}>
                <LanguageSwitch/>
              </Box>
              <Box sx={visibleOnLarge}>
                <IconButton aria-label="Open profile menu" onClick={() => setUserMenuOpen(true)}>
                  <Avatar src={user.avatar?.path} alt={`${user.firstName} ${user.lastName}`}/>
                </IconButton>
              </Box>
            </CenteredBox>
          </Box>

          <CenteredBox>
            <Box key="small" p={2} sx={visibleOnSmall}>
              <IconButton aria-label="Open main menu" color="inherit" onClick={() => setMainMenuOpen(!isMainMenuOpen)}>
                <MenuRounded sx={{height: '30px', width: '30px'}} color={isHome ? "white" : "inherit" }/>
              </IconButton>
            </Box>
          </CenteredBox>

          <Menu
            TransitionComponent={Fade}
            sx={{mt: '60px'}}
            anchorEl={barRef.current}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            open={isUserMenuOpen}
            onClose={() => setUserMenuOpen(false)}
          >
            {userMenu}
          </Menu>
        </Box>
      </AppBar>

      <Drawer
        anchor="right"
        open={isMainMenuOpen}
        PaperProps={{sx: {backgroundColor: theme.palette.secondary.main, borderRadius: 0}}}
        onClose={() => setMainMenuOpen(false)}
      >
        <Box display="flex" p={2} justifyContent="space-between" alignItems="flex-start">
          <Box display="flex" alignItems="center" gap={2}>
            <Avatar src={user.avatar?.path} alt={`${user.firstName} ${user.lastName}`}/>
            <Box>
              <Box>
                {user.id ? [user.firstName, user.lastName].join(' ') : <Translated value="Welcome"/>}
              </Box>
              <Box ml={-1} pt={1}>
                <LanguageSwitch/>
              </Box>
            </Box>
          </Box>

          <IconButton onClick={() => setMainMenuOpen(false)}>
            <CloseRounded/>
          </IconButton>
        </Box>
        <Divider/>
        <List sx={{width: '100vw'}}>
          {pages.map(({title, to, icon}) => (
            <NavigationMenuItem key={title} path={to} onClick={menuNavigate}>
              <ListItemIcon>
                {icon ? icon : <ArticleOutlined/>}
              </ListItemIcon>
              <Translated value={title}/>
            </NavigationMenuItem>
          ))}
        </List>
        <Divider/>
        <List>
          {userMenu}
        </List>
      </Drawer>
    </Box>
  );
}

export const CenteredBox = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`

export interface NavigationButtonProps extends FCProps {
  to: To;
  icon?: ReactNode;
}

export const NavigationButton: FC<NavigationButtonProps> = ({children, to}) => {
  const locale = useCurrentLanguage();

  const path = usePath(to, locale?.code);
  const href = useHref(path);
  const active = !!useMatch(href);

  return (
    <StyledMenuLink to={path} className={active ? 'active' : ''}>
      {children}
    </StyledMenuLink>
  );
};

const StyledMenuLink = styled(Link)`
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
  font-weight: 500;
  white-space: nowrap;
  color: inherit;
  border-radius: ${NORMAL_RADIUS}px;
  
  &.active {
    color: ${theme.palette.primary.main};
  }
  
  &:hover {
    text-decoration: none;
    background-color: ${theme.palette.primary.dark}05;
  }
`

interface NavigationMenuItemProps extends FCProps {
  path: To;
  onClick: (path: To) => void;
}

const NavigationMenuItem: FC<NavigationMenuItemProps> = ({children, path, onClick}) => {
  const locale = useCurrentLanguage();
  const absolutePath = usePath(path, locale?.code);

  return (
    <MenuItem onClick={() => onClick(absolutePath)}>
      {children}
    </MenuItem>
  );
}

export const DesktopMenuWrapper = styled(Box)`
  width: 100%;
  justify-content: start;
  max-width: ${theme.breakpoints.values.lg};
  gap: ${theme.spacing(2)};
  
  ${theme.breakpoints.up("md")} {
    gap: ${theme.spacing(4)};
  }
`

const LogoWrapper = styled('div')`
  padding: 0 ${theme.spacing(3)} 0 ${theme.spacing(3)};
  width: max(150px, calc((100vw - ${theme.breakpoints.values.lg}px)/2 + ${theme.spacing(2)}));
`
