import { API } from '@API/API.ts';
import { Language } from '@Components/I18nWrapper.tsx';
import { useAxios } from '@Util/Hooks/Http.tsx';

export interface WorksWith {
  id: number;
  title: string;
  i18n: WorksWithI18n[];
}

export interface WorksWithI18n {
  locale: Language;
  title: string;
}

export const useWorksWithAPI = () => {
  const http = useAxios();

  return new WorksWithAPI(http);
}

const ENDPOINT = '/api/works-with';


export interface WorksWithCreate {
  title: string;
}

export interface WorksWithUpdate extends WorksWithCreate {
  id: number;
  locale: string;
}

export class WorksWithAPI extends API {
  async list() {
    const response = await this.http.get(ENDPOINT);

    return response.data as WorksWith[]
  }

  async create(data: WorksWithCreate) {
    const response = await this.http.post(ENDPOINT, data);

    return response.data as WorksWith;
  }

  async update(data: WorksWithUpdate): Promise<WorksWith> {
    const response = await this.http.put(ENDPOINT, data);

    return response.data as WorksWith;
  }

  async delete(id: number): Promise<void> {
    await this.http.delete(ENDPOINT, {data: {id}});
  }
}
