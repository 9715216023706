import { API, useAPI } from '@API/API.ts';
import { Language } from '@Components/I18nWrapper.tsx';

export interface TopicI18n {
  id: number;
  title: string;
  locale: Language;
}

export interface Topic {
  id: number;
  title: string;
  i18n: TopicI18n[];
}

export interface TopicCreate {
  title: string;
}

export interface TopicUpdate extends TopicCreate {
  id: number;
  locale: string;
}

export interface TopicDelete {
  id: number;
}

export const useTopicsAPI = () => {
  return useAPI(TopicsAPI);
}

const ENDPOINT = '/api/topic';

export class TopicsAPI extends API {
  async list(): Promise<Topic[]> {
    const response = await this.http.get(ENDPOINT);

    return response.data as Topic[];
  }

  async create(data: TopicCreate): Promise<Topic> {
    const response = await this.http.post(ENDPOINT, data);

    return response.data as Topic;
  }

  async update(data: TopicUpdate): Promise<Topic> {
    const response = await this.http.put(ENDPOINT, data);

    return response.data as Topic;
  }

  async delete(data: TopicDelete): Promise<void> {
    await this.http.delete(ENDPOINT, {data});
  }
}
