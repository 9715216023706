import {useParams} from "react-router-dom";
import {useAxios} from "@Util/Hooks/Http.tsx";
import {FormDialog} from "@Components/FormDialog.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {CheckboxField} from "@Components/CheckboxField.tsx";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {Language, useLanguage} from "@Components/I18nWrapper.tsx";
import {Required} from "@Util/Validation/Required.ts";
import {TextField} from "@Components/TextField.tsx";

export const LanguageEditDialog = () => {
  const params = useParams<{ code: string }>();

  const http = useAxios();
  const language: Language | undefined = useLanguage(params.code);

  const name = useFormField({
    initialValue: language?.name ?? '',
    validators: [
      new Required(),
    ],
  })

  const isSiteLocale = useFormField({
    initialValue: language?.isSiteLocale ?? false,
  });

  const isEnabled = useFormField({
    initialValue: language?.isEnabled ?? false,
  });

  const updateLanguage = async () => {
    if (!language) {
      return;
    }

    await http.post('/api/language', {
      code: language.code,
      name: name.value,
      isEnabled: isEnabled.value,
      isSiteLocale: isSiteLocale.value,
    });
  }

  return (
    <FormDialog title="Update language" onSubmit={updateLanguage}>
      <TextField field={name} label="Name"/>
      <CheckboxField label="Enabled" field={isEnabled}/>
      <CheckboxField label="Use as site locale" field={isSiteLocale}/>

      <ActionButton>Save</ActionButton>
    </FormDialog>
  );
}
