import {Translated} from "@Components/Localization/Translated.tsx";
import {LanguageSelect} from "@Components/LanguageSelect.tsx";
import {useState} from "react";
import {Language} from "@Components/I18nWrapper.tsx";
import {AddButton} from "@Components/Button/AddButton.tsx";
import {CityListTable} from "./CityListTable.tsx";
import {LocalisedCityProvider} from "@Components/Providers/LocalisedCityProvider.tsx";
import {Outlet, useNavigate, useSearchParams} from "react-router-dom";
import {CountriesProvider} from "@Components/Providers/CountriesProvider.tsx";
import {useLoading} from "@Util/Hooks/Loading.tsx";
import {CircularProgress} from "@mui/material";

export const CityListPage = () => {
  const [language, setLanguage] = useState<Language>();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const isLoading = useLoading();

  const showAddDialog = () => {
    navigate({
      pathname: 'add',
      search: search.toString(),
    });
  };

  return (
    <>
      <h1><Translated value="City list"/></h1>

      <AddButton onClick={showAddDialog}/>

      <LanguageSelect onSelected={setLanguage}/>

      {language?.code && (
        <CountriesProvider i18nLanguage={language}>
          <LocalisedCityProvider i18nLanguage={language}>
            {isLoading ? <CircularProgress/> : <CityListTable/>}

            <Outlet/>
          </LocalisedCityProvider>
        </CountriesProvider>
      )}
    </>
  )
}
