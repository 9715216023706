import { RouteObject } from 'react-router-dom';
import { QualificationAddDialog } from './QualificationAddDialog.tsx';
import { QualificationDeleteDialog } from './QualificationDeleteDialog.tsx';
import { QualificationPage } from './QualificationPage.tsx';
import { QualificationUpdateDialog } from './QualificationUpdateDialog.tsx';

export const QualificationAdminRoute: RouteObject = {
  path: 'qualification',
  Component: QualificationPage,
  children: [
    {
      path: 'add',
      Component: QualificationAddDialog,
    },
    {
      path: ':id',
      children: [
        {
          path: 'update',
          Component: QualificationUpdateDialog,
        },
        {
          path: 'delete',
          Component: QualificationDeleteDialog,
        },
      ],
    },
  ],
};
