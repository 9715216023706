import {useTherapist} from "../../Account/Therapist/TherapistProvider.tsx";
import {HighlightItem} from "./HighlightItem.tsx";
import {CalendarMonth} from "@mui/icons-material";
import {TRANSLATIONS} from "@assets/translation.ts";

export const AgeView = () => {
  const t = useTherapist();

  return (
    <HighlightItem label={TRANSLATIONS.PROFILE.AGE} IconComponent={CalendarMonth}>
      {t.age}
    </HighlightItem>
  )
}
