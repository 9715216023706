import {IconButton} from "@mui/material";
import {Done} from "@mui/icons-material";
import {ButtonProps} from "./ButtonProps.tsx";

export const ConfirmButton = ({onClick}: ButtonProps) => {
  return (
    <IconButton onClick={onClick}>
      <Done/>
    </IconButton>
  );
};
