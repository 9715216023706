import {Translated} from "@Components/Localization/Translated.tsx";
import {Box} from "@mui/material";
import {useTherapist} from "../TherapistProvider.tsx";
import {styled} from "@mui/system";
import {theme} from "@Util/theme.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const ReviewStatusSection = () => {
  const therapist = useTherapist();

  return (
    <Box mb={2}>
      <span>
        <Label><Translated value={TRANSLATIONS.PROFILE.STATUS}/>:</Label>
        <Translated value={therapist.status ?? 'N/A'}/>
      </span>
    </Box>
  );
}

const Label = styled('b')`
  margin-right: ${theme.spacing(1)};
`
