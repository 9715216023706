import {io, Socket} from 'socket.io-client';
import {createContext, useContext, useEffect, useMemo} from "react";
import {FCProps} from "@Util/FCProps.ts";
import {useCurrentUser} from "@Util/Hooks/Auth.tsx";

const WebsocketContext = createContext<Socket|null>(null);

export const useWebSocket = () => {
  const ws = useContext(WebsocketContext);

  if (ws === null) {
    throw new Error('WebsocketProvider needed for this hook');
  }

  return ws;
}

export enum Topics {
  CHAT_MESSAGE = 'chat.message',
  CHAT_MESSAGE_UPDATED = 'chat.message.updated',
}

export const WebsocketProvider = ({children}: FCProps) => {
  const user = useCurrentUser();
  const ws = useMemo(() => io({
    autoConnect: false,
    reconnection: true,
    transports: ['websocket'],
    timeout: 1000,
    auth: {
      user: user.id, // Server checks that this id actually matches authenticated user by cookie
    }
  }), [user.id]);

  useEffect(() => {
    if (ws.connected) {
      ws.disconnect();
    }

    if (!user.id) {
      return;
    }

    ws.connect();

    const handleConnection = () => ws.emit('join');

    ws.on('connect', handleConnection);

    return () => {
      ws.off('connect', handleConnection);

      if (ws.connected) {
        ws.disconnect();
      }
    }
  }, [user.id]);

  return (
    <WebsocketContext.Provider value={ws}>
      {children}
    </WebsocketContext.Provider>
  );
}
