import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {FCProps} from "@Util/FCProps.ts";
import {useAxios} from "@Util/Hooks/Http.tsx";
import {AxiosError} from "axios";

const LoadingContext = createContext(false);

export interface LoadingProviderProps extends FCProps {
  queryOnly: boolean;
}

export const LoadingProvider = ({children, queryOnly}: LoadingProviderProps) => {
  const http = useAxios();
  const [pendingCount, setPendingCount] = useState(0);
  const [lastActive, setLastActive] = useState<Date>();
  const queryPattern = /^get$/i;

  useEffect(() => {
    const increment = () => {
      setLastActive(new Date());
      setPendingCount((prevState) => ++prevState);
    };

    const decrement = () => {
      setPendingCount((prevState) => Math.min(--prevState, 0));
    }

    const reqId = http.interceptors.request.use((req) => {
      if (queryOnly) {
        if (queryPattern.test(req.method as string)) {
          increment()
        }
      } else {
        increment()
      }

      return req;
    });

    const resId = http.interceptors.response.use((res) => {
      if (queryOnly) {
        if (queryPattern.test(res.config.method as string)) {
          decrement()
        }
      } else {
        decrement();
      }

      return res;
    }, (error: AxiosError) => {
      const method = error.response?.config.method;
      if (queryOnly && method) {
        if (queryPattern.test(method as string)) {
          decrement()
        }
      } else {
        decrement();
      }

      throw error;
    });

    return () => {
      http.interceptors.request.eject(reqId);
      http.interceptors.response.eject(resId);
    };
  }, [http]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (lastActive) {
        const since = lastActive.getTime() + 400;
        const till = new Date().getTime();

        if (since < till) {
          setLastActive(undefined);
        }
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [lastActive]);

  const isLoading = useMemo(() => {
    return pendingCount > 0 || !!lastActive;
  }, [pendingCount, lastActive])

  return (
    <LoadingContext.Provider value={isLoading}>
      {children}
    </LoadingContext.Provider>
  );
}

export const useLoading = () => useContext(LoadingContext);
