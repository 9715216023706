import {FCProps} from "@Util/FCProps.ts";
import {ReactNode} from "react";
import {Box, Breakpoint, Dialog, DialogContent, DialogTitle, IconButton, ModalProps} from "@mui/material";
import {To, useNavigate} from "react-router-dom";
import {useSubmitHandler} from "@Util/Hooks/Form.tsx";
import {Close} from "@mui/icons-material";

export interface FormDialogProps extends FCProps {
  title: ReactNode;
  back?: To;
  redirect?: To
  maxWidth?: Breakpoint;
  onSubmit: () => Promise<void>;
}

export const FormDialog = (props: FormDialogProps) => {
  const navigate = useNavigate();
  const back = props.back ?? '..';

  const onSubmit = useSubmitHandler(async () => {
    await props.onSubmit();

    navigate(props.redirect ?? back);
  });

  const onClose: ModalProps['onClose'] = (_, reason) => {
    switch (reason) {
      case "backdropClick":
        return;
      default:
        navigate(back);
    }
  }

  return (
    <Dialog fullWidth maxWidth={props.maxWidth ?? 'xs'} open={true} onClose={onClose}>
      <DialogTitle mr={2}>{props.title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => navigate(back)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close/>
      </IconButton>
      <DialogContent>
        <form onSubmit={onSubmit} noValidate autoComplete="off">
          {props.children}
        </form>
      </DialogContent>
    </Dialog>
  )
}

export const Actions = ({children}: FCProps) => {
  return (
    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
      {children}
    </Box>
  );
}
