import {SelectField} from "@Components/SelectField.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {TextField} from "@Components/TextField.tsx";
import {DataUrlImageField} from "@Components/Image/DataUrlImageField.tsx";
import {FormSelectField, useFormSelectField} from "@Util/Hooks/FormSelectField.ts";
import {LocalisedQualification, useLocalisedQualifications} from "@Components/Providers/QualificationProvider.tsx";
import {Required} from "@Util/Validation/Required.ts";
import {FormField, useFormField} from "@Util/Hooks/FormField.ts";
import dayjs from "dayjs";
import {Range} from "@Util/Validation/Range.ts";
import {DataUrlFieldState, useDataUrlField} from "@Components/Image/useDataUrlField.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {NumericFormField, useNumericFormField} from "@Util/Hooks/NumericTextField.ts";
import {Education} from "@API/EducationAPI.ts";
import {Button} from "@mui/material";
import {useSearchAwareNavigate} from "@Util/Hooks/Navigate.tsx";
import {Actions} from "@Components/FormDialog.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export interface EducationFormState {
  qualification: FormSelectField<LocalisedQualification, number>
  institutionName: FormField<string>,
  speciality: FormField<string>,
  graduatedAt: NumericFormField,
  photo: DataUrlFieldState,
}

export const useEducationFormState = (item?: Education): EducationFormState => {
  const qualification = useFormSelectField<LocalisedQualification, number>({
    initialValue: '',
    value: item?.qualification.id,
    options: useLocalisedQualifications(),
    getOptionValue: (q) => q.id,
    getOptionLabel: (q) => q.localised.title ?? q.title,
    validators: [
      new Required(),
    ],
  });

  const institutionName = useFormField<string>({
    initialValue: '',
    value: item?.institution,
    validators: [
      new Required(),
    ]
  });

  const speciality = useFormField<string>({
    initialValue: '',
    value: item?.speciality,
    validators: [
      new Required(),
    ],
  });

  const currentYear = dayjs().year();
  const graduatedAt = useNumericFormField({
    initialValue: '',
    value: item?.graduationYear.toString(),
    validators: [
      new Required(),
      new Range({min: currentYear - 100, max: currentYear}),
    ]
  });

  const photo = useDataUrlField({
    initialValue: '',
    value: item?.photo,
    validators: item ? [] : [
      new Required(),
    ],
  });

  return {
    qualification,
    institutionName,
    speciality,
    graduatedAt,
    photo,
  };
}

export interface EducationFormProps {
  fields: EducationFormState;
  allowDelete?: true;
}

export const EducationForm = ({fields, allowDelete}: EducationFormProps) => {
  const navigate = useSearchAwareNavigate();
  const showDeleteModal = () => navigate('../delete');

  return (
    <>
      <SelectField
        field={fields.qualification}
        label={(
          <Translated value={TRANSLATIONS.PROFILE.QUALIFICATION}/>
        )}/>
      <TextField
        field={fields.institutionName}
        label={(
          <Translated value={TRANSLATIONS.PROFILE.INSTITUTION_NAME}/>
        )}
        autoComplete="off"
        name="institution"
      />
      <TextField
        field={fields.speciality}
        name="speciality"
        autoComplete="off"
        label={(
          <Translated value={TRANSLATIONS.PROFILE.SPECIALIZATION}/>
        )}
      />
      <TextField
        field={fields.graduatedAt}
        label={(
          <Translated value={TRANSLATIONS.PROFILE.GRADUATION_YEAR}/>
        )}
        name="graduation_year"
        autoComplete="off"
        type="number"
      />
      <DataUrlImageField
        field={fields.photo}
        label={(
          <Translated value={TRANSLATIONS.PROFILE.UPLOAD_PHOTO_OF_DIPLOMA}/>
        )}
      />

      <Actions>
        <ActionButton requireValid={Object.values(fields)}/>
        {allowDelete && (
          <Button onClick={showDeleteModal} color="error">
            <Translated value="Delete"/>
          </Button>
        )}
      </Actions>
    </>
  )
}
