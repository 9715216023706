import {Therapist} from "@API/TherapistAPI.ts";
import {FC} from "react";
import {FormDialog} from "@Components/FormDialog.tsx";
import {AuthProvider, AuthUser} from "@Util/Hooks/Auth.tsx";
import {TherapistProvider} from "../../../Account/Therapist/TherapistProvider.tsx";
import {AccountPageContent} from "../../../Account/AccountPageContent.tsx";
import {useSearchAwarePath} from "@Util/Hooks/Navigate.tsx";
import {EditableProvider} from "../../../Account/EditableProvider.tsx";

export interface TherapistReviewProps {
  user: AuthUser;
  therapist: Therapist;
}

export const TherapistPreview: FC<TherapistReviewProps> = ({user, therapist}) => {

  const handleSubmit = async () => {}

  const back = useSearchAwarePath('../..');

  return (
    <FormDialog title="Review" onSubmit={handleSubmit} maxWidth="lg" back={back}>
      <AuthProvider value={user}>
        <TherapistProvider value={therapist ?? undefined}>
          <EditableProvider value={false}>
            <AccountPageContent/>
          </EditableProvider>
        </TherapistProvider>
      </AuthProvider>
    </FormDialog>
  );
}
