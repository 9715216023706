import {Avatar, Badge, ListItem, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material";
import {ChatContact} from "@API/ContactAPI.ts";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {useUnreadMessagesSummary} from "@Components/Providers/ChatProvider.tsx";

export interface ContactViewProps {
  contact: ChatContact;
  onSelect: (contact: ChatContact) => any
}

export const ContactView = ({contact, onSelect}: ContactViewProps) => {
  const name = `${contact.target.firstName} ${contact.target.lastName}`;
  const params = useParams<{ contact: string }>();
  const summary = useUnreadMessagesSummary();
  const unread = summary.bySender[contact.target.id as number] ?? 0;

  useEffect(() => {
    if (params.contact) {
      const numeric = parseInt(params.contact);

      if (contact.id === numeric) {
        onSelect(contact);
      }
    }
  }, [params.contact]);

  const selected = params.contact === contact.id.toString();

  return (
    <ListItem sx={{p: 0}}>
      <ListItemButton selected={selected} onClick={() => onSelect(contact)}>
        <ListItemAvatar>
          <Badge invisible={unread === 0} badgeContent={unread} color="primary">
            <Avatar src={contact.target.avatar?.path} alt={name}/>
          </Badge>
        </ListItemAvatar>
        <ListItemText primary={name}/>
      </ListItemButton>
    </ListItem>
  );
}
