import {InfoBlockItem} from "./Info.ts";
import {Paper} from "@mui/material";
import {styled} from "@mui/system";
import {theme} from "@Util/theme.tsx";
import {Headline} from "@Components/Headline.tsx";

export interface InfoCardProps {
  item: InfoBlockItem;
}

export const InfoCard = ({ item }: InfoCardProps) => {
  return (
    <Paper>
      <SpacingWrapper className={item.direction}>
        <div>
          <img width="100%" src={item.img}/>
        </div>
        <div>
          <Headline text={item.title} level={2}/>
          <p>{item.description}</p>
        </div>
      </SpacingWrapper>
    </Paper>
  )
}

const SpacingWrapper = styled('div')`
  padding: ${theme.spacing(2)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  h2 {
    font-size: 16px;
  }
  
  ${theme.breakpoints.up('sm')} {
    min-height: 600px;
    
    &.column-reversed {
      flex-direction: column-reverse;
    }
  }
`
