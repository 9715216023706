import {RouteObject} from "react-router-dom";
import {MainLayout} from "./Layout/MainLayout.tsx";
import {HomePage} from "./Pages/HomePage/HomePage.tsx";
import {globalDataLoader, GlobalLayout} from "./Layout/GlobalLayout.tsx";
import {AdminRoute} from "./Pages/Admin/route.tsx";
import {AuthRoute} from "./Pages/Auth/route.tsx";
import {AccountRoute} from "./Pages/Account/route.tsx";
import {TherapistsRoute} from "./Pages/Therapists/routes.tsx";
import {CalendarRoute} from "./Pages/Calendar/routes.tsx";
import {MessagesRoute} from "./Pages/Messages/routes.tsx";
import {BecomeAMember} from "./Pages/BecomeAMember/BecomeAMember.tsx";
import {OurMission} from "./Pages/OurMission.tsx";
import {MembershipRoute} from "./Pages/Membership/route.tsx";

export const routes: RouteObject[] = [
  {
    path: '/:locale?',
    loader: globalDataLoader,
    shouldRevalidate: () => false,
    Component: GlobalLayout,
    children: [
      {
        Component: MainLayout,
        children: [
          {
            index: true,
            Component: HomePage
          },
          {
            path: 'for-therapists',
            Component: BecomeAMember,
          },
          {
            path: 'about',
            Component: OurMission,
          },
          AuthRoute,
          AccountRoute,
          MembershipRoute,
          TherapistsRoute,
          MessagesRoute,
          CalendarRoute,
        ],
      },
      AdminRoute,
    ],
  },
];
