import {useFormField} from "@Util/Hooks/FormField.ts";
import {IsEmail} from "@Util/Validation/IsEmail.ts";
import {Required} from "@Util/Validation/Required.ts";
import {Translated} from "@Components/Localization/Translated.tsx";
import {TextField} from "@Components/TextField.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {useUserAPI} from "@API/User.ts";
import {useNotifications} from "@Util/Hooks/Notifications.tsx";
import {useNavigate} from "react-router-dom";

export const ResetPasswordPage = () => {
  const api = useUserAPI();
  const showNotification = useNotifications();
  const navigate = useNavigate();
  const email = useFormField<string>({
    initialValue: '',
    validators: [
      new Required(),
      new IsEmail()
    ],
  });

  const handleSubmit = () => {
    api
      .getResetPasswordLink({
        email: email.value as string,
      })
      .then(() => {
        navigate('sent');
      })
      .catch(() => {
        showNotification({
          variant: 'error',
          autoHideDuration: 5000,
          message: <Translated value="Failed to send an email"/>
        });
      });
  }

  return (
    <>
      <h1><Translated value="Reset password"/></h1>

      <TextField
        label={(
          <Translated value="Email"/>
        )}
        field={email}
        type="email"
      />

      <ActionButton requireValid={[email]} onClick={handleSubmit}>
        <Translated value="Send"/>
      </ActionButton>
    </>
  )
}
