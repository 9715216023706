import {TranslationSection} from "@API/TranslationAPI.ts";
import {useFormSelectField} from "@Util/Hooks/FormSelectField.ts";
import {SelectField} from "@Components/SelectField.tsx";
import {useTransSections} from "./AvailableTransSectionsProvider.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {cloneSearchParams} from "@Util/CloneSearchParams.ts";
import {useSearchParams} from "react-router-dom";

export interface SectionSelectProps {
  onSelected: (section: TranslationSection) => void;
  selected?: TranslationSection;
}

export const SectionSelect = ({selected, onSelected}: SectionSelectProps) => {
  const [params, setSearchParams] = useSearchParams()
  const allSections = useTransSections();

  const field = useFormSelectField({
    initialValue: '',
    options: allSections,
    selected: selected,
    getOptionValue: (s) => s.key,
    getOptionLabel: (s) => {
      const text = s.key.toLowerCase().split(/[_-]/).join(' ');

      return text.slice(0, 1).toUpperCase() + text.slice(1)
    },
  });

  const handleSelected = (section: TranslationSection) => {
    const clone = cloneSearchParams(params, {
      section: section.id,
    });

    setSearchParams(clone, {replace: true});
    onSelected(section);
  }

  return (
    <SelectField
      onSelected={handleSelected}
      field={field}
      label={(
        <Translated value="Section"/>
      )}/>
  );
}
