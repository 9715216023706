import {ConfirmDelete} from "@Components/ConfirmDelete.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {useSearchAwarePath} from "@Util/Hooks/Navigate.tsx";
import {useCertificate} from "./useCertificate.tsx";
import {useCertificateAPI} from "@API/CertificateAPI.ts";
import {TRANSLATIONS} from "@assets/translation.ts";

export const CertificateDeleteDialog = () => {
  const api = useCertificateAPI();
  const cert = useCertificate();

  const handleSubmit = async () => {
    if (!cert) {
      throw new Error('No item');
    }

    await api.delete({id: cert.id});
  };

  const title = <Translated value={TRANSLATIONS.PROFILE.DELETE_CERTIFICATE_TITLE}/>;
  const back = useSearchAwarePath('../../..');

  return (
    <ConfirmDelete title={title} onSubmit={handleSubmit} back={back}/>
  )
}
