import {LoaderFunction} from "react-router-dom";
import {createUniversalAPI} from "@Util/UniversalAPI.ts";
import {Membership, MembershipAPI} from "@API/MembershipAPI.ts";

export type AuthLoaderData = [
  Membership[],
];

export const AuthLoader: LoaderFunction = ({context}): Promise<AuthLoaderData> => {
  const membershipAPI = createUniversalAPI(MembershipAPI, context);

  return Promise.all([
    membershipAPI.list(),
  ]);
}
