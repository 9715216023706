export interface TextProps {
  text: string;
}

export interface AboutTrimmedProps extends TextProps {
  maxLength: number;
}

export const AboutTrimmed = ({text, maxLength}: AboutTrimmedProps) => {
  const trimmed = text.slice(0, maxLength);

  const value = trimmed.length < text.length ? trimmed.concat('...') : text;

  return <span>{value}</span>
}

export const LongText = ({text}: TextProps) => {
  const parts = text
    .split("\n")
    .map(p => p.trim())
    .filter(p => !!p);

  return (
    <>
      {parts.map((part, index) => <p key={index}>{part}</p>)}
    </>
  )
}
