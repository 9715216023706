import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {FCProps} from "@Util/FCProps.ts";
import {useWorksWithAPI, WorksWith} from "@API/WorksWithAPI.ts";
import {useResponseActionInterceptor} from "@Util/Hooks/Http.tsx";
import {Language, useCurrentLanguage} from "@Components/I18nWrapper.tsx";

interface WorksWithTranslation {
  locale: string;
  title?: string;
}

export interface LocalisedWorksWith {
  id: number;
  title: string;
  translation: WorksWithTranslation;
}

const WorksWithContext = createContext<LocalisedWorksWith[]>([]);

export const useLocalisedWorksWith = () => useContext(WorksWithContext);

export const useLocalisedWorksWithItem = (id: string|number|undefined) => {
  if (!id) {
    return undefined;
  }

  const number = parseInt(id.toString());

  return useLocalisedWorksWith().find(item => item.id === number);
}

export interface WorksWithProviderProps extends FCProps {
  language?: Language;
  value?: WorksWith[]
}

export const WorksWithProvider = ({language, value, children}: WorksWithProviderProps) => {
  const currentLanguage = useCurrentLanguage()
  const locale = language?.code ?? currentLanguage?.code ?? null;
  const api = useWorksWithAPI();
  const [updatedElements, setUpdatedElements] = useState<WorksWith[]>();

  const elements = updatedElements ?? value ?? [];

  useEffect(() => {
    if (!value) {
      api.list().then(setUpdatedElements);
    }
  }, [value]);

  useResponseActionInterceptor(/^works_with/, async () => {
    api.list().then(setUpdatedElements);
  }, [setUpdatedElements])

  const localised: LocalisedWorksWith[] = useMemo(() => {
    if (!locale) {
      return [];
    }

    return elements.map((item): LocalisedWorksWith => {
      const i18n = item.i18n.find(translation => translation.locale.code === locale);

      return {
        id: item.id,
        title: item.title,
        translation: {
          title: i18n?.title,
          locale: locale,
        }
      };
    });
  }, [locale, elements]);

  return (
    <WorksWithContext.Provider value={localised}>
      {children}
    </WorksWithContext.Provider>
  );
}
