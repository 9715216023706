import {useCurrentUser} from "@Util/Hooks/Auth.tsx";
import {Outlet, useLoaderData} from "react-router-dom";
import {TransSection} from "@Components/Localization/TransSection.tsx";
import {AccountData, AccountDataProvider} from "./AccountDataProvider.tsx";
import {Helmet} from "react-helmet-async";
import {Translated} from "@Components/Localization/Translated.tsx";
import {AccountPageContent} from "./AccountPageContent.tsx";

export const AccountPage = () => {
  const user = useCurrentUser();
  const data = useLoaderData() as AccountData;

  return (
    <AccountDataProvider data={[user, ...data]}>
      <TransSection section="profile">
        <h1><Translated value="My account"/></h1>

        <AccountPageContent/>
        <Outlet/>
      </TransSection>
      <Helmet>
        <title>Account - {user.firstName} {user.lastName}</title>
        <meta name="description" content="User profile page."/>
      </Helmet>
    </AccountDataProvider>
  )
};
