import {useParams} from "react-router-dom";
import {useLocalisedCities} from "@Components/Providers/LocalisedCityProvider.tsx";
import {useMemo} from "react";

export const useCity = () => {
  const params = useParams<{id: string}>();
  const id = parseInt(params.id as string);

  const list = useLocalisedCities();

  return useMemo(() => {
    return list.find(city => city.id === id);
  }, [id, list]);
}
