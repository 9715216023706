import { API, useAPI } from '@API/API.ts';
import { UserPublicData } from '@Util/Hooks/Auth.tsx';

export const useContactAPI = () => useAPI(ContactAPI);

export interface ChatContact {
  id: number;
  author: UserPublicData;
  target: UserPublicData;
  content: string;
  lastActivityAt: string;
}

export interface ContactRequestCreate {
  target: number;
  message: string;
}

const ENDPOINT = '/api/chat/contact';

export class ContactAPI extends API {
  async create(data: ContactRequestCreate): Promise<ChatContact> {
    return this.http.post(ENDPOINT, data).then(r => r.data);
  }

  async list(): Promise<ChatContact[]> {
    return this.http.get(ENDPOINT).then(r => r.data);
  }

  async delete(id: number) {
    await this.http.delete(ENDPOINT, {data: {id}});
  }
}
