import {useTherapist} from "../TherapistProvider.tsx";
import {useParams} from "react-router-dom";

export const useTherapistEducation = () => {
  const therapist = useTherapist();
  const params = useParams<{id: string}>();
  const id = params.id ? parseInt(params.id) : undefined;

  return therapist.education?.find(e => e.id === id);
}
