import {styled} from "@mui/system";
import {Link as RouterLink, To, useHref, useMatch} from "react-router-dom"
import {theme} from "@Util/theme.tsx";
import {Button, ButtonOwnProps} from "@mui/material";
import {FCProps} from "@Util/FCProps.ts";

export const Link = styled(RouterLink)`
  text-decoration: none;
  color: ${theme.palette.primary.main};
  
  &:hover {
    text-decoration: underline;
  }
`

export interface ButtonLinkProps extends Omit<ButtonOwnProps, 'href'> {
  to: To;
}

export const ButtonLink = (props: ButtonLinkProps) => {
  const {children, to, ...rest} = props;
  const href = useHref(props.to);
  const selected = !!useMatch(href);

  const color = selected ? 'primary' : props.color;

  return (
    <Button {...rest} color={color} href={href}>
      {props.children}
    </Button>
  );
}

interface ExternalLinkProps extends FCProps {
  url: string;
}

export const ExternalLink = (props: ExternalLinkProps) => {
  const text = props.url
    .replace(/^http(s?):\/\//, '')
    .replace(/\/$/, '');

  return <Link to={props.url} target="_blank">{text}</Link>
}
