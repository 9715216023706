import {FCProps} from "@Util/FCProps.ts";
import {Language, useCurrentLanguage} from "@Components/I18nWrapper.tsx";
import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {Topic, useTopicsAPI} from "@API/TopicsAPI.ts";
import {useResponseActionInterceptor} from "@Util/Hooks/Http.tsx";

const TopicsContext = createContext<LocalisedTopic[]>([])

export const useLocalisedTopics = () => useContext(TopicsContext);
export const useLocalisedTopic = (id: number|string|undefined) => {
  if (!id) {
    return undefined;
  }

  const numeric = parseInt(id.toString());

  return useLocalisedTopics().find(item => item.id === numeric);
}

export interface LocalisedTopic {
  id: number;
  title: string;
  translation: {
    locale: string;
    title?: string;
  };
}

export interface TopicsProviderProps extends FCProps {
  value?: Topic[];
  language?: Language;
}

export const TopicsProvider = ({language, value, children}: TopicsProviderProps) => {
  const api = useTopicsAPI();
  const currentLanguage = useCurrentLanguage();
  const locale = language?.code ?? currentLanguage?.code ?? null;
  const [updatedList, setList] = useState<Topic[]>();

  const list = updatedList ?? value ?? [];

  useEffect(() => {
    if (!value) {
      api.list().then(setList);
    }
  }, [locale, value]);

  useResponseActionInterceptor(/^topic/, async () => {
    const items = await api.list();

    setList(items);
  }, [locale]);

  const localised: LocalisedTopic[] = useMemo(() => {
    if (!locale) {
      return [];
    }

    return list.map((item): LocalisedTopic => {
      const translation = item.i18n.find(t => t.locale.code === locale);

      return {
        id: item.id,
        title: item.title,
        translation: {
          locale: locale,
          title: translation?.title,
        },
      };
    })
  }, [list, locale]);

  return (
    <TopicsContext.Provider value={localised}>
      {children}
    </TopicsContext.Provider>
  )
}
