import {Typography} from "@mui/material";
import {EditableInline} from "../Editable.tsx";
import {useCurrentUser} from "@Util/Hooks/Auth.tsx";

export const NameSection = () => {
  const user = useCurrentUser();

  return (
    <EditableInline editPath="name">
      <Typography sx={{fontWeight: 700, fontSize: 24}}>{user.firstName} {user.lastName}</Typography>
    </EditableInline>
  );
}
