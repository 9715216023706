import { API, APIConstructor } from '@API/API.ts';
import axios, { AxiosHeaders } from 'axios';

export interface UniversalContext {
  BASE_URL?: string;
  API_BASE_URL?: string;
  SSR_HEADERS?: AxiosHeaders;
}

export const createUniversalAPI = <T extends API>(constructor: APIConstructor<T>, context?: UniversalContext) => {
  const http = axios.create({
    baseURL: context?.API_BASE_URL,
    headers: context?.SSR_HEADERS,
  });

  return new constructor(http);
}
