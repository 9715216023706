import { API } from '@API/API.ts';

const ENDPOINT = '/api/currency';

export interface Currency {
  id: string;
  symbol: string;
}

export class CurrencyAPI extends API {
  async list(): Promise<Currency[]> {
    return this.http.get(ENDPOINT).then(r => r.data);
  }
}
