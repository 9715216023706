import {LoaderFunction} from "react-router-dom";
import {createUniversalAPI} from "@Util/UniversalAPI.ts";
import {Therapist} from "@API/TherapistAPI.ts";
import {TherapistAdminAPI} from "@API/TherapistAdminAPI.ts";
import {UserApi} from "@API/User.ts";
import {AuthUser} from "@Util/Hooks/Auth.tsx";

export type MemberReviewData = [
  AuthUser,
  Therapist | null,
];

export const memberReviewLoader: LoaderFunction = ({context, params}): Promise<MemberReviewData> => {
  const therapistAdminAPI = createUniversalAPI(TherapistAdminAPI, context);
  const userAPI = createUniversalAPI(UserApi, context);

  const id = parseInt(params['id'] ?? '');

  if (isNaN(id)) {
    throw new Error('Invalid parameter');
  }

  return Promise.all([
    userAPI.details(id),
    therapistAdminAPI.details(id).catch(() => null),
  ]);
}
