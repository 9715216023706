import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {Translated} from "@Components/Localization/Translated.tsx";
import {useSearchAwareNavigate} from "@Util/Hooks/Navigate.tsx";
import {Member} from "@API/MembersAPI.ts";
import {useMembershipOptions} from "@Components/Membership/useMembershipOptions.tsx";
import {ConfirmButton, PreviewButton} from "@Components/Button";

export interface TherapistsTableProps {
  rows?: Member[];
}

export const MembersTable = ({rows}: TherapistsTableProps) => {
  const navigate = useSearchAwareNavigate();
  const membershipOptions = useMembershipOptions();
  const membershipOptionsMap: Record<number, string> = {};

  for (const option of membershipOptions) {
    membershipOptionsMap[option.id] = option.title;
  }

  const previewNavigation = (id: number) => () => navigate(`${id}/preview`);
  const approveNavigation = (id: number) => () => navigate(`${id}/approve`);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell><Translated value="Name"/></TableCell>
          <TableCell><Translated value="Current type"/></TableCell>
          <TableCell><Translated value="Pending type"/></TableCell>
          <TableCell><Translated value="Status"/></TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows?.length
          ? rows.map((m: Member) => (
            <TableRow key={m.user.id}>
              <TableCell>{m.user.id}</TableCell>
              <TableCell>{m.user.firstName} {m.user.lastName}</TableCell>
              <TableCell>
                {m.type ? membershipOptionsMap[m.type.id] : 'N/A'}
              </TableCell>
              <TableCell>
                {m.pendingType ? membershipOptionsMap[m.pendingType.id] : 'N/A'}
              </TableCell>
              <TableCell>
                <Translated value={m.status ?? 'N/A'}/>
              </TableCell>
              <TableCell align="right">
                <ConfirmButton onClick={approveNavigation(m.user.id as number)}/>
                <PreviewButton onClick={previewNavigation(m.user.id as number)}/>
              </TableCell>
            </TableRow>
          ))
          : (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <Translated value="No data to disaplay"/>
              </TableCell>
            </TableRow>
          )
        }
      </TableBody>
    </Table>
  );
}


