import {FormField, useFormField} from "@Util/Hooks/FormField.ts";
import {NumericFormField, useNumericFormField} from "@Util/Hooks/NumericTextField.ts";
import {DataUrlFieldState, useDataUrlField} from "@Components/Image/useDataUrlField.tsx";
import {Certificate} from "@API/CertificateAPI.ts";
import {Required} from "@Util/Validation/Required.ts";
import {TextField} from "@Components/TextField.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {DataUrlImageField} from "@Components/Image/DataUrlImageField.tsx";
import dayjs from "dayjs";
import {Range} from "@Util/Validation/Range.ts";
import {TRANSLATIONS} from "@assets/translation.ts";

export interface CertificateFormState {
  institution: FormField<string>;
  courseName: FormField<string>;
  graduationYear: NumericFormField;
  photo: DataUrlFieldState;
}

export const useCertificateFormState = (certificate?: Certificate): CertificateFormState => {
  const currentYear = dayjs().year();

  return {
    institution: useFormField<string>({
      initialValue: '',
      value: certificate?.institution,
      validators: [
        new Required(),
      ],
    }),
    courseName: useFormField({
      initialValue: '',
      value: certificate?.courseName,
      validators: [
        new Required(),
      ]
    }),
    graduationYear: useNumericFormField({
      initialValue: '',
      value: certificate?.graduationYear.toString(),
      validators: [
        new Range({min: currentYear - 100, max: currentYear}),
      ]
    }),
    photo: useDataUrlField({
      initialValue: '',
      value: certificate?.photo,
      validators: !certificate ? [] : [
        new Required(),
      ],
    })
  };
}

export interface CertificateFormProps {
  state: CertificateFormState;
  allowDelete?: true;
}

export const CertificateForm = ({state}: CertificateFormProps) => {
  return (
    <>
      <TextField
        field={state.institution}
        autoComplete="off"
        label={(
          <Translated value={TRANSLATIONS.PROFILE.INSTITUTION_NAME}/>
        )}
      />
      <TextField
        field={state.courseName}
        autoComplete="off"
        label={(
          <Translated value={TRANSLATIONS.PROFILE.COURSE_NAME}/>
        )}
      />
      <TextField
        field={state.graduationYear}
        autoComplete="off"
        type="number"
        label={(
          <Translated value={TRANSLATIONS.PROFILE.GRADUATION_YEAR}/>
        )}
      />
      <DataUrlImageField
        field={state.photo}
        label={(
          <Translated value={TRANSLATIONS.PROFILE.UPLOAD_PHOTO_OF_CERTIFICATE}/>
        )}
      />
    </>
  )
}
