import {useTherapist} from "../../Account/Therapist/TherapistProvider.tsx";
import {CheckCircle, RemoveCircle, SvgIconComponent} from "@mui/icons-material";
import {HighlightItem} from "./HighlightItem.tsx";
import {SvgIconProps} from "@mui/material";
import {Translated} from "@Components/Localization/Translated.tsx";

export const WorksOnlineView = () => {
  const t = useTherapist();

  const Icon: SvgIconComponent = t.worksOnline ? CheckCircle : RemoveCircle;
  const color: SvgIconProps['color'] = t.worksOnline ? 'success' : 'disabled';

  return (
    <HighlightItem label="Works online" IconComponent={Icon} color={color}>
      <Translated value={t.worksOnline ? 'Yes' : 'No'}/>
    </HighlightItem>
  );
}
