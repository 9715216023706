import {useLocalisedCities} from "@Components/Providers/LocalisedCityProvider.tsx";
import {useTherapist} from "../Account/Therapist/TherapistProvider.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {styled} from "@mui/system";
import {theme} from "@Util/theme.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const TherapyFormat = () => {
  const t = useTherapist();
  const cities = useLocalisedCities()
    .filter(c => t.locations
      ?.map(loc => loc.city.id)
      .includes(c.id)
    )
    .map(c => c.localisedName ?? c.name)
    .join(', ');

  return (
    <Wrapper>
      <div>
        {t.worksOnline && (
          <span><Translated value={TRANSLATIONS.PROFILE.ONLINE}/></span>
        )}
        {cities.length > 0 && (
          <span><Translated value={TRANSLATIONS.PROFILE.IN_PERSON}/></span>
        )}
      </div>

      {cities.length > 0 && (
        <div>{cities}</div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  color: ${theme.palette.text.secondary};

  span:not(:last-child):after {
    content: ', ';
  }
`
