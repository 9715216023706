import LogoIcon from "@assets/logo.svg?react";
import {useCurrentLanguage} from "@Components/I18nWrapper.tsx";
import {Link} from "react-router-dom";
import {styled} from "@mui/system";

export const Logo = () => {
  const language = useCurrentLanguage();
  const to = `/${language.code}`;

  return (
    <StyledLink to={to}>
      <LogoIcon/>
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  color: inherit;
  
  svg {
    display: block;
    width: 134px;
    height: 50px;
  }
`

