import {useLocalisedWorksWith} from "@Components/Providers/WorksWithProvider.tsx";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {EditButton} from "@Components/Button/EditButton.tsx";
import {DeleteButton} from "@Components/Button/DeleteButton.tsx";
import {useSearchAwareNavigate} from "@Util/Hooks/Navigate.tsx";

export const WorksWithTable = () => {
  const items = useLocalisedWorksWith();
  const navigate = useSearchAwareNavigate();

  const showEditDialog = (id: number) => navigate(`${id}/update`);
  const showDeleteDialog = (id: number) => navigate(`${id}/delete`);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Locale</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Localised title</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(item => (
            <TableRow key={item.id}>
              <TableCell>{item.id}</TableCell>
              <TableCell>{item.translation.locale}</TableCell>
              <TableCell>{item.title}</TableCell>
              <TableCell>{item.translation.title ?? 'N/A'}</TableCell>
              <TableCell align="right">
                <EditButton onClick={() => showEditDialog(item.id)}/>
                <DeleteButton onClick={() => showDeleteDialog(item.id)}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}
