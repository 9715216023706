import {createContext, useContext, useEffect, useState} from "react";
import {Therapist, useTherapistAPI} from "@API/TherapistAPI.ts";
import {FCProps} from "@Util/FCProps.ts";
import {useResponseActionInterceptor} from "@Util/Hooks/Http.tsx";
import {useCurrentUser} from "@Util/Hooks/Auth.tsx";
import {TherapyLocation, TherapyLocationPK} from "@API/LocationsAPI.ts";

const TherapistContext = createContext<Therapist>({
  id: 0,
});

export const useTherapist = () => useContext(TherapistContext);

export interface TherapistProviderProps extends FCProps {
  value?: Therapist;
}

export const TherapistProvider = ({children, value}: TherapistProviderProps) => {
  const api = useTherapistAPI();
  const user = useCurrentUser();

  const [updatedTherapist, setUpdatedTherapist] = useState<Therapist>();

  const therapist = updatedTherapist ?? value ?? {id: 0};

  useEffect(() => {
    if (!value && user.id) {
      api.current().then(setUpdatedTherapist);
    }
  }, [user.id]);

  useResponseActionInterceptor(/^therapist$/, async (response) => {
    const updated = response.data as Therapist;

    if (updated?.user?.id && updated.user.id === therapist?.user?.id) {
      setUpdatedTherapist(updated);
    }
  }, [therapist ?? null]);

  useResponseActionInterceptor(/^education/, async () => {
    await api.current().then(setUpdatedTherapist);
  }, [updatedTherapist]);

  useResponseActionInterceptor(/^certificate/, async () => {
    await api.current().then(setUpdatedTherapist);
  }, [therapist]);

  useResponseActionInterceptor(/^link/, async () => {
    await api.current().then(setUpdatedTherapist);
  }, [therapist]);

  useResponseActionInterceptor(/^location$/, async (response) => {
    const updated = response.data as TherapyLocation;

    if (updated.therapist.id == therapist.id) {
      await api.current().then(setUpdatedTherapist);
    }
  },[therapist ?? null]);

  useResponseActionInterceptor(/^location_deleted$/, async (response) => {
    const deleted = response.data as TherapyLocationPK;
    const exist = therapist.locations?.find(l => l.id === deleted.id);

    if (exist) {
      await api.current().then(setUpdatedTherapist);
    }
  },[therapist ?? null]);

  return (
    <TherapistContext.Provider value={therapist}>
      {children}
    </TherapistContext.Provider>
  )
}
