export const TRANSLATIONS = {
  AUTH: {
    FIRST_NAME: 'label_first_name',
    LAST_NAME: 'label_last_name',
    EMAIL: 'label_email',
    PASSWORD: 'label_password',
    REGISTER: 'Register',
    HAVE_ACCOUNT_ALREADY: 'Have an account already?'
  },
  HOME: {
    TOP_PANELS: [
      {
        HEADING: 'top_panel_1_heading',
        SUB_HEADING: 'top_panel_1_sub_heading',
        CONTENT: 'top_panel_1_content'
      },
      {
        HEADING: 'top_panel_2_heading',
        SUB_HEADING: 'top_panel_2_sub_heading',
        CONTENT: 'top_panel_2_content'
      }
    ],
    GESTALT: [
      {
        HEADING: 'gestalt_1_heading',
        BULLETS: [
          'gestalt_1_bullet_1',
          'gestalt_1_bullet_2',
          'gestalt_1_bullet_3',
          'gestalt_1_bullet_4',
          'gestalt_1_bullet_5',
          'gestalt_1_bullet_6',
          'gestalt_1_bullet_7',
        ]
      },
      {
        HEADING: 'gestalt_2_heading',
        BULLETS: [
          'gestalt_2_bullet_1',
          'gestalt_2_bullet_2',
          'gestalt_2_bullet_3',
          'gestalt_2_bullet_4',
          'gestalt_2_bullet_5',
          'gestalt_2_bullet_6',
          'gestalt_2_bullet_7',
        ]
      },
      {
        HEADING: 'gestalt_3_heading',
        BULLETS: [
          'gestalt_3_bullet_1',
          'gestalt_3_bullet_2',
          'gestalt_3_bullet_3',
          'gestalt_3_bullet_4',
          'gestalt_3_bullet_5',
          'gestalt_3_bullet_6',
          'gestalt_3_bullet_7',
        ]
      }
    ],
    META: {
      TITLE: 'Professional psychological support',
      DESCRIPTION: 'About gestalt, professionally.',
    }
  },
  PROFILE: {
    NOT_FILLED: 'not_filled',
    SEARCH: 'label_search',
    FIRST_NAME: 'label_first_name',
    LAST_NAME: 'label_last_name',
    MEMBERSHIP_TYPE: 'label_membership_type',
    AGE: 'label_age',
    DATE_OF_BIRTH: 'date_of_birth',
    PRICE_FREE: 'price_free',
    SEX: 'label_sex',
    YEARS_OF_EXPERIENCE: 'label_years_of_experience',
    PRICE: 'label_price',
    CURRENCY: 'label_currency',
    SET_PRICE: 'set_price',
    WORK_ONLINE: 'label_work_online',
    I_WORK_ONLINE: 'i_work_online',
    ONLINE: 'label_online',
    IN_PERSON: 'label_in_person',
    LOCATIONS: 'label_locations',
    ADD_LOCATION: 'add_location',
    COUNTRY: 'label_country',
    CITY: 'label_city',
    STATUS: 'label_status',
    THERAPY_LANGUAGES: 'label_therapy_languages',
    LANGUAGES: 'label_languages',
    I_WORK_WITH: 'i_work_with',
    WORKS_WITH: 'label_works_with',
    TOPICS_AND_PROBLEMS: 'label_topics_and_problems',
    TOPICS: 'label_topics',
    TOPIC: 'label_topic',
    ERROR_TOPIC_MISMATCH: 'error_topic_mismatch',
    ALREADY_CONTACTED: 'already_contacted',
    GO_TO_MESSAGES: 'go_to_messages',
    EDUCATION: 'label_education',
    ADD_EDUCATION_TITLE: 'add_education_title',
    UPDATE_EDUCATION_TITLE: 'update_education_title',
    DELETE_EDUCATION_TITLE: 'delete_education_title',
    QUALIFICATION: 'label_qualification',
    INSTITUTION_NAME: 'label_institution_name',
    SPECIALIZATION: 'label_specialization',
    GRADUATION_YEAR: 'label_graduation_year',
    UPLOAD_PHOTO_OF_DIPLOMA: 'label_upload_photo_of_diploma',
    CERTIFICATES: 'label_certificates',
    ADD_CERTIFICATE_TITLE: 'add_certificate_title',
    UPDATE_CERTIFICATE_TITLE: 'update_certificate_title',
    DELETE_CERTIFICATE_TITLE: 'delete_certificate_title',
    COURSE_NAME: 'label_course_name',
    UPLOAD_PHOTO_OF_CERTIFICATE: 'label_upload_photo_of_certificate',
    SOCIAL_NETWORKS_LINK: 'label_social_networks_link',
    SOCIAL_NETWORKS_LINKS: 'label_social_networks_links',
    DELETE_THIS_LINK: 'delete_this_link',
    ABOUT_ME: 'label_about_me',
    ABOUT_ME_SHORT: 'label_about_me_short',
    ABOUT_ME_LONGER: 'label_about_me_longer',
    ABOUT_ME_SHORT_PLACEHOLDER: 'placeholder_about_me_short',
    ABOUT_ME_LONGER_PLACEHOLDER: 'placeholder_about_me_longer',
    BTN_CONTACT_THERAPIST: 'btn_contact_therapist',
    BTN_SHOW_RESULTS: 'btn_show_results',
    TITLE_CONTACT_THERAPIST: 'title_contact_therapist',
    MESSAGE: 'label_message',
  },
  CANT_BE_UNDONE: 'cant_be_undone',
  SURE_WANT_TO_PROCEED: 'sure_want_to_proceed',
  BECOME_MEMBER: {
    TITLE: 'title_become_a_member',
    SUB_TITLE: 'sub_title_become_a_member',
    EXPLANATION_1: 'explanation_1',
    EXPLANATION_2: 'explanation_2',
    NO_EXTRA_CHARGES: 'no_extra_charges',
  },
  BTN: {
    BECOME_MEMBER: 'btn_become_member',
    FIND_THERAPIST: 'btn_find_therapist'
  }
};
