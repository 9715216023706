import {LocalisedQualification, useLocalisedQualifications} from "@Components/Providers/QualificationProvider.tsx";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {useSearchAwareNavigate} from "@Util/Hooks/Navigate.tsx";
import {EditButton} from "@Components/Button/EditButton.tsx";
import {DeleteButton} from "@Components/Button/DeleteButton.tsx";

export const QualificationTable = () => {
  const items = useLocalisedQualifications();
  const navigate = useSearchAwareNavigate();

  const showEditDialog = (id: number) => navigate(`${id}/update`);
  const showDeleteDialog = (id: number) => navigate(`${id}/delete`);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Locale</TableCell>
          <TableCell>Title (source)</TableCell>
          <TableCell>Title (localised)</TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item: LocalisedQualification) => (
          <TableRow key={item.id}>
            <TableCell>{item.id}</TableCell>
            <TableCell>{item.locale}</TableCell>
            <TableCell>{item.title}</TableCell>
            <TableCell>{item.localised.title ?? 'N/A'}</TableCell>
            <TableCell align="right">
              <EditButton onClick={() => showEditDialog(item.id)}/>
              <DeleteButton onClick={() => showDeleteDialog(item.id)}/>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
