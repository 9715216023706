import { useCurrentLanguage } from '@Components/I18nWrapper.tsx';
import { useMemo } from 'react';

type Direction = 'column' | 'column-reversed';

interface TextData {
  title: string
  description: string;
}

interface InfoBlockData {
  img: string;
  direction: Direction;
  i18n: Record<string, TextData>;
}

export interface InfoBlockItem extends TextData {
  img: string;
  direction: Direction;
}

const INFO_BLOCKS: InfoBlockData[] = [
  {
    img: '/for-therapists/1.png',
    direction: 'column',
    i18n: {
      en: {
        title: 'Start/expand your own practice',
        description: 'ProGestalt will help you attract new motivated clients, expand your professional horizons and open the door to working with a diverse audience.',
      },
      ua: {
        title: 'Початок/розширення власної практики',
        description: `ProGestalt допоможе вам залучити нових мотивованих клієнтів, розширити ваші професійні горизонти та відкрити двері для роботи з різноманітною аудиторією.`
      },
      ru: {
        title: 'Начало/расширение собственной практики',
        description: `ProGestalt поможет вам привлечь новых целевых клиентов, расширить ваши профессиональные горизонты и открыть двери для работы с разнообразной аудиторией.`
      },
    },
  },
  {
    img: '/for-therapists/2.png',
    direction: 'column-reversed',
    i18n: {
      en: {
        title: 'Protection and security',
        description: 'ProGestalt strictly monitors the accuracy of information and compliance with platform rules to ensure a safe and professional environment for all participants. Users who do not follow the rules of the platform will be blocked, which guarantees high standards of quality and ethics.',
      },
      ua: {
        title: 'Захист та безпека',
        description: `ProGestalt суворо конролює достовірність інформації та дотримання правил платформи, щоб забезпечити безпечне і професійне середовище для всіх учасників. Користувачі, які не дотримуватимуться правил пратформи, будуть заблоковані, що гарантує високі стандарти якості та етики.`
      },
      ru: {
        title: 'Защита и безопасность',
        description: `ProGestalt строго контролирует достоверность информации и соблюдение правил платформы для обеспечения безопасной и профессиональной среды для всех участников. Пользователи, не соблюдающие правила пратформы, будут заблокированы, что гарантирует высокие стандарты качества и этики.`,
      }
    },
  },
  {
    img: '/for-therapists/3.png',
    direction: 'column',
    i18n: {
      en: {
        title: 'Like-minded community',
        description: 'You will be part of an active professional community of Gestalt therapists ProGestalt, where you can exchange experiences, receive support and inspiration from colleagues.',
      },
      ua: {
        title: 'Спільнота однодумців',
        description: `Ви станете частиною активної професійної спільноти гештальт терапевтів ProGestalt, де зможете обмінюватись досвідом, отримувати підтримку та натхнення від колег.`,
      },
      ru: {
        title: 'Сообщество единомышленников',
        description: `Вы станете частью активного профессионального сообщества гештальт терапевтов ProGestalt, где сможете обмениваться опытом, получать поддержку и вдохновение от коллег.`,
      }
    },
  },
  {
    img: '/for-therapists/4.png',
    direction: 'column',
    i18n: {
      en: {
        title: 'Qualified supervision',
        description: 'Get professional support and feedback from experienced supervisors to help you improve your practice and solve challenging cases.',
      },
      ua: {
        title: 'Кваліфікована супевізія',
        description: `Отримайте професійну підтримку та зворотній зв’язок від досвідчених супервізорів, що допоможе вам вдосконалювати свою практику та вирішувати складні випадки.`
      },
      ru: {
        title: 'Квалифицированная супевизия',
        description: `Получите профессиональную поддержку и обратную связь от опытных супервизоров, что поможет вам усовершенствовать свою практику и решать сложные случаи.`
      },
    },
  },
  {
    img: '/for-therapists/5.png',
    direction: 'column-reversed',
    i18n: {
      en: {
        title: 'Effectiveness research',
        description: 'It’s a unique opportunity to take part in a longitudinal study of the effectiveness of Gestalt therapy in working with different categories of clients to finally resolve the issue of evidence of our method. ProGestalt will allow you to improve your methods and contribute to the development of therapeutic science.',
      },
      ua: {
        title: 'Дослідження ефективності',
        description: `Унікальна можливість взяти участь у лонгітюдному дослідженні ефективності гештальт-терапії в роботі з різними категорями клієнтів щоб нарешті вирішити питання доказовості нашого методу. ProGestalt дозволить вам покращити свої методи, та зробити свій внесок у розвиток терапевтичної науки.`,
      },
      ru: {
        title: 'Исследование эффективности',
        description: `Уникальная возможность принять участие в лонгитюдном исследовании эффективности гештальт-терапии в работе с разными категориями клиентов, чтобы наконец решить вопрос доказательности нашего метода. ProGestalt позволит вам улучшить свои методы и внести свой вклад в развитие терапевтической науки.`,
      }
    },
  },
  {
    img: '/for-therapists/6.png',
    direction: 'column',
    i18n: {
      en: {
        title: 'Up-to-date convenience',
        description: 'Use ProGestalt\'s user-friendly interface to organize sessions and communicate with clients. Refer your acquaintances, relatives, colleagues who need psychotherapeutic help without additional effort and responsibility for a personal recommendation.',
      },
      ua: {
        title: 'Зручність і сучасність',
        description: `Використовуйте зручний інтерфейс ProGestalt для організації сессій та комунікацій з клієнтами. Направляйте своїх знайомих, родичів, колег що потребують психотерапевтичної допомоги без додаткових зусиль та відповідальності за особисту рекомендацію.`
      },
      ru: {
        title: 'Удобство и современность',
        description: `Используйте удобный интерфейс ProGestalt для организации сессий и коммуникаций с клиентами. Направляйте своих знакомых, родственников, коллег, нуждающихся в психотерапевтической помощи без дополнительных усилий и ответственности за личную рекомендацию.`,
      }
    },
  },
];

export const useInfoBlocks = (): InfoBlockItem[] => {
  const language = useCurrentLanguage();

  return useMemo(() => {
    return INFO_BLOCKS.map(block => {
      const i18n = block.i18n[language.code] ?? block.i18n['en'];

      return {
        img: block.img,
        direction: block.direction,
        ...i18n,
      };
    })
  }, [language.code]);
}
