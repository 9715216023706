import {RouteObject} from "react-router-dom";
import {AuthPage} from "./AuthPage.tsx";
import {Login} from "./Login.tsx";
import {Register} from "./Register.tsx";
import {AuthLoader} from "./AuthLoader.tsx";
import {SetPasswordPage} from "./SetPasswordPage.tsx";
import {ResetPasswordPage} from "./ResetPasswordPage.tsx";
import {ResetPasswordSuccessPage} from "./ResetPasswordSuccessPage.tsx";
import {ResetPasswordContainer} from "./ResetPasswordContainer.tsx";

export const AuthRoute: RouteObject =  {
  children: [
    {
      path: 'auth',
      loader: AuthLoader,
      Component: AuthPage,
      children: [
        {
          path: 'log-in',
          Component: Login,
        },
        {
          path: 'register',
          Component: Register,
        }
      ]
    },
    {
      path: 'password',
      Component: ResetPasswordContainer,
      children: [
        {
          index: true,
          Component: ResetPasswordPage,
        },
        {
          path: 'sent',
          Component: ResetPasswordSuccessPage,
        },
        {
          path: ':id/:token',
          Component: SetPasswordPage,
        },
      ],
    },
  ]
};
