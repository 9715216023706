import {useParams} from "react-router-dom";
import {useLocalisedQualifications} from "@Components/Providers/QualificationProvider.tsx";

export const useQualification = () => {
  const params = useParams<{id: string}>();
  const qualifications = useLocalisedQualifications();

  if (!params.id) {
    return undefined;
  }

  const numeric = parseInt(params.id);

  return qualifications.find(q => q.id === numeric);
}
