import {FormEvent} from "react";

type HandlerCallback = () => Promise<any>

export const useSubmitHandler = (callback: HandlerCallback) => {
  return async (event: FormEvent) => {
    event.preventDefault();

    await callback();
  }
}
