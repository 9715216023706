import {useTherapist} from "../../Pages/Account/Therapist/TherapistProvider.tsx";
import {ReactNode, useState} from "react";
import {styled} from "@mui/system";
import {Collapse} from "@mui/material";
import {ChevronRight} from "@mui/icons-material";
import {theme} from "@Util/theme.tsx";
import {Education} from "@API/EducationAPI.ts";
import {useLocalisedQualifications} from "@Components/Providers/QualificationProvider.tsx";

type EducationItem = {
  key: string;
  heading: ReactNode;
  photo: ReactNode;
}

export const AllEducationView = () => {
  const t = useTherapist();
  const [expanded, setExpanded] = useState<string | null>(null);
  const toggleExpanded = (next: string) => {
    setExpanded(current => {
      if (current === next) {
        return null;
      } else {
        return next;
      }
    });
  }
  const all: EducationItem[] = [];

  for (const item of t.education ?? []) {
    all.push({
      key: `education_${item.id}`,
      heading: <EducationSummary {...item} />,
      photo: <StyledImg src={item.photo} alt={item.speciality}/>,
    });
  }

  for (const item of t.certificates ?? []) {
    all.push({
      key: `certificate_${item.id}`,
      heading: `${item.graduationYear}, ${item.institution}, ${item.courseName}`,
      photo: <StyledImg src={item.photo} alt={item.courseName}/>,
    })
  }

  return (
    <Wrapper>
      {all.map(({key, heading, photo }) => (
        <div key={key}>
          <ClickableHeader onClick={() => toggleExpanded(key)}>
            {heading}
            <ChevronRight className={key === expanded ? 'rotated' : 'normal'}/>
          </ClickableHeader>
          <Collapse in={key === expanded}>
            {photo}
          </Collapse>
        </div>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
  margin: ${theme.spacing(1)} 0;
  
  svg {
    color: #737373;
    transition: transform 200ms;
    &.rotated {
      transform: rotate(90deg);
    }
  }
`

const ClickableHeader = styled('div')`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledImg = styled('img')`
  width: 100%;
  margin: ${theme.spacing(2)} 0;
`

export const EducationSummary = ({institution, graduationYear, speciality, qualification}: Education) => {
  const qualifications = useLocalisedQualifications();
  const localised = qualifications.find(q => q.id === qualification.id);
  const qLabel = localised?.localised.title ?? localised?.title;

  return (
    <>{graduationYear}, {institution}, {qLabel}, {speciality}</>
  )
}
