import {useWebSocket} from "@Components/Providers/WebsocketProvider.tsx";
import {FCProps} from "@Util/FCProps.ts";
import {useEffect, useState} from "react";
import {useNotifications} from "@Util/Hooks/Notifications.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {Button} from "@mui/material";

export const CheckVersion = ({children}: FCProps) => {
  const [version, setVersion] = useState<string>();
  const ws = useWebSocket();
  const showNotification = useNotifications();

  useEffect(() => {
    const checkVersion = (nextVersion: string) => {
      if (!version || version === nextVersion) {
        setVersion(nextVersion);

        return;
      }

      const handleUpdate = () => window.location.reload();

      showNotification({
        variant: 'info',
        key: 'version_check',
        preventDuplicate: true,
        autoHideDuration: null,
        message: <>
          <Translated value="You are using outdated version."/>
          <Button sx={{ml: 2}} color="inherit" variant="text" onClick={handleUpdate}>
            <Translated value="Update"/>
          </Button>
        </>
      });
    }

    const checkInterval = setInterval(() => ws.emit('version'), 5000);
    ws.on('version', checkVersion);

    return () => {
      ws.off('version', checkVersion);
      clearInterval(checkInterval);
    }
  }, [version])

  return <>{children}</>
}
