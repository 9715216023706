import {FormDialog} from "@Components/FormDialog.tsx";
import {EducationForm, useEducationFormState} from "./EducationForm.tsx";
import {useEducationAPI} from "@API/EducationAPI.ts";
import {useSearchAwarePath} from "@Util/Hooks/Navigate.tsx";
import {useTranslated} from "@Components/Localization/Translated.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const EducationAddDialog = () => {
  const api = useEducationAPI();
  const fields = useEducationFormState();
  const back = useSearchAwarePath('../..');

  const handleSubmit = async () => {
    await api.add({
      qualification: fields.qualification.value as number,
      institution: fields.institutionName.value as string,
      speciality: fields.speciality.value as string,
      graduationYear: fields.graduatedAt.getNumericValue() as number,
      photo: fields.photo.value as string
    });
  }

  const title = useTranslated(TRANSLATIONS.PROFILE.ADD_EDUCATION_TITLE);

  return (
    <FormDialog title={title} onSubmit={handleSubmit} back={back}>
      <EducationForm fields={fields}/>
    </FormDialog>
  );
}
