import {useCurrentUser} from "@Util/Hooks/Auth.tsx";
import {SexLocalized, useLocalisedSex} from "@Components/Providers/SexProvider.tsx";
import {FormDialog} from "@Components/FormDialog.tsx";
import {useUserAPI} from "@API/User.ts";
import {useFormSelectField} from "@Util/Hooks/FormSelectField.ts";
import {SexEnum} from "@API/SexAPI.ts";
import {SelectField} from "@Components/SelectField.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const SexDialog = () => {
  const user = useCurrentUser();
  const api = useUserAPI();
  const sexOptions = useLocalisedSex();

  const sexField = useFormSelectField<SexLocalized>({
    initialValue: user.sex?.id ?? '',
    options: sexOptions,
    getOptionValue: (o) => o.id,
    getOptionLabel: (o) => o.title,
  });

  const onSubmit = async () => {
    await api.update({
      id: user.id as number,
      sex: sexField.selected?.id as SexEnum,
    });
  };

  return (
    <FormDialog title={(<Translated value={TRANSLATIONS.PROFILE.SEX}/>)} onSubmit={onSubmit}>
      <SelectField
        field={sexField}
        label={(
          <Translated value={TRANSLATIONS.PROFILE.SEX}/>
        )}
      />

      <ActionButton/>
    </FormDialog>
  )
}
