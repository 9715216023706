import {useSearchAwarePath} from "@Util/Hooks/Navigate.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {useTopicsAPI} from "@API/TopicsAPI.ts";
import {useParams} from "react-router-dom";
import {useLocalisedTopic} from "@Components/Providers/TopicsProvider.tsx";
import {ConfirmDelete} from "@Components/ConfirmDelete.tsx";

export const TopicDeleteDialog = () => {
  const api = useTopicsAPI();
  const back = useSearchAwarePath('../..');
  const params = useParams<{id: string}>();
  const topic = useLocalisedTopic(params.id);

  const handleSubmit = async () => {
    if (!topic) {
      throw new Error('No topic');
    }
    await api.delete({id: topic.id});
  };

  return (
    <ConfirmDelete title={<Translated value="Topic delete"/>} onSubmit={handleSubmit} back={back}/>
  )
}
