import {HighlightItem} from "./HighlightItem.tsx";
import {PeopleRounded} from "@mui/icons-material";
import {useLocalisedWorksWith} from "@Components/Providers/WorksWithProvider.tsx";
import {useTherapist} from "../../Account/Therapist/TherapistProvider.tsx";
import {SvgIconProps} from "@mui/material";
import {TRANSLATIONS} from "@assets/translation.ts";

export interface WorksWithViewProps {
  preventInline?: boolean;
  color?: SvgIconProps['color'];
}

export const WorksWithView = ({color, preventInline}: WorksWithViewProps) => {
  const t = useTherapist();
  const worksWith = useLocalisedWorksWith()
    .filter(item => {
      return t.worksWith?.map(ww => ww.id).includes(item.id);
    })
    .map(item => item.translation.title ?? item.title)
    .join(', ');

  if (worksWith.length === 0) {
    return '';
  }

  return (
    <HighlightItem IconComponent={PeopleRounded} label={TRANSLATIONS.PROFILE.WORKS_WITH} preventInline={preventInline} color={color}>
      {worksWith}
    </HighlightItem>
  );
}
