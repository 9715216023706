import {Translated} from "@Components/Localization/Translated.tsx";

export const OurMission = () => {

  return (
    <h1>
      <Translated value="About us"/>
    </h1>
  )
}
