import {ButtonLink} from "@Components/Link.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {usePath} from "@Util/Hooks/Navigate.tsx";
import {styled} from "@mui/system";
import {theme} from "@Util/theme.tsx";
import {Paper} from "@mui/material";
import {MembershipOption} from "@Components/Membership/useMembershipOptions.tsx";

export interface MembershipCardProps {
  membership: MembershipOption;
}

export const MembershipCard = ({membership}: MembershipCardProps) => {
  const registerPath = usePath({
    pathname: '/auth/register',
    search: `membership=${membership.id}`,
  });

  return (
    <StyledPanel>
      <h3>{membership.title}</h3>
      <PriceBox>
        <PriceValue>
          <PriceCurrency>{membership.currency.symbol}</PriceCurrency>
          <PriceAmount>{membership.price}</PriceAmount>
        </PriceValue>
        <div>
          <Translated value="per month"/>
        </div>
      </PriceBox>

      <ButtonLink to={registerPath}>
        <Translated value="Start"/>
      </ButtonLink>
    </StyledPanel>
  );
}

const StyledPanel = styled(Paper)`
  padding: ${theme.spacing(2)};
  text-align: center;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  h3 {
    font-size: 16px;
  }
`

const PriceBox = styled('div')`
    
`

const PriceCurrency = styled('span')`
  margin-right: 10px;
`

const PriceValue = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

const PriceAmount = styled('span')`
  font-size: 90px;
  line-height: 70px;
  font-weight: lighter;
  color: ${theme.palette.primary.main};
`
