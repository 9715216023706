import {AuthUser} from "@Util/Hooks/Auth.tsx";
import {FormDialog} from "@Components/FormDialog.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {FC} from "react";

export interface ClientPreviewProps {
  user: AuthUser;
}
export const ClientPreview: FC<ClientPreviewProps> = () => {

  const handleSubmit = async () => {

  };

  return (
    <FormDialog title="Review" onSubmit={handleSubmit}>
      <ActionButton/>
    </FormDialog>
  )
}
