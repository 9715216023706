import {RouteObject} from "react-router-dom";
import {MessagesPage} from "./MessagesPage.tsx";

export const MessagesRoute: RouteObject = {
  path: 'messages',
  children: [
    {
      path: ':contact?',
      Component: MessagesPage,
    }
  ],
};
