import {Outlet, useLoaderData} from "react-router-dom";
import {Therapist} from "@API/TherapistAPI.ts";
import {Box, Card, Grid} from "@mui/material";
import {TherapistProvider} from "../../Account/Therapist/TherapistProvider.tsx";
import {useCurrentUser} from "@Util/Hooks/Auth.tsx";
import {TherapistContactButton} from "./TherapistContactButton.tsx";
import {styled} from "@mui/system";
import {WorksWithView} from "../Partial/WorksWithView.tsx";
import {LanguagesView} from "../Partial/LanguagesView.tsx";
import {LongText} from "../Partial/AboutTrimmed.tsx";
import {TopicsListView} from "../Partial/TopicsView.tsx";
import {HighlightItem} from "../Partial/HighlightItem.tsx";
import {ArticleOutlined, PhotoCameraFront, School} from "@mui/icons-material";
import {ProfessionalLinksView} from "./ProfessionalLinksView.tsx";
import {Heading} from "../Search/TherapistPreview.tsx";
import {MembershipView} from "../Partial/MembershipView.tsx";
import {theme} from "@Util/theme.tsx";
import {AllEducationView} from "@Components/Therapist/AllEducationView.tsx";
import {YearsOfExperienceView} from "../Partial/YearsOfExperienceView.tsx";
import {AgeView} from "../Partial/AgeView.tsx";
import {PriceView} from "../Partial/PriceView.tsx";
import {WorksOnlineView} from "../Partial/WorksOnlineView.tsx";
import {LocationsView} from "../Partial/LocationsView.tsx";
import {TransSection} from "@Components/Localization/TransSection.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const TherapistDetailsPage = () => {
  const user = useCurrentUser();
  const t = useLoaderData() as Therapist;
  const isOwner = t.user?.id === user.id;

  return (
    <TransSection section="profile">
      <TherapistProvider value={t}>
        <Grid container spacing={2} direction="row" mt={2}>
          <Grid item xs={12} md={4}>
            <StickyCard>
              <Box>
                <StyledAvatar src={t.avatar}/>
                <Box p={2}>
                  <Box mb={2}>
                    <Heading>{t.name}</Heading>
                    <MembershipView/>
                  </Box>
                  <YearsOfExperienceView/>
                  <AgeView/>
                  <WorksOnlineView/>
                  <LocationsView/>
                  <PriceView/>
                </Box>
              </Box>
              {!isOwner && (
                <Box p={2} display="flex" justifyContent="space-around">
                  <TherapistContactButton/>
                </Box>
              )}
            </StickyCard>
          </Grid>
          <Grid item xs={12} md={8} display="flex" flexDirection="column" gap={2}>
            <Card>
              <Box p={2}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <WorksWithView preventInline={true}/>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LanguagesView preventInline={true}/>
                  </Grid>
                </Grid>
              </Box>
            </Card>
            <Card>
              <Box p={2} width="100%">
                <TopicsListView/>
              </Box>
            </Card>
            <Card>
              <Box p={2}>
                <HighlightItem label={TRANSLATIONS.PROFILE.EDUCATION} IconComponent={School}>
                  <AllEducationView/>
                </HighlightItem>
              </Box>
            </Card>
            <Card>
              <Box p={2}>
                <ProfessionalLinksView/>
              </Box>
            </Card>
            <Card>
              <Box p={2}>
                {t.video && (
                  <Box>
                    <HighlightItem label="Video" IconComponent={PhotoCameraFront}/>
                  </Box>
                )}
                <Box>
                  <HighlightItem label={TRANSLATIONS.PROFILE.ABOUT_ME} IconComponent={ArticleOutlined}>
                    {t.summary && <LongText text={t.summary}/>}
                    {t.about && <LongText text={t.about}/>}
                  </HighlightItem>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
        <Outlet/>
      </TherapistProvider>
    </TransSection>
  );
}

const StyledAvatar = styled('img')`
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
`

const StickyCard = styled(Card)`
  position: sticky;
  top: ${theme.spacing(2)};
`
