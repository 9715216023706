import { API, useAPI } from '@API/API.ts';
import { CityPK } from '@API/CityAPI.tsx';
import { CountryPK } from '@API/CountryAPI.ts';
import { TherapistPK } from '@API/TherapistAPI.ts';

export interface TherapyLocationPK {
  id: number;
}

export interface TherapyLocation extends TherapyLocationPK {
  city: CityPK;
  country: CountryPK;
  therapist: TherapistPK;
}

export const useLocationsAPI = () => useAPI(LocationsAPI);

const ENDPOINT = '/api/location';

export class LocationsAPI extends API {
  async create(city: number): Promise<TherapyLocation> {
    return this.http.post(ENDPOINT, {city}).then(r => r.data);
  }

  async update(id: number, city: number): Promise<TherapyLocation> {
    return this.http.put(ENDPOINT, {id, city}).then(r => r.data);
  }

  async delete(id: number): Promise<void> {
    await this.http.delete(ENDPOINT, {
      data: {id},
    });
  }
}
