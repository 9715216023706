export const cloneSearchParams = (params: URLSearchParams, patch?: Record<string, number|string>) => {
  const newParams = new URLSearchParams();

  for (const key of params.keys()) {
    newParams.set(key, params.get(key) as string);
  }

  if (patch) {
    for (const key in patch) {
      newParams.set(key, patch[key].toString());
    }
  }

  return newParams;
}
