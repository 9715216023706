import {useCurrencyList} from "@Components/Providers/CurrencyProvider.tsx";
import {useFormSelectField} from "@Util/Hooks/FormSelectField.ts";
import {useTherapist} from "../TherapistProvider.tsx";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {Currency} from "@API/CurrencyAPI.ts";
import {FormDialog} from "@Components/FormDialog.tsx";
import {TextField} from "@Components/TextField.tsx";
import {SelectField} from "@Components/SelectField.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {useTherapistAPI} from "@API/TherapistAPI.ts";
import {Translated} from "@Components/Localization/Translated.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const SetPriceDialog = () => {
  const t = useTherapist();
  const api = useTherapistAPI();
  const currencyList = useCurrencyList();

  const amount = useFormField<string>({
    initialValue: '',
    value: t.price?.amount.toString(),
  });

  const currency = useFormSelectField<Currency, string>({
    initialValue: '',
    value: t.price?.currency.id ?? 'UAH',
    options: currencyList,
    getOptionValue: c => c.id,
    getOptionLabel: c => c.id,
  });

  const handleSubmit = async () => {
    await api.updatePrice({
      amount: parseInt(amount.value as string),
      currency: currency.value as string,
    });
  }

  return (
    <FormDialog title={<Translated value={TRANSLATIONS.PROFILE.SET_PRICE}/>} onSubmit={handleSubmit}>
      <TextField
        field={amount}
        type="number"
        label={(
          <Translated value={TRANSLATIONS.PROFILE.PRICE}/>
        )}
      />
      <SelectField
        field={currency}
        label={(
          <Translated value={TRANSLATIONS.PROFILE.CURRENCY}/>
        )}
      />

      <ActionButton/>
    </FormDialog>
  )
}
