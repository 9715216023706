import {EditableInline} from "../../Editable.tsx";
import {PriceAmountView} from "@Components/Therapist/PriceAmountView.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const PriceSection = () => {
  return (
    <EditableInline editPath="price" label={TRANSLATIONS.PROFILE.PRICE}>
      <PriceAmountView/>
    </EditableInline>
  );
}
