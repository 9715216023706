import React, {ReactNode} from "react";
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {routes} from "./routes.tsx";
import {HelmetProvider} from "react-helmet-async";

const router = createBrowserRouter(routes);
const strict = false;

interface BypassProps {
  children: ReactNode;
}

const BypassStrictMode = ({children}: BypassProps) => children

const Wrapper = strict
  ? React.StrictMode
  : BypassStrictMode;

ReactDOM.hydrateRoot(document.getElementById('app')!,
  <Wrapper>
    <HelmetProvider>
      <RouterProvider router={router}/>
    </HelmetProvider>
  </Wrapper>
);
