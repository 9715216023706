import { FieldValidator, scalar } from '@Util/Hooks/FormField.ts';

export interface RangeOptions {
  min: number;
  max: number;
}

export class Range implements FieldValidator {
  constructor(private readonly options: RangeOptions) {}

  isValid(value: scalar): boolean {
    if (value === null) {
      return false;
    }

    if (typeof value === 'boolean') {
      return false;
    }

    const numeric = parseFloat(value.toString());

    return numeric >= this.options.min && numeric <= this.options.max;
  }

  get message(): string {
    return `Value must be between ${this.options.min} and ${this.options.max}`;
  };
}
