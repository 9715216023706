import {FormDialog} from "@Components/FormDialog.tsx";
import {useAxios} from "@Util/Hooks/Http.tsx";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {Required} from "@Util/Validation/Required.ts";
import {TextField} from "@Components/TextField.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";

export const LanguageAddDialog = () => {
  const http = useAxios();

  const code = useFormField<string>({
    initialValue: '',
    validators: [
      new Required(),
    ]
  });

  const name = useFormField<string>({
    initialValue: '',
    validators: [
      new Required(),
    ]
  });

  const addLanguage = async () => {
    await http.post('/api/language', {
      code: code.value,
      name: name.value,
      isSiteLocale: false,
    });
  }

  return (
    <FormDialog title="Add language" onSubmit={addLanguage}>
      <TextField field={code} label="Language code"/>
      <TextField field={name} label="Name"/>

      <ActionButton requireValid={[code]}>Add</ActionButton>
    </FormDialog>
  )
}
