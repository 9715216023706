import {WorksWith} from "@API/WorksWithAPI.ts";
import {useLocalisedWorksWith} from "@Components/Providers/WorksWithProvider.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";

interface WorksWithListProps {
  elements?: WorksWith[],
}

export const WorksWithList = ({elements}: WorksWithListProps) => {
  const items = useLocalisedWorksWith();

  if (!elements || elements.length === 0) {
    return <Translated value="Not selected"/>
  }

  const matching = items.filter(item => {
    for (const element of elements) {
      if (element.id === item.id) {
        return true;
      }
    }

    return false;
  });

  return matching.map(l => l.translation.title ?? l.title).join(', ');
}
