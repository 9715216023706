import {OptionsWithExtraProps, useSnackbar, VariantType} from "notistack";
import {ReactNode} from "react";

export type Notification = OptionsWithExtraProps<VariantType> & {
  message: ReactNode;
};

export type ShowNotification = (options: Notification) => void;

export function useNotifications(): ShowNotification {
  const {enqueueSnackbar} = useSnackbar()

  return (options: Notification) => enqueueSnackbar(options)
}
