import {Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper} from "@mui/material";
import {useHref, useLocation, useNavigate} from "react-router-dom";
import {ReactNode} from "react";

export interface SidebarSection {
  elements: AdminMenuItem[];
  divider: boolean;
  key: string;
}


interface AdminMenuItem {
  title: ReactNode;
  icon: ReactNode;
  path: string;
}

export interface SidebarProps {
  items: SidebarSection[];
}

export const Sidebar = ({items}: SidebarProps) => {
  return (
    <Paper>
      {items.map(item =>
        (
          <Box key={item.key} sx={{width: 200}}>
            <List>
              {item.elements.map((element) =>
                (
                  <MenuItem key={element.path} item={element}/>
                )
              )}
            </List>
            {item.divider && <Divider/>}
          </Box>
        )
      )}
    </Paper>
  )
}

export interface MenuItemProps {
  item: AdminMenuItem;
}

export const MenuItem = ({item}: MenuItemProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const href = useHref(item.path);

  const selected = location.pathname.search(href) >= 0;

  return (
    <ListItem key={item.path} disablePadding>
      <ListItemButton selected={selected} onClick={() => navigate(item.path)}>
        <ListItemIcon>
          {item.icon}
        </ListItemIcon>
        <ListItemText primary={item.title} />
      </ListItemButton>
    </ListItem>
  );
}
