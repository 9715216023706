import { RouteObject } from 'react-router-dom';
import { CertificateAddDialog } from './CertificateAddDialog.tsx';
import { CertificateDeleteDialog } from './CertificateDeleteDialog.tsx';
import { CertificateUpdateDialog } from './CertificateUpdateDialog.tsx';

export const CertificatesRoute: RouteObject = {
  path: 'certificates',
  children: [
    {
      path: 'add',
      Component: CertificateAddDialog,
    },
    {
      path: ':id',
      children: [
        {
          path: 'update',
          Component: CertificateUpdateDialog,
        },
        {
          path: 'delete',
          Component: CertificateDeleteDialog,
        },
      ],
    },
  ],
};
