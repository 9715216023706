import {Chair, Groups, HeartBroken, School} from "@mui/icons-material";
import {Flag, Place, Translate} from "@mui/icons-material";
import {TopicAdminRoute} from "../../Pages/Admin/Topic/route.ts";
import {QualificationAdminRoute} from "../../Pages/Admin/Qualification/route.ts";
import {CountryRoute} from "../../Pages/Admin/Country/route.tsx";
import {CityRoute} from "../../Pages/Admin/City/route.tsx";
import {SidebarSection} from "../partial/Sidebar.tsx";

export const buildAdminMenu = (): SidebarSection[] => {
  return [
    {
      key: 'auth',
      elements: [
        {
          title: 'Members',
          icon: <Chair/>,
          path: 'members',
        },
      ],
      divider: true,
    },
    {
      key: 'therapy',
      elements: [
        {
          title: 'Work with',
          icon: <Groups/>,
          path: 'work-with',
        },
        {
          title: 'Topics and problems',
          icon: <HeartBroken/>,
          path: TopicAdminRoute.path as string,
        },
        {
          title: 'Qualification',
          icon: <School/>,
          path: QualificationAdminRoute.path as string,
        },
      ],
      divider: true,
    },
    {
      key: 'locations',
      elements: [
        {
          title: 'Countries',
          icon: <Flag/>,
          path: CountryRoute.path as string,
        },
        {
          title: 'Cities',
          icon: <Place/>,
          path: CityRoute.path as string,
        },
        {
          title: 'Languages',
          icon: <Translate/>,
          path: 'languages',
        },
        {
          title: 'Translations',
          icon: <Translate/>,
          path: 'translations',
        },
      ],
      divider: true,
    },
  ]
}
