import {FormDialog} from "@Components/FormDialog.tsx";
import {Translated, useTranslated} from "@Components/Localization/Translated.tsx";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {Required} from "@Util/Validation/Required.ts";
import {CheckboxField} from "@Components/CheckboxField.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {useTherapistAPI} from "@API/TherapistAPI.ts";
import {styled} from "@mui/system";
import {theme} from "@Util/theme.tsx";
import {Link} from "@Components/Link.tsx";

export const SendToReviewDialog = () => {
  const api = useTherapistAPI();
  const terms = useFormField<boolean>({
    initialValue: false,
    validators: [
      new Required(),
    ],
  });

  const policy = useFormField<boolean>({
    initialValue: false,
    validators: [
      new Required(),
    ],
  });

  const title = useTranslated('Send to review');
  const iAccept = useTranslated('I accept');

  const termsLink = (
    <Link to="/terms-and-conditions" target="_blank">
      <Translated value="terms and conditions"/>
    </Link>
  );

  const policyLink = (
    <Link to="/privacy-policy" target="_blank">
      <Translated value="privacy policy"/>
    </Link>
  );

  const handleSubmit = async () => {
    await api.sendToReview();
  }

  return (
    <FormDialog title={title} onSubmit={handleSubmit}>
      <Wrapper>
        <p>
          <Translated value="We will review your profile."/><br/>
          <Translated value="Usually it takes no longer than one day."/><br/>
          <Translated value="After profile is approved it will be publicly accessible."/>
        </p>
        <p>
          <Translated value="In case of required updates support will contact you via messages."/>
        </p>

        <CheckboxField
          field={terms}
          label={(
            <>{iAccept} {termsLink}.</>
          )}
        />
        <CheckboxField 
          field={policy}
          label={(
            <>{iAccept} {policyLink}.</>
          )}
        />
      </Wrapper>

      <ActionButton requireValid={[policy, terms]}>
        <Translated value="Confirm"/>
      </ActionButton>
    </FormDialog>
  )
}

const Wrapper = styled('div')`
  margin-bottom: ${theme.spacing(2)};
  
  p {
    margin-top: 0;
  }
  
  .MuiFormGroup-root {
    margin-bottom: 0;
  }
`
