import {FormDialog, FormDialogProps} from "@Components/FormDialog.tsx";
import {CanNotBeUndone} from "@Components/CanNotBeUndone.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";

export const ConfirmDelete = (props: FormDialogProps) => {
  const {children, ...rest} = props;

  return (
    <FormDialog {...rest}>
      {children}
      <CanNotBeUndone/>
      <ActionButton>
        <Translated value="Delete"/>
      </ActionButton>
    </FormDialog>
  )
}
