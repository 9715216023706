import {FormDialog} from "@Components/FormDialog.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {useLocationsAPI} from "@API/LocationsAPI.ts";
import {useFormSelectField} from "@Util/Hooks/FormSelectField.ts";
import {LocalisedCountry, useLocalisedCountries} from "@Components/Providers/CountriesProvider.tsx";
import {LocalisedCity} from "@Components/Providers/LocalisedCityProvider.tsx";
import {SelectField} from "@Components/SelectField.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {useMemo} from "react";
import {Required} from "@Util/Validation/Required.ts";
import {useTherapist} from "../TherapistProvider.tsx";
import {useFilteredCities} from "./useFilteredCities.tsx";
import {useParams} from "react-router-dom";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {CheckboxField} from "@Components/CheckboxField.tsx";

export const LocationUpdateDialog = () => {
  const therapist = useTherapist();
  const api = useLocationsAPI();
  const params = useParams<{ id: string }>();

  const existing = therapist.locations ?? [];
  const current = useMemo(() => {
    const id = params.id;
    if (id) {
      return existing.find(l => l.id === parseInt(id));
    }

    return undefined;
  }, [params, existing]);

  const exclude = existing
    .filter(l => l.id !== current?.id)
    .map(l => l.city);


  const country = useFormSelectField<LocalisedCountry, number>({
    initialValue: '',
    value: current?.country?.id,
    options: useLocalisedCountries(),
    getOptionValue: country => country.id,
    getOptionLabel: country => country.localisedName ?? country.name,
  });

  const city = useFormSelectField<LocalisedCity, number>({
    initialValue: '',
    value: current?.city?.id,
    options: useFilteredCities({
      country: country.selected,
      exclude: exclude,
    }),
    getOptionValue: city => city.id,
    getOptionLabel: city => city.localisedName ?? city.name,
    validators: [
      new Required(),
    ],
  });

  const remove = useFormField<boolean>({
    initialValue: false,
  });

  const handleSubmit = async () => {
    if (!city.selected || !current) {
      throw new Error('Not selected');
    }

    if (remove.value) {
      await api.delete(current.id);
    } else {
      await api.update(current.id, city.selected?.id as number);
    }
  };


  const title = <Translated value="Update location"/>;

  return (
    <FormDialog title={title} onSubmit={handleSubmit} back="../../..">
      <SelectField
        disabled={remove.value}
        field={country}
        label={(
          <Translated value="Country"/>
        )}
      />

      <SelectField
        disabled={city.options.length === 0 || remove.value}
        field={city}
        label={(
          <Translated value="City"/>
        )}
      />

      <CheckboxField
        field={remove}
        label={(
          <Translated value="Remove this location"/>
        )}/>

      <ActionButton requireValid={[country, city]}>
        {remove.value
          ? <Translated value="Delete"/>
          : <Translated value="Save"/>
        }
      </ActionButton>
    </FormDialog>
  );
}
