import {Helmet} from "react-helmet-async";
import {useI18n} from "@Components/I18nWrapper.tsx";
import {resolvePath, useLocation, useParams} from "react-router-dom";
import {absolutePath} from "@Util/Hooks/Navigate.tsx";
import {UniversalContext} from "@Util/UniversalAPI.ts";
import {useTranslated} from "@Components/Localization/Translated.tsx";

interface Alternate {
  url: string;
  locale: string;
}

export interface GlobalMetaProps {
  context: UniversalContext;
}

export const hrefLangCode = (code: string) => {
  switch (code.toLowerCase()) {
    case 'ua':
      return 'uk';

    default:
      return code;
  }
}

export const GlobalMeta = ({context}: GlobalMetaProps) => {
  const languages = useI18n();
  const location = useLocation();
  const params = useParams();
  const defaultTitle = useTranslated('where professional psychological support is given and received');

  const translations: Alternate[] = languages
    .filter(l => l.isSiteLocale)
    .map(l => {
      const withLocale = Object.assign({}, params, {
        locale: l.code,
      });

      const to = resolvePath(absolutePath(location, withLocale));

      return {
        locale: hrefLangCode(l.code),
        url: `${context.BASE_URL}${to.pathname}${to.search}${to.hash}`,
      };
    });

  return (
    <Helmet>
      <title>ProGestalt - {defaultTitle}</title>
      {translations.map(t => (
        <link key={t.locale} rel="alternate" href={t.url} hrefLang={t.locale}/>
      ))}
    </Helmet>
  )
}
