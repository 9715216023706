import {useParams} from "react-router-dom";
import {FormDialog} from "@Components/FormDialog.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {useLocalisedWorksWithItem} from "@Components/Providers/WorksWithProvider.tsx";
import {Required} from "@Util/Validation/Required.ts";
import {TextField} from "@Components/TextField.tsx";
import {useWorksWithAPI} from "@API/WorksWithAPI.ts";
import {useSearchAwarePath} from "@Util/Hooks/Navigate.tsx";

export const WorksWithUpdateDialog = () => {
  const api = useWorksWithAPI();
  const back = useSearchAwarePath('../..');

  const params = useParams<{id: string}>()

  const item = useLocalisedWorksWithItem(params.id);

  const title = useFormField({
    initialValue: item?.translation.title ?? '',
    validators: [new Required()],
  });

  const handleSubmit = async () => {
    if (!item) {
      throw new Error('Item not selected');
    }

    await api.update({
      id: item.id,
      locale: item.translation.locale,
      title: title.value as string,
    })
  };

  return (
    <FormDialog title={<Translated value="Works with update"/>} onSubmit={handleSubmit} back={back}>
      <TextField
        field={title}
        label={(
          <Translated value="Title"/>
        )}
      />
      <ActionButton requireValid={[title]}/>
    </FormDialog>
  );
}
