import {FormDialog} from "@Components/FormDialog.tsx";
import {useTranslated} from "@Components/Localization/Translated.tsx";
import {EducationForm, useEducationFormState} from "./EducationForm.tsx";
import {useSearchAwarePath} from "@Util/Hooks/Navigate.tsx";
import {useTherapistEducation} from "./useTherapistEducation.tsx";
import {EducationUpdate, useEducationAPI} from "@API/EducationAPI.ts";
import {TRANSLATIONS} from "@assets/translation.ts";

export const EducationEditDialog = () => {
  const api = useEducationAPI();
  const eduction = useTherapistEducation();
  const fields = useEducationFormState(eduction);
  const back = useSearchAwarePath('../../..');

  const handleSubmit = async () => {
    if (!eduction) {
      throw new Error('No item');
    }

    const data: EducationUpdate = {
      id: eduction.id as number,
      qualification: fields.qualification.value as number,
      institution: fields.institutionName.value as string,
      speciality: fields.speciality.value as string,
      graduationYear: fields.graduatedAt.getNumericValue() as number,
    };

    if (fields.photo.file) {
      data.photo = fields.photo.value;
    }

    await api.update(data);
  };

  const title = useTranslated(TRANSLATIONS.PROFILE.UPDATE_EDUCATION_TITLE);

  return (
    <FormDialog title={title} onSubmit={handleSubmit} back={back}>
      <EducationForm fields={fields} allowDelete={true}/>
    </FormDialog>
  );
}
