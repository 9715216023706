import {AxiosError} from "axios";
import {FormDialog} from "@Components/FormDialog.tsx";
import {TextField} from "@Components/TextField.tsx";
import {PasswordField} from "@Components/PasswordField.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {useCurrentUser} from "@Util/Hooks/Auth.tsx";
import {Required} from "@Util/Validation/Required.ts";
import {IsEmail} from "@Util/Validation/IsEmail.ts";
import {useAxios} from "@Util/Hooks/Http.tsx";
import {TransSection} from "@Components/Localization/TransSection.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";

export const EmailDialog = () => {
  const http = useAxios();
  const user = useCurrentUser();

  const email = useFormField({
    initialValue: user.userName ?? '',
    validators: [
      new Required(),
      new IsEmail(),
    ]
  });

  const password = useFormField<string>({
    initialValue: '',
    validators: [
      new Required(),
    ]
  });

  const onSubmit = async () => {
    await http
      .put('/api/user/email', {
        email: email.value,
        password: password.value,
      })
      .catch(error => {
        if (error instanceof AxiosError && error.response?.status === 403) {
          console.error('TODO show toast');
        }

        throw error;
      });

    await http.get('/api/auth');
  };

  return (
    <TransSection section="account">
      <TransSection section="basics">
        <FormDialog title="Email" onSubmit={onSubmit}>
          <TextField
            field={email}
            type="email"
            label={(
              <Translated value="New email"/>
            )}
          />
          <PasswordField
            autoComplete="false"
            field={password}
            name="asdasdasd"
            label={(
              <Translated value="Current password"/>
            )}
          />

          <ActionButton requireValid={[email, password]}/>
        </FormDialog>
      </TransSection>
    </TransSection>
  );
}
