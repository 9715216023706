import {useAxios, useResponseActionInterceptor} from "@Util/Hooks/Http.tsx";
import {Box, CircularProgress, IconButton} from "@mui/material";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material"

import {Edit} from "@mui/icons-material";
import {createContext, useContext, useEffect, useState} from "react";
import {AuthUser} from "@Util/Hooks/Auth.tsx";
import {useLoading} from "@Util/Hooks/Loading.tsx";
import {Outlet, useNavigate} from "react-router-dom";

export const UsersContext = createContext<AuthUser[]>([]);

export const useUsers = () => useContext(UsersContext)

export const UserListPage = () => {
  const http = useAxios();
  const isLoading = useLoading();
  const navigate = useNavigate();

  const [users, setUsers] = useState<AuthUser[]>([]);

  useResponseActionInterceptor(/^users$/, async (response) => {
    setUsers(response.data);
  }, [users]);

  useResponseActionInterceptor(/^user$/, async (response) => {
    setUsers(current => {
      return current.map(user => {
        if (user.id === response.data.id) {
          return response.data;
        }

        return user;
      })
    });
  }, [users]);

  useEffect(() => {
    http.get('/api/user').then(() => {
      console.log('Users loaded');
    });
  }, []);

  return (
    <>
      <h1>Users list</h1>

      <Box>
        {isLoading && !users.length && <CircularProgress/>}

        {users.length > 0 && (
          <TableContainer sx={{minWidth: 500}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>First name</TableCell>
                  <TableCell>Last name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>{user.id}</TableCell>
                    <TableCell>{user.firstName}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell>{user.userName}</TableCell>
                    <TableCell>{user.role?.id}</TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => navigate(`${user.id}/edit`)}>
                        <Edit/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

          </TableContainer>
        )}
      </Box>

      <UsersContext.Provider value={users}>
        <Outlet/>
      </UsersContext.Provider>
    </>
  )
}
