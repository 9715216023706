import { API } from '@API/API.ts';
import { Currency } from '@API/CurrencyAPI.ts';
import { Language } from '@Components/I18nWrapper.tsx';

export interface MembershipI18n {
  locale: Language;
  title: string;
}

export interface MembershipPK {
  id: number;
}

export interface Membership extends MembershipPK {
  title: string;
  price: number;
  currency: Currency;
  i18n: MembershipI18n[];
}


export class MembershipAPI extends API {
  async list() {
    const response = await this.http.get('/api/membership');

    return response.data as Membership[];
  }
}
