import {CountryPK} from "@API/CountryAPI.ts";
import {CityPK} from "@API/CityAPI.tsx";
import {useMemo} from "react";
import {LocalisedCity, useLocalisedCities} from "@Components/Providers/LocalisedCityProvider.tsx";

export interface FilterCityOptions {
  country?: CountryPK;
  exclude?: CityPK[];
}
export const useFilteredCities = ({country, exclude}: FilterCityOptions) => {
  const all = useLocalisedCities();

  return useMemo(() => {
    const excludeIds = exclude?.map(city => city.id);
    let filtered: LocalisedCity[] = all;

    if (country) {
      filtered = filtered.filter(city => city.country.id === country.id);
    }

    if (excludeIds) {
      filtered = filtered.filter(city => !excludeIds.includes(city.id))
    }

    return filtered;
  }, [all, country, exclude]);
}
