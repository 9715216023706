import {FormDialog} from "@Components/FormDialog.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {To, useSearchParams} from "react-router-dom";
import {useCity} from "./Hooks/useCity.tsx";
import {useCityAPI} from "@API/CityAPI.tsx";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {Required} from "@Util/Validation/Required.ts";
import {TextField} from "@Components/TextField.tsx";

export const CityUpdateDialog = () => {
  const api = useCityAPI();
  const city = useCity();
  const [search] = useSearchParams();

  const back: To = {
    pathname: '../..',
    search: search.toString(),
  };

  const name = useFormField({
    initialValue: city?.name ?? '',
    validators: [
      new Required(),
    ],
  });

  const i18nName = useFormField({
    initialValue: city?.localisedName ?? '',
    validators: [
      new Required(),
    ],
  });

  const handleSubmit = async () => {
    if (!city) {
      throw new Error('Invalid city');
    }

    await api.update({
      id: city.id,
      name: name.value as string,
      i18n: {
        locale: city.locale,
        name: i18nName.value as string,
      }
    });
  };

  const title = <Translated value="Update city"/>;

  return (
    <FormDialog title={title} onSubmit={handleSubmit} back={back}>
      <TextField
        field={name}
        label={(
          <Translated value="Name"/>
        )}
      />

      <TextField
        field={i18nName}
        label={(
          <Translated value="Name localization"/>
        )}
      />

      <ActionButton requireValid={[name, i18nName]} />
    </FormDialog>
  );
}
