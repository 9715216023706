import {FCProps} from "@Util/FCProps.ts";
import {ComponentType} from "react";
import {Box, css, SvgIconProps} from "@mui/material";
import {Translated} from "@Components/Localization/Translated.tsx";
import {styled} from "@mui/system";
import {theme} from "@Util/theme.tsx";

export interface HighlightItemProps extends FCProps {
  label: string;
  IconComponent: ComponentType<SvgIconProps>;
  preventInline?: boolean;
  color?: SvgIconProps['color'];
}

export const HighlightItem = ({IconComponent, label, preventInline, color, children}: HighlightItemProps) => {
  return (
    <StyleWrapper>
      <IconWrapper>
        <IconComponent color={color ?? 'primary'}/>
      </IconWrapper>
      <Box width="100%">
        <Box paddingRight={1} display={{xs: 'block', sm: preventInline ? 'block' : 'inline'}}>
          <b>
            <Translated value={label}/>:
          </b>
        </Box>
        {children}
      </Box>
    </StyleWrapper>
  )
}

const StyleWrapper = styled('div')`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
`

const IconWrapper = styled('div')`
  margin-right: ${theme.spacing(0.5)};
  
  .MuiSvgIcon-root {
    height: 20px;
  }
`
