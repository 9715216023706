import { API, useAPI } from '@API/API.ts';

export interface Education {
  id: number;
  qualification: {
    id: number;
  };
  institution: string;
  speciality: string;
  graduationYear: number;
  photo: string;
}

interface EducationData {
  qualification: number;
  institution: string;
  speciality: string;
  graduationYear: number;
}

export interface EducationAdd extends EducationData {
  photo: string;
}

export interface EducationUpdate extends EducationData {
  id: number;
  photo?: string;
}

export interface EducationDelete {
  id: number;
}

const ENDPOINT = '/api/education';

export class EducationAPI extends API {
  async add(data: EducationAdd): Promise<Education> {
    const response = await this.http.post(ENDPOINT, data);

    return response.data;
  }

  async update(data: EducationUpdate): Promise<Education> {
    const response = await this.http.put(ENDPOINT, data);

    return response.data as Education;
  }

  async delete(data: EducationDelete): Promise<void> {
    await this.http.delete(ENDPOINT, {data});
  }
}

export const useEducationAPI = () => useAPI(EducationAPI);
