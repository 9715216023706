import {FormDialog} from "@Components/FormDialog.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {useTherapist} from "../Account/Therapist/TherapistProvider.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {TextField} from "@Components/TextField.tsx";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {ChatContact, useContactAPI} from "@API/ContactAPI.ts";
import {useNotifications} from "@Util/Hooks/Notifications.tsx";
import {AuthenticationGuard} from "@Components/AuthenticationGuard.tsx";
import {LocalisedTopic, useLocalisedTopics} from "@Components/Providers/TopicsProvider.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";
import {ExpressRegistration} from "./ExpressRegistration.tsx";
import {CheckboxesField} from "@Components/CheckboxesField.tsx";
import {useMultiSelectField} from "@Components/useMultiselect.ts";
import {Required} from "@Util/Validation";
import {styled} from "@mui/system";
import {theme} from "@Util/theme.tsx";
import {useEffect, useState} from "react";
import {Link} from "@Components/Link.tsx";

export const TherapistContactDialog = () => {
  const api = useContactAPI();
  const t = useTherapist();
  const topics = useLocalisedTopics();
  const therapistTopicIds = t.topics?.map(topic => topic.id) ?? [];

  const [contact, setContact] = useState<ChatContact>();

  const checkIsInContacts = () => {
    api.list().then(list => {
      setContact(list.find(c => c.target.id === t.user?.id));
    });
  }

  useEffect(checkIsInContacts, [t.user?.id]);

  const topicsField = useMultiSelectField<LocalisedTopic, number>({
    initialValue: [],
    options: topics,
    getOptionValue: (topic) => topic.id,
    getOptionLabel: (topic) => topic.translation.title ?? topic.title,
  });

  const messageField = useFormField<string>({
    initialValue: '',
    validators: [
      new Required(),
    ],
  });

  const topicsMatch = topicsField.selected?.every(selected => therapistTopicIds.includes(selected.id)) ?? false;

  const notify = useNotifications()

  const handleSubmit = async () => {
    await api.create({
      target: t.user?.id as number,
      message: messageField.value as string,
    });

    notify({
      variant: 'info',
      message: <Translated value="Your message has been sent."/>
    });
  }

  const title = <Translated value={TRANSLATIONS.PROFILE.TITLE_CONTACT_THERAPIST}/>;

  return (
    <AuthenticationGuard Fallback={ExpressRegistration}>
      <FormDialog title={title} onSubmit={handleSubmit}>
        {!contact ? (
          <>
            <CheckboxesField
              field={topicsField}
              label={(
                <Translated value={TRANSLATIONS.PROFILE.TOPIC}/>
              )}
            />

            {topicsField.selected && topicsField.selected.length > 0 && (
              topicsMatch ? (
                <>
                  <TextField
                    field={messageField}
                    label={(
                      <Translated value={TRANSLATIONS.PROFILE.MESSAGE}/>
                    )}
                    multiline={true} minRows={3}
                  />

                  <ActionButton requireValid={[messageField]}>
                    <Translated value="Send"/>
                  </ActionButton>
                </>
              ) : (
                <InfoBox>
                  <Translated value={TRANSLATIONS.PROFILE.ERROR_TOPIC_MISMATCH}/>
                </InfoBox>
              )
            )}
          </>
        ) : (
          <p>
            <Translated value={TRANSLATIONS.PROFILE.ALREADY_CONTACTED}/>.&nbsp;
            <Link to={`/messages/${contact.id}`}>
              <Translated value={TRANSLATIONS.PROFILE.GO_TO_MESSAGES}/>.
            </Link>
          </p>
        )}

      </FormDialog>
    </AuthenticationGuard>
  )
}

const InfoBox = styled('div')`
  padding: ${theme.spacing(2)};
  background-color: ${theme.palette.secondary.main};
`
