import {Box} from "@mui/material";
import {useMembershipOptions} from "@Components/Membership/useMembershipOptions.tsx";
import {useTherapist} from "../../Account/Therapist/TherapistProvider.tsx";

export const MembershipView = () => {
  const t = useTherapist();
  const options = useMembershipOptions();
  const type = options.find(o => o.id === t.membershipType?.id);

  return (
    <>
      {type && (
        <Box sx={{mb: 1}}>
          {type.title}
        </Box>
      )}
    </>
  );
};
