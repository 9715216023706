import {ChatContact} from "@API/ContactAPI.ts";
import {Box, Button, List} from "@mui/material"
import {InputBase} from "@mui/material";
import {ChangeEvent, ChangeEventHandler, FormEvent, useEffect, useMemo, useState} from "react";
import {NORMAL_RADIUS, theme} from "@Util/theme.tsx";
import {styled} from "@mui/system";
import {ArrowBack, Search} from "@mui/icons-material";
import {ChatMessageView} from "./ChatMessageView.tsx";
import {useNavigate, useParams} from "react-router-dom";
import {ContactView} from "./ContactView.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {Topics, useWebSocket} from "@Components/Providers/WebsocketProvider.tsx";
import {Link} from "@Components/Link.tsx";
import {useNotifications} from "@Util/Hooks/Notifications.tsx";
import {useContactsList} from "./Hooks/ContactsList.tsx";
import {maxMessages, useMessagesList} from "./Hooks/MessagesList.tsx";
import {Headline} from "@Components/Headline.tsx";

export const MessagesPage = () => {
  const params = useParams<{ contact: string }>();
  const navigate = useNavigate();
  const notify = useNotifications();
  const [selected, setSelected] = useState<ChatContact>();
  const [filter, setFilter] = useState('');
  const contacts = useContactsList();
  const messages = useMessagesList(selected);

  const ws = useWebSocket();

  useEffect(() => {
    setSelected(contacts.find(c => c.id.toString() === params.contact));
  }, [params.contact])

  const handleSelect = (contact: ChatContact) => {
    setSelected(contact);
    if (params.contact) {
      navigate(`../${contact.id}`, {replace: true});
    } else {
      navigate(`${contact.id}`, {replace: true});
    }
  }

  const [newMessage, setNewMessage] = useState<string>('');

  const handleNewMessageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewMessage(event.target.value);
  }

  const handleMessageSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!newMessage || !selected) {
      return;
    }

    if (!ws.connected) {
      notify({
        message: <Translated value="Connection lost. Please check your connection or try again later."/>,
        variant: 'warning',
      });

      return;
    }

    ws.emit(Topics.CHAT_MESSAGE, {
      contact: selected.id,
      content: newMessage,
    });

    setNewMessage('');
  };

  const handleFilterChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setFilter(event.target.value.toLowerCase());
  }

  const contactsFiltered = useMemo(() => {
    return contacts.filter(c => {
      const name = `${c.target.firstName} ${c.target.lastName}`;

      return name.toLowerCase().includes(filter);
    })
  }, [filter, contacts]);

  return (
    <>
      <Headline text="My messages" level={1}/>

      <Chat>
        <Sidebar sx={{display: {sm: 'block', xs: selected ? 'none' : 'block'}}}>
          <SearchWrapper>
            <Search color="disabled"/>
            <InputBase onChange={handleFilterChange} fullWidth placeholder="Search contacts"/>
          </SearchWrapper>

          {contacts.length > 0 && (
            <List>
              {contactsFiltered.map(c => (
                <ContactView key={c.id} contact={c} onSelect={handleSelect}/>
              ))}
            </List>
          )}
        </Sidebar>
        {selected && (
          <Main>
            <Box display={{sx: 'block', sm: 'none'}} sx={{p: 2}}>
              <Link to=".." sx={{display: 'flex', alignItems: 'center'}}>
                <ArrowBack/>
                <Translated value="To contacts list"/>
              </Link>
            </Box>
            <MainContent>
              <Messages>
                <NewMessageForm onSubmit={handleMessageSubmit}>
                  <InputBase
                    sx={{flexGrow: 1}}
                    value={newMessage}
                    onChange={handleNewMessageChange}
                    placeholder="Type message"
                  />
                  <Button type="submit" color="primary">
                    <Translated value="Send"/>
                  </Button>
                </NewMessageForm>
                {selected && messages.map(m => (
                  <ChatMessageView key={m.id} message={m} contact={selected}/>
                ))}
                {messages.length === maxMessages && (
                  <Box textAlign="center">
                    <Translated value="Showing last {0} messages" args={[maxMessages]}/>
                  </Box>
                )}
              </Messages>
            </MainContent>
          </Main>
        )}
        {!selected && (
          <Main sx={{display: {xs: 'none', sm: 'block'}}}>
            <Box display="flex" alignItems="center" justifyContent="space-around" height="100%">
              <Translated value="Please select contact"/>
            </Box>
          </Main>
        )}
      </Chat>
    </>
  )
}


const Chat = styled('div')`
  min-height: 400px;
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(2)};
`

const Sidebar = styled('div')`
  width: 100%;
  background-color: white;

  ${theme.breakpoints.up('sm')} {
    max-width: 300px;
    border-radius: ${NORMAL_RADIUS}px;
  }
`

const Main = styled('div')`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: ${NORMAL_RADIUS}px;
`

const MainContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`

const Messages = styled(Box)`
  flex-grow: 1;
  height: 100%;
  max-height: 600px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
  gap: ${theme.spacing(2)};
  background-color: white;
  border-radius: ${NORMAL_RADIUS}px;
  margin-top: ${theme.spacing(2)};
`

const SearchWrapper = styled('div')`
  display: flex;
  align-items: center;
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
  border: 1px solid ${theme.palette.divider};
  border-radius: ${NORMAL_RADIUS}px;
  margin: ${theme.spacing(2)};
`

const NewMessageForm = styled('form')`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(2)};
  width: 100%;
  padding: 0 ${theme.spacing(2)};

  button {
    padding: ${theme.spacing(1)};
  }
  
  input {
    border: 1px solid ${theme.palette.divider};
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
    margin: ${theme.spacing(2)} 0;
    border-radius: ${NORMAL_RADIUS}px;
  }
`
