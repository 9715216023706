import {useCurrentUser} from "@Util/Hooks/Auth.tsx";
import {TextField} from "@Components/TextField.tsx";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {Required} from "@Util/Validation/Required.ts";
import {ActionButton} from "@Components/ActionButton.tsx";
import {FormDialog} from "@Components/FormDialog.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {useUserAPI} from "@API/User.ts";
import {useMembershipSelectField} from "../../Membership/MembershipField.tsx";
import {SelectField} from "@Components/SelectField.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const NameDialog = () => {
  const user = useCurrentUser();
  const userAPI = useUserAPI();

  const firstName = useFormField({
    initialValue: user.firstName,
    validators: [new Required()],
  });

  const lastName = useFormField({
    initialValue: user.lastName,
    validators: [new Required()],
  });

  const membership = useMembershipSelectField(user);

  const onSubmit = async () => {
    await userAPI.update({
      id: user.id as number,
      firstName: firstName.value,
      lastName: lastName.value,
      membership: membership.value,
    });

    await userAPI.current();
  };

  return (
    <FormDialog title={<Translated value={TRANSLATIONS.PROFILE.FIRST_NAME}/>} onSubmit={onSubmit} maxWidth="sm">
      <TextField
        field={firstName}
        name="first_name"
        label={(
          <Translated value={TRANSLATIONS.PROFILE.FIRST_NAME}/>
        )}
      />
      <TextField
        field={lastName}
        name="last_name"
        label={(
          <Translated value={TRANSLATIONS.PROFILE.LAST_NAME}/>
        )}
      />

      <SelectField
        field={membership}
        label={(
          <Translated value={TRANSLATIONS.PROFILE.MEMBERSHIP_TYPE}/>
        )}
      />

      <ActionButton/>
    </FormDialog>
  );
}
