import {RouteObject} from "react-router-dom";
import {AdminLayout} from "../../Layout/AdminLayout.tsx";
import {AdminDashboardPage} from "./AdminDashboardPage.tsx";
import {AdminUserRoute} from "./User/route.tsx";
import {AdminLanguagesRoute} from "./Language/route.tsx";
import {AdminTranslationRoute} from "./Translations/route.tsx";
import {CountryRoute} from "./Country/route.tsx";
import {CityRoute} from "./City/route.tsx";
import {WorkWithRoute} from "./WorkWith/route.tsx";
import {TopicAdminRoute} from "./Topic/route.ts";
import {QualificationAdminRoute} from "./Qualification/route.ts";
import {AdminMembersRoute} from "./Members/route.tsx";

export const AdminRoute: RouteObject = {
  path: 'admin',
  Component: AdminLayout,
  children: [
    {
      index: true,
      Component: AdminDashboardPage,
    },
    AdminUserRoute,
    AdminMembersRoute,
    AdminLanguagesRoute,
    AdminTranslationRoute,
    CountryRoute,
    CityRoute,
    WorkWithRoute,
    TopicAdminRoute,
    QualificationAdminRoute,
  ]
}
