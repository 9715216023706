import {InputAdornment} from "@mui/material";
import {useState} from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {TextField, TextFieldProps} from "@Components/TextField.tsx";

type PasswordFieldProps = Omit<TextFieldProps, 'type'>;

export const PasswordField = (props: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const type = showPassword ? 'text' : 'password';
  const toggleVisibility = () => {
    setShowPassword(!showPassword);
  }

  return <TextField
    {...props}
    type={type}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end" onClick={toggleVisibility} sx={{cursor: 'pointer'}}>
          {showPassword ? <VisibilityOff/> : <Visibility/>}
        </InputAdornment>
      )
    }}
  />
}
