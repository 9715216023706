import {Paid} from "@mui/icons-material";
import {PriceAmountView} from "@Components/Therapist/PriceAmountView.tsx";
import {HighlightItem} from "./HighlightItem.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const PriceView = () => {
  return (
    <HighlightItem label={TRANSLATIONS.PROFILE.PRICE} IconComponent={Paid}>
      <PriceAmountView/>
    </HighlightItem>
  );
};
