import {createContext, useContext} from "react";
import {FCProps} from "@Util/FCProps.ts";
import {Sex} from "@API/SexAPI.ts";
import {useCurrentLanguage} from "@Components/I18nWrapper.tsx";

const SexContext = createContext<Sex[]>([]);

export interface SexLocalized {
  id: string;
  title: string;
}

export const useSex = () => useContext(SexContext);

export const useLocalisedSex = (): SexLocalized[] => {
  const language = useCurrentLanguage();
  const sex = useSex();

  return sex.map(item => {
    const localized = item.i18n.find(i => i.locale.code === language.code);

    return {
      id: item.id,
      title: localized?.title ?? item.id,
    };
  });
}

export interface SexProviderProps extends FCProps {
  value: Sex[];
}

export const SexProvider = (props: SexProviderProps) => {
  return (
    <SexContext.Provider value={props.value}>
      {props.children}
    </SexContext.Provider>
  );
}
