import { scalar } from '@Util/Hooks/FormField.ts';
import { FormSelectField, FormSelectFieldOptions } from '@Util/Hooks/FormSelectField.ts';
import { useState } from 'react';

export type MultiSelectFieldOptions<O, V extends scalar> = Omit<FormSelectFieldOptions<O, V>, 'initialValue' | 'value' | 'selected' > & {
  initialValue: V[];
  value?: V[];
  selected?: O[];
}

export type MultiSelectField<O, V extends scalar> = Omit<FormSelectField<O, V>, 'value' | 'initialValue' | 'onChange' | 'selected'> & {
  initialValue: V[];
  value?: V[];
  selected?: O[];
  onChange: (values: V[]) => void;
}

export const useMultiSelectField = <O, V extends scalar>(params: MultiSelectFieldOptions<O, V>): MultiSelectField<O, V> => {
  const {options, getOptionValue, getOptionLabel} = params

  const [value, setValue] = useState<V[]>(params.value ?? []);

  const [isValid] = useState<boolean>(false);
  const [isTouched, setTouched] = useState<boolean>(false);
  const [error] = useState<string|false>(false);

  const handleChange = (values: V[]) => {
    setTouched(true);
    setValue(values);
  }

  const handleBlur = () => {
    setTouched(true);
  }

  const selected = options.filter(o => {
    for (const v of value) {
      if (v === getOptionValue(o)) {
        return true;
      }
    }

    return false;
  });

  return {
    initialValue: params.initialValue,
    value: value,
    selected: selected,
    options: options,
    onChange: handleChange,
    onBlur: handleBlur,
    getOptionValue: getOptionValue,
    getOptionLabel: getOptionLabel,
    isValid,
    isTouched,
    error,
    reset: () => {
      setValue(params.initialValue);
      setTouched(false);
    }
  };
}
