import { FormField, FormFieldOptions, scalar, useFormField } from '@Util/Hooks/FormField.ts';
import { ReactNode } from 'react';

export interface FormSelectFieldOptions<O, T extends scalar,> extends FormFieldOptions<T> {
  options: O[];
  selected?: O;
  getOptionValue: (item: O) => string|number;
  getOptionLabel?: (item: O) => ReactNode;
}

export interface FormSelectField<O, T extends scalar> extends FormField<T> {
  options: O[];
  selected?: O;
  getOptionValue: (item: O) => string|number;
  getOptionLabel?: (item: O) => ReactNode;
}

export const useFormSelectField = <O, T extends scalar = string>(params: FormSelectFieldOptions<O, T>): FormSelectField<O, T> => {
  const {options, getOptionValue, getOptionLabel} = params

  const field: FormField<T> = useFormField<T>({
    value: params.value,
    initialValue: params.initialValue,
    validators: params.validators,
  });

  const selected: O | undefined = options.find(option => getOptionValue(option) === field.value);

  return {
    ...field,
    options,
    selected,
    getOptionValue,
    getOptionLabel,
  };
}


