import {useTherapist} from "../TherapistProvider.tsx";
import {useTherapistAPI} from "@API/TherapistAPI.ts";
import {FormDialog} from "@Components/FormDialog.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {CheckboxesField} from "@Components/CheckboxesField.tsx";
import {useMultiSelectField} from "@Components/useMultiselect.ts";
import {ActionButton} from "@Components/ActionButton.tsx";
import {LocalisedWorksWith, useLocalisedWorksWith} from "@Components/Providers/WorksWithProvider.tsx";
import {TRANSLATIONS} from "@assets/translation.ts";

export const WorkWithDialog = () => {
  const worksWith = useLocalisedWorksWith();
  const therapist = useTherapist();
  const api = useTherapistAPI();

  const selectedValues = worksWith.filter(item => {
    for (const selected of therapist.worksWith ?? []) {
      if (selected.id === item.id) {
        return true;
      }
    }

    return false;
  })

  const workWithField = useMultiSelectField<LocalisedWorksWith, number>({
    initialValue: [],
    options: worksWith,
    value: selectedValues.map(o => o.id),
    getOptionValue: (o) => o.id,
    getOptionLabel: (o) => o.translation.title ?? o.title,
  });

  const title = <Translated value={TRANSLATIONS.PROFILE.I_WORK_WITH}/>;

  const handleSubmit = async () => {
    await api.updateWorksWith(workWithField.value as number[]);
  };

  return (
    <FormDialog title={title} onSubmit={handleSubmit}>
      <CheckboxesField field={workWithField} />

      <ActionButton/>
    </FormDialog>
  );
}
