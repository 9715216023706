import {Checkbox, CheckboxProps, FormControlLabel, FormGroup} from "@mui/material";
import {ExtraProps} from "@Util/Hooks/FormField.ts";
import {ChangeEvent, ReactNode} from "react";

export type CheckboxFieldProps = CheckboxProps & ExtraProps<boolean> & {
  label: ReactNode;
  required?: boolean;
}

export const CheckboxField = (props: CheckboxFieldProps) => {
  const {label, required, field, ...rest} = props

  const handleChange = (_: ChangeEvent, checked: boolean) => {
    field.onChange(checked);
    field.onBlur();
  }

  const showRequired = required ?? false;

  return (
    <FormGroup sx={{mb: 2}}>
      <FormControlLabel
        required={showRequired}
        control={<Checkbox onChange={handleChange} checked={field.value} {...rest} />}
        label={label}
      />
    </FormGroup>
  );
}
