import {Translated} from "@Components/Localization/Translated.tsx";
import {LanguageSelect} from "@Components/LanguageSelect.tsx";
import {useState} from "react";
import {Language} from "@Components/I18nWrapper.tsx";
import {AddButton} from "@Components/Button/AddButton.tsx";
import {TopicsProvider} from "@Components/Providers/TopicsProvider.tsx";
import {TopicTable} from "./TopicTable.tsx";
import {Outlet} from "react-router-dom";
import {useSearchAwareNavigate} from "@Util/Hooks/Navigate.tsx";
import {useLoading} from "@Util/Hooks/Loading.tsx";
import {CircularProgress} from "@mui/material";

export const TopicListPage = () => {
  const navigate = useSearchAwareNavigate();
  const isLoading = useLoading();
  const [language, setLanguage] = useState<Language>();

  const showAddDialog = () => navigate('add');

  return (
    <>
      <h1><Translated value="Topics"/></h1>

      <AddButton onClick={showAddDialog}/>

      <LanguageSelect onSelected={setLanguage}/>

      <TopicsProvider language={language}>
        {isLoading ? <CircularProgress/> :<TopicTable/>}
        <Outlet/>
      </TopicsProvider>
    </>
  )
}
