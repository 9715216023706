import {useMemo} from "react";
import {useRootTransSection, useTransSection} from "@Components/Localization/TransSection.tsx";

export interface TranslatedProps {
  value: string;
  args?: (string|number)[]
}

export const useTranslated = (value: string, args: Array<string|number> = []) => {
  const rootTranslations = useRootTransSection();
  const translations = useTransSection();

  return useMemo(() => {
    let translation = translations.find((t) => t.key === value);

    if (!translation) {
      translation = rootTranslations.find((t) => t.key === value);

      if (!translation) {
        // console.log('Translation not found', { key: value })
      }
    }

    const template = translation?.value ?? value;

    return args.reduce((current: string, arg, index) => {
      return current.replace(`{${index}}`, arg.toString());
    }, template);
  }, [translations, value, ...args]);
}

export const Translated = ({value, args}: TranslatedProps) => {
  const translated = useTranslated(value, args ?? [])

  return <>{translated}</>;
}
