import {Language, useCurrentLanguage} from "@Components/I18nWrapper.tsx";
import {FCProps} from "@Util/FCProps.ts";
import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {Qualification, useQualificationAPI} from "@API/QualificationAPI.ts";
import {useResponseActionInterceptor} from "@Util/Hooks/Http.tsx";

export interface LocalisedQualification {
  id: number;
  title: string;
  locale: string;
  localised: {
    title?: string;
  }
}

const QualificationContext = createContext<LocalisedQualification[]>([]);

export const useLocalisedQualifications = () => useContext(QualificationContext);

export interface QualificationProviderProps extends FCProps {
  value?: Qualification[];
  language?: Language;
}

export const QualificationProvider = ({children, value, language}: QualificationProviderProps) => {
  const api = useQualificationAPI();
  const currentLanguage = useCurrentLanguage();
  const locale = language?.code ?? currentLanguage?.code ?? null;
  const [updatedElements, setElements] = useState<Qualification[]>();

  const elements = updatedElements ?? value ?? [];

  useEffect(() => {
    if (!value) {
      api.list().then(setElements);
    }
  }, []);

  useResponseActionInterceptor(/^qualification/, async () => {
    const data = await api.list();

    setElements(data);
  }, [setElements]);

  const localised = useMemo(() => {
    if (!locale) {
      return [];
    }

    return elements.map((item: Qualification): LocalisedQualification => {
      const i18n = item.i18n.find(q => q.locale.code === locale);

      return {
        id: item.id,
        title: item.title,
        locale: locale,
        localised: {
          title: i18n?.title,
        }
      }
    })
  }, [elements, locale]);


  return (
    <QualificationContext.Provider value={localised}>
      {children}
    </QualificationContext.Provider>
  );
}
