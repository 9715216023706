import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {FCProps} from "@Util/FCProps.ts";
import {RegistrationTarget, Targets, useTargetAPI} from "@API/TargetAPI.ts";
import {Language, useCurrentLanguage} from "@Components/I18nWrapper.tsx";
import {useResponseActionInterceptor} from "@Util/Hooks/Http.tsx";

export interface LocalisedTarget {
  id: Targets;
  locale: string;
  title: string;
  description?: string;
}

const TargetsContext = createContext<LocalisedTarget[]>([]);

export const useLocalisedTargets = () => useContext(TargetsContext);

export interface TargetsProviderProps extends FCProps {
  language?: Language;
  value?: RegistrationTarget[];
}

export const TargetsProvider = ({children, language, value}: TargetsProviderProps) => {
  const api = useTargetAPI();
  const currentLanguage = useCurrentLanguage();
  const locale = language?.code ?? currentLanguage?.code ?? null;
  const [updatedTargets, setUpdatedTargets] = useState<RegistrationTarget[]>()

  const targets = updatedTargets ?? value;

  useEffect(() => {
    if (!value) {
      api.list().then(setUpdatedTargets);
    }
  }, []);

  useResponseActionInterceptor(/^target/, async () => {
    api.list().then(setUpdatedTargets);
  }, [setUpdatedTargets]);

  const localised = useMemo((): LocalisedTarget[] => {
    if (!targets?.length || !locale) {
      return [];
    }

    return targets.map(({id, i18n}): LocalisedTarget => {
      const translation = i18n.find(t => t.locale.code === locale);

      return {
        id,
        locale,
        title: translation?.title ?? id,
        description: translation?.description,
      };
    })
  }, [locale, targets]);

  return (
    <TargetsContext.Provider value={localised}>
      {children}
    </TargetsContext.Provider>
  )
}
