import {useTherapist} from "../../Pages/Account/Therapist/TherapistProvider.tsx";
import {Therapist} from "@API/TherapistAPI.ts";
import {useCurrencyList} from "@Components/Providers/CurrencyProvider.tsx";
import {NotFilled} from "@Components/NotFilled.tsx";

export const PriceAmountView = () => {
  const t: Therapist = useTherapist();
  const currencyList = useCurrencyList();
  const currency = currencyList.find(c => c.id === t.price?.currency.id);

  if (!t.price || !currency) {
    return <NotFilled/>;
  }

  return <>{currency.symbol}{t.price.amount}</>
}
