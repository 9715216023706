import {FormDialog} from "@Components/FormDialog.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {ActionButton} from "@Components/ActionButton.tsx";
import {useFormField} from "@Util/Hooks/FormField.ts";
import {Required} from "@Util/Validation/Required.ts";
import {useTopicsAPI} from "@API/TopicsAPI.ts";
import {TextField} from "@Components/TextField.tsx";
import {useSearchAwarePath} from "@Util/Hooks/Navigate.tsx";

export const TopicAddDialog = () => {
  const api = useTopicsAPI();
  const back = useSearchAwarePath('..');
  const title = useFormField<string>({
    initialValue: '',
    validators: [
      new Required(),
    ],
  });

  const handleSubmit = async () => {
    await api.create({title: title.value as string})
  };

  return (
    <FormDialog title={<Translated value="Add topic"/>} onSubmit={handleSubmit} back={back}>
      <TextField
        field={title}
        label={(
          <Translated value="Title"/>
        )}
      />
      <ActionButton requireValid={[title]}/>
    </FormDialog>
  )
}
