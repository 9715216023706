import React, {FC} from "react";
import {useTherapist} from "../../Account/Therapist/TherapistProvider.tsx";
import {HighlightItem} from "../Partial/HighlightItem.tsx";
import {AdsClick} from "@mui/icons-material";
import {ExternalLink} from "@Components/Link.tsx";
import {Box} from "@mui/material";
import {TRANSLATIONS} from "@assets/translation.ts";

export const ProfessionalLinksView: FC = React.memo(() => {
  const t = useTherapist();

  return (
    <HighlightItem label={TRANSLATIONS.PROFILE.SOCIAL_NETWORKS_LINKS} IconComponent={AdsClick}>
      <Box>
        {t.links?.map((link) => (
          <Box key={link.id} mb={1} mt={1}>
            <ExternalLink url={link.url}/>
          </Box>
        ))}
      </Box>
    </HighlightItem>
  );
});
