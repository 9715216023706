import {Button, Dialog, DialogContent, DialogTitle} from "@mui/material";
import {useState} from "react";

export const UnderConstruction = () => {
  const [, setShownAt] = useState(new Date());
  const accept = () => {
    localStorage.setItem('inDevelopment', 'yes');
    setShownAt(new Date());
  }

  const show = typeof localStorage === 'undefined' ? false : !localStorage.getItem('inDevelopment');

  return (
    <Dialog open={show} onClose={accept}>
      <DialogTitle>Under development</DialogTitle>

      <DialogContent>
        <p>Service is not ready for use yet. Please wait.</p>

        <Button variant="contained" onClick={accept}>OK</Button>
      </DialogContent>
    </Dialog>
  )
}
