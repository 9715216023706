import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, SelectProps} from "@mui/material";
import {scalar} from "@Util/Hooks/FormField.ts";
import {FormSelectField} from "@Util/Hooks/FormSelectField.ts";
import {v4} from 'uuid';
import {ReactNode, useEffect, useMemo} from "react";

export type SelectFieldProps<O, T extends scalar> = Omit<SelectProps, 'value'|'onChange'|'onBlur'> & {
  field: FormSelectField<O, T>
  label: ReactNode;
  onSelected?: (o: O) => void;
}

export function SelectField<O, V extends scalar>(props: SelectFieldProps<O, V>) {
  const {field, onSelected, ...rest} = props;

  const {getOptionValue, getOptionLabel} = field;

  function buildLabel(option: O) {
    if (getOptionLabel) {
      return getOptionLabel(option);
    }

    return getOptionValue(option);
  }

  const labelId = useMemo(v4, []);

  const handleChange = (event: SelectChangeEvent<any>) => {
    field.onChange(event.target.value);
    field.onBlur();
  }

  useEffect(() => {
    if (field.value && field.value !== field.initialValue) {
      if (onSelected) {
        const selected = field.options.find(o => getOptionValue(o) === field.value)

        if (selected) {
          onSelected(selected);
        }
      }
    }
  }, [field.value, field.initialValue]);

  return (
    <FormControl fullWidth={props.fullWidth}>
      <InputLabel id={labelId}>{rest.label}</InputLabel>
      <Select
        labelId={labelId}
        value={field.value}
        onChange={handleChange}
        {...rest}
      >
        {field.options.map(option => (
          <MenuItem key={getOptionValue(option)} value={getOptionValue(option)}>{buildLabel(option)}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}


