import {NotFilled} from "@Components/NotFilled.tsx";
import {EditableInline} from "../../Editable.tsx";
import {LocationLabel} from "./LocationLabel.tsx";
import {EditableCollection} from "../../EditableCollection.tsx";
import {useTherapist} from "../TherapistProvider.tsx";
import { Box } from "@mui/material";
import {TRANSLATIONS} from "@assets/translation.ts";

export const LocationsSection = () => {
  const therapist = useTherapist();

  return (
    <Box>
      <EditableCollection
        label={TRANSLATIONS.PROFILE.LOCATIONS}
        showIndicator={!therapist.locations || therapist.locations.length === 0}
        indicator={(
          <NotFilled/>
        )}
        addPath="locations/add"
        shift={1}
      >
        {therapist.locations && therapist.locations.map(location => (
          <Box key={location.id} mb={1}>
            <EditableInline editPath={`locations/${location.id}/update`}>
              <LocationLabel location={location}/>
            </EditableInline>
          </Box>
        ))}
      </EditableCollection>
    </Box>
  );
}
