import { useCallback, useEffect, useState } from 'react';

export type scalar = string|number|boolean|null;

export interface FieldValidator {
  isValid: (value: scalar) => boolean;
  message: string;
}

export interface FormFieldOptions<T extends scalar> {
  isValid?: boolean;
  isTouched?: boolean;
  initialValue: T | '';
  value?: T;
  validators?: FieldValidator[];
}

export interface ExtraProps<T extends scalar> {
  field: FormField<T>;
}

export interface FormField<T extends scalar> {
  readonly isValid: boolean,
  readonly isTouched: boolean,
  readonly initialValue: T,
  readonly value: T | undefined,
  readonly onChange: (value: T) => void,
  readonly onBlur: () => void,
  error: string | false,
  reset: () => void,
}

export const useFormField = <T extends scalar = string>(options: FormFieldOptions<T>): FormField<T> => {
  const [isValid, setValid] = useState(options?.isValid ?? false);
  const [isTouched, setTouched] = useState(options?.isTouched ?? false);
  const [value, setValue] = useState<T>(options.value ?? options.initialValue as T);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (options.value === undefined) {
      setValue(options.initialValue as T);
    } else {
      setValue(options.value);
    }
  }, [options.value, options.initialValue]);

  useEffect(() => {
    const newErrors: string[] = []
    if (options?.validators) {
      for (const validator of options.validators) {
        if (!validator.isValid(value ?? options.initialValue)) {
          newErrors.push(validator.message);
        }
      }
    }

    setValid(newErrors.length === 0);

    if (isTouched) {
      setErrors(newErrors);
    }
  }, [isTouched, value, options.initialValue]);

  const onChange = useCallback((value: T) => {
    setTouched(true);
    setValue(value);
  }, [value, isTouched]);

  const onBlur = useCallback(() => {
    setTouched(true);
  }, [isTouched]);

  const reset = () => {
    setValue(options.initialValue as T);
    setTouched(false);
  };

  return {
    isValid,
    isTouched,
    value: value,
    initialValue: options.initialValue as T,
    onChange,
    onBlur,
    error: errors[0] ?? false,
    reset,
  }
}
