import {useEffect, useState} from "react";
import {ChatMessage, MessageAPI} from "@API/MessageAPI.ts";
import {ChatContact} from "@API/ContactAPI.ts";
import {useAPI} from "@API/API.ts";
import {Topics, useWebSocket} from "@Components/Providers/WebsocketProvider.tsx";
import {useCurrentUser} from "@Util/Hooks/Auth.tsx";
import {useTabActive} from "@Util/Hooks/UseTabActive.tsx";

export const maxMessages = 100;

export const useMessagesList = (contact?: ChatContact): ChatMessage[] => {
  const ws = useWebSocket();
  const api = useAPI(MessageAPI);
  const user = useCurrentUser();
  const isActiveTab = useTabActive();
  const [messages, setMessages] = useState<ChatMessage[]>([]);

  useEffect(() => {
    if (contact) {
      api.list({contact: contact.id}).then(setMessages);
    } else {
      setMessages([]);
    }
  }, [contact]);

  useEffect(() => {
    for (const message of messages) {
      if (message.receiver.id === user.id && !message.receivedAt) {
        if (isActiveTab) {
          api
            .update({
              id: message.id,
              receivedAt: new Date().toISOString(),
            })
            .then(updated => {
              Object.assign(message, updated);
            });
        }
      }
    }
  }, [messages, isActiveTab]);

  useEffect(() => {
    const handleWSChatMessage = (message: ChatMessage) => {
      if (!contact) {
        return;
      }

      const pair = [message.sender.id, message.receiver.id].sort();

      if (pair.includes(user.id) && pair.includes(contact.target.id)) {
        setMessages([message, ...messages].slice(0, maxMessages));
      }
    }

    ws.on(Topics.CHAT_MESSAGE, handleWSChatMessage);

    return () => {
      ws.off(Topics.CHAT_MESSAGE, handleWSChatMessage);
    }
  }, [contact?.id, messages]);

  return messages;
}
