import {CircularProgress, Paper} from "@mui/material";
import {styled} from "@mui/system";
import {Navigate, NavLink, NavLinkProps, Outlet, useLoaderData} from "react-router-dom";
import {theme} from "@Util/theme.tsx";
import {Translated} from "@Components/Localization/Translated.tsx";
import {TransSection} from "@Components/Localization/TransSection.tsx";
import {ReactNode} from "react";
import {useCurrentUser} from "@Util/Hooks/Auth.tsx";
import {Link} from "@Components/Link.tsx";
import {useLoading} from "@Util/Hooks/Loading.tsx";
import {useSearchAwarePath} from "@Util/Hooks/Navigate.tsx";
import {AuthLoaderData} from "./AuthLoader.tsx";
import {MembershipListProvider} from "@Components/Membership/MembershipProvider.tsx";
import {useSearchParam} from "@Util/Hooks/UseSearchParam.tsx";

export const RETURN_PATH_PARAM_NAME = 'returnPath';

export const AuthPage = () => {
  const isLoading = useLoading();
  const user = useCurrentUser();
  const loginPath = useSearchAwarePath('log-in');
  const registerPath = useSearchAwarePath('register');
  const loaderData = useLoaderData() as AuthLoaderData

  const redirectPath = useSearchParam(RETURN_PATH_PARAM_NAME, {
    default: '/account',
    transform: (v) => v,
  });

  if (user.id) {
    return <Navigate to={redirectPath}/>
  }

  return (
    <AuthContainer>
      <TransSection section="auth">
        {!isLoading && !user.id && (
          <Paper sx={{padding: 2}} title="">
            <TabLinkStyled
              to={loginPath}
              title={(
                <Translated value="Log in"/>
              )}
            />
            <TabLinkStyled
              to={registerPath}
              title={(
                <Translated value="Register"/>
              )}
            />
            <h1><Translated value="Welcome!"/></h1>
            <p>
              <Translated value="Please fill the form below to access the cabinet."/>
            </p>
            <MembershipListProvider value={loaderData[0]}>
              <Outlet/>
            </MembershipListProvider>
          </Paper>
        )}

        {isLoading && (
          <CircularProgress/>
        )}

        {!isLoading && user.id && (
          <Link to="/account">Go to account</Link>
        )}

      </TransSection>
    </AuthContainer>
  );
}

interface TabLinkProps extends Omit<NavLinkProps, 'title'> {
  title: ReactNode;
}

const TabLink = (props: TabLinkProps) => {
  const {title, ...rest} = props
  props.className ??= ({isActive, isPending}) => isActive ? 'active' : isPending ? 'pending' : '';

  return <NavLink {...rest}>{title}</NavLink>
}

const TabLinkStyled = styled(TabLink)`
  text-decoration: none;
  color: ${theme.palette.primary.main};
  margin-right: ${theme.spacing(2)};

  &.active {
    text-decoration: underline;
    font-weight: bold;
  }
`

export const AuthContainer = styled('div')`
  max-width: ${theme.breakpoints.values.sm}px;
  margin: 0 auto;
  margin-top: ${theme.spacing(4)};
`
